<template>
    <div @mouseleave="CookisCardPlacer()" @mouseup="CookisCardPlacer()" :style="CardOnHold ? CardSizeNow : ''" OnHoldNow="true"  class="CookiesDragableCard BG2" :class="{CookiesCardOnHold : CardOnHold}">
      <div OnHoldNow="true" @mousedown="CookisCardHolder()" :style="{'text-align': LanguageSelector('left', 'right')}" class="CookiesCardHeader color3"><span class="CardHeaderTXT">{{CardsNames[CardContent]}}</span><span class="P-Rel O-25 F-Bold" style="pointer-events: none; vertical-align: middle; top: 0.3em;">{{FollowDate.includes(CardContent) ? ($store.state.CurrentDate.From + ' - ' + $store.state.CurrentDate.To) : LanguageSelector('Live', 'مباشر')}}</span></div>
      <div class="CookiesCardBody"><component :is="CardContent"></component></div>
    </div>
</template>

<script>
export default {
  props: {
    number: Number,
    CardContent: { default: 'EmptyCard', type: String }
  },
  created () {
    window.addEventListener('mousemove', this.CookiesCardMover)
  },
  data () {
    return {
      number1: 1,
      CardOnHold: false,
      // //
      ClickedDomElement: null,
      CardInitialPlace: null,
      MousXInitial: null,
      MousYInitial: null,
      FollowDate: ['DriversCard', 'CustomersOrdersCard', 'ProfitCard', 'OrdersFlowCard'],
      CardsNames: {
        EmptyCard: this.LanguageSelector('', ''),
        TicketsCard: this.LanguageSelector('Active Orders', 'الطلبات الحالية'),
        CashCard: this.LanguageSelector('Cash', 'الكاش'),
        ProfitCard: this.LanguageSelector('Cash Flow', 'السيوله'),
        OrdersFlowCard: this.LanguageSelector('Orders History', 'حركة الطلبات'),
        DriversCard: this.LanguageSelector('Drivers Orders', 'طلبات السائقون'),
        CustomersOrdersCard: this.LanguageSelector('Customers Orders', 'طلبات العملاء')
      }
      // CardContent2: require('../HelloWorld.vue')
    }
  },
  components: {
    // asyncComponent: defineAsyncComponent(() => import('../HelloWorld.vue'))
  },
  emits: ['IamDragging', 'DraggingStopped'],
  methods: {
    CookisCardHolder () {
      this.ClickedDomElement = event.target.parentNode // getting the clicked dom
      this.CardInitialPlace = this.ClickedDomElement.getBoundingClientRect() // getting intial place of the clicked card
      this.MousXInitial = event.clientX // getting initial x position of the mouse
      this.MousYInitial = event.clientY // getting initial y position of the mouse
      // //
      this.ClickedDomElement.style.top = (this.CardInitialPlace.y + window.scrollY + document.getElementById('MainAppContainer').scrollTop).toString() + 'px'
      this.ClickedDomElement.style.left = this.CardInitialPlace.x.toString() + 'px'
      this.CardOnHold = true // defining the status of the card to on hold
      // removing text and other selections
      this.$store.state.CookiesMemory.DashCardOnhold = true
      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }
      this.$emit('IamDragging')
    },
    CookisCardPlacer () {
      if (this.CardOnHold) {
        this.CardOnHold = false // defining the status of the card to not hold
        this.$store.state.CookiesMemory.DashCardOnhold = false
        this.ClickedDomElement.style.top = '0px'
        this.ClickedDomElement.style.left = '0px'
        this.ClickedDomElement = null
        this.CardInitialPlace = null
        this.MousXInitial = null
        this.MousYInitial = null
      }
      this.$emit('DraggingStopped')
    },
    CookiesCardMover () {
      // /////
      if (this.CardOnHold) {
        var MouseXNow = event.clientX
        var MouseYNow = event.clientY
        var PositionXDef = MouseXNow - this.MousXInitial
        var PositionYDef = MouseYNow - this.MousYInitial
        // console.log(PositionXDef, PositionYDef)
        // console.log(this.CardInitialPlace)
        // console.log(this.MousXInitial, this.MousYInitial)
        var CardNewX = this.CardInitialPlace.x + PositionXDef + window.scrollX
        var CardNewY = this.CardInitialPlace.y + PositionYDef + window.scrollY + document.getElementById('MainAppContainer').scrollTop
        this.ClickedDomElement.style.top = CardNewY.toString() + 'px'
        this.ClickedDomElement.style.left = CardNewX.toString() + 'px'
      }
    },
    CookiesloadComponent () {}
  },
  computed: {
    CardSizeNow () {
      // alert(this.CardInitialPlace.width)
      if (this.CardInitialPlace != null) {
        return {
          minWidth: this.CardInitialPlace.width + 'px',
          minHeight: this.CardInitialPlace.height + 'px'
        }
      } else {
        return {
          minWidth: '',
          minHeight: ''
        }
      }
    }
  }
}
</script>

<style scoped>
.CookiesCardFill{
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  display: flex;
}
*{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
  .CookiesDragableCard{
    display: inline-block;
    filter: drop-shadow(var(--shadow1));
    /* border: 1px dashed #000000; */
    /* background-color: var(--colorw1); */
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-duration: 1s;
    transition-property: transform;
  }
  .CookiesCardOnHold{
    position: absolute;
    opacity: 0.5;
    z-index: 55;
    transform: rotate3d(0.1,1,0.1,9deg);
  }
  .CookiesCardHeader{
    width:100%;
    height:3.2em;
    background-color: var(--shade0);
    border-radius: var(--rad1);
    cursor: move;
    text-align: left;
    vertical-align: middle;
  }
  .CookiesCardBody{
    opacity: 1;
    /* background-color:rgba(94, 49, 49, 0.432); */
    height: calc(100% - 3.2em);
    border-radius: var(--rad1);
    overflow: hidden;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }
  .CardHeaderTXT{
    font-size: 1.5em;
    font-weight: bold;
    top: 0.35em;
    margin-left: 1em;
    margin-right: 1em;
    display: inline-block;
    position: relative;
    /* color: rgba(0,0,0,0.5); */
  }
  .CookiesCenterDiv{
    margin: auto;
  }
</style>
