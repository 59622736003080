<template>
<div id="DriverOrderTableToPrint" class="W-100 Shadow1 D-Flex DialogBody" style="padding:5px; flex-direction: column;">
  <div class="M-Auto" v-if="FilteredOrders.length === 0 & !Loading">{{LanguageSelector('No Orders Assigned', 'لا يوجد طلبات')}}</div>
  <div class="CookiesRowSpacer"></div>
  <div class="M-Auto">
    <!-- <CookiesLoading></CookiesLoading> -->
    <div v-if="FilteredOrders.length > 0" @click="printIT()" class="PrintButton D-Flex RowMargin NOPRINT">
      <CookiesIcon style="width: 1.2em; height:1.2em; margin: 0 0.5em;" :ID="'Printer'"></CookiesIcon>
      <span class="F-Bold M-Auto">Print</span>
    </div>
    <div class="CookiesRowSpacer"></div>
  </div>
  <div v-if="FilteredOrders.length > 0" class="M-Auto" style="padding: 1em;">
    <span class="F1_5em F-Bold">{{LanguageSelector('Orders for driver ','طلبات السائق ')}} {{DeepFetcher(DialogData, ['Employee', 'Name'])}}</span>
    <CookiesTable :DefaultSort="{ name: ['SubArea'], value: 1 }" :PrintControls="false" :Footer="false" :TableName="''" :Data="FilteredOrders" :Fields="Fields"></CookiesTable>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      CookiesDataOrders: [],
      Fields: [
        {
          name: 'index',
          noheader: true,
          type: 'index',
          print: false
        },
        {
          name: this.LanguageSelector('Bar Code', 'بار كود'),
          value: ['BarCode'],
          sort: true,
          bold: true
        },
        {
          name: this.LanguageSelector('Customer', 'العميل'),
          value: ['Customer', 'Company'],
          sort: true
        },
        {
          name: this.LanguageSelector('Location', 'الموقع'),
          value: ['SubArea'],
          sort: true
        },
        {
          name: this.LanguageSelector('Address', 'العنوان'),
          value: ['Address'],
          sort: true
        },
        {
          name: this.LanguageSelector('Client Number', 'رقم الزبون'),
          value: ['Client', 'PhoneNum'],
          sort: true
        },
        {
          name: this.LanguageSelector('Total Ammount', 'اجمالي المطلوب'),
          value: ['AmountTotal'],
          sort: true,
          type: 'number',
          pricetag: true,
          total: true
        },
        {
          name: this.LanguageSelector('Notes', 'الملاحظات'),
          value: ['Notes'],
          sort: true
        }
      ]
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    this.ReadUserOders()
  },
  methods: {
    ReadUserOders () {
      this.Get('Order?DriverID=' + this.DeepFetcher(this.DialogData, ['Id'])).then(response => {
        this.CookiesDataOrders = response
      }, error => {
        error.CookiesError = 'Error in reading Orders'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    printIT () {
      var bodd = document.getElementById('DriverOrderTableToPrint')
      this.$store.state.Printing = bodd.cloneNode([true])
    }
  },
  computed: {
    FilteredOrders () {
      var Filtered = []
      this.CookiesDataOrders.forEach(element => {
        var FilterIt = true
        try {
          if (!(['OnDelivery'].includes(element.Status))) {
            FilterIt = false
          }
        } catch (error) {
        }
        if (FilterIt) {
          element.StatusDecoded = this.StatusMapper(element.Status)
          Filtered.push(element)
        }
      })
      return Filtered
    }
  }
}
</script>
<style scoped>
.DialogBody{
  min-width: 800px;
  min-height: 400px;
  max-width: 95vw;
}
.PrintButton{
  width: 6em;
  border-radius: 5em;
  background: v-bind('$store.getters.Theme.BG6');
  padding: 0.4em 0.4em;
  cursor: pointer;
  filter: drop-shadow(var(--shadow0));
  transition-property: transform, filter;
  transition-duration: 0.2s;
}
.PrintButton:hover{
  filter: drop-shadow(var(--shadow1));
  transform: translateY(-0.2em);
}
</style>
