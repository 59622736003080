<template>
<div class="M-Auto" :class="Disabled ? 'CookiesInputDisabled' : ''">
  <input @change="$emit('ColorChanged')" class="Pointer" v-model="$store.state.CookiesConfig[Res]" type="color" :disabled="Disabled">
  <span :style="Name ==='' ? {'padding': '0px'} : {'padding': '0.6em'}" >{{Name}}</span>
</div>
</template>

<script>
export default {
  name: 'CookiesColorPicker',
  data () {
    return {}
  },
  props: {
    Name: { default: '', Type: String },
    Res: { default: '', type: String },
    Disabled: { default: false, Type: Boolean }
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>

.CookiesInputDisabled{
  opacity: 0.3;
  pointer-events: none;
}
.CookiesPicker{
  background: red;
}
input[type="color"] {
  -webkit-appearance: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 2.5em;
  height: 2.5em;
  border-radius: var(--rad1);
  background: none;
  filter: drop-shadow(var(--shadow1));
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0.35em;
  margin: none;
  border-radius: var(--rad2);
  background: v-bind('$store.getters.Theme.BG2');
}
input[type="color"]::-webkit-color-swatch {
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: inset var(--shadow2);
  border-radius: var(--rad2);
}
</style>
