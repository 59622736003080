<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Order -------------------------------------- -->
      <div>
        <span :class="!NoBarCode ? 'ContentHidden': ''" class="Transition0_5 W-100 F1em F-CR">{{LanguageSelector('No Code', 'لا يوجد كود')}}</span>
      </div>
      <input v-if="!NoBarCode" maxlength="13" style="text-align: center;" v-model="BarCode" :class=" (ErrorsVisibilty & !Name) && 'ErrorBorder' " class="CookiesInput color2" type="Text" name="barcode" :placeholder="LanguageSelector('BarCode', 'BarCode')" autocomplete="off">
      <div v-if="!NoBarCode" :class="BarcodeValue === '' && 'barcodeBlur'" class="barcode M-Auto Transition0_5">
        <vue-barcode :value="BarCodeHandler" :options="{ displayValue: true, fontSize: 30, height: 100, width: 2.2, background: 'rgba(0,0,0,0)'}"></vue-barcode>
      </div>
      <div v-if="!NoBarCode" class="CookiesRowSpacer"/>
      <div :class="BarcodeEntered ? 'ContentHidden': ''" class="Transition0_5 CookiesRowSpacer"/>
      <span :class="BarcodeEntered ? 'ContentHidden': ''" class="Transition0_5 W-100 F2em F-CR">{{LanguageSelector('Please Scan the BarCode', 'الرجاء مسح الكود')}}</span>
      <div :class="!BarcodeEntered ? 'ContentHidden': ''" class="CookiesRowSplitter Transition0_5"/>
      <div :class="!BarcodeEntered ? 'ContentHidden': ''" class="W-100 Transition0_5">
        <div class="D-Flex" style="padding: 0.6em 3em;">
          <span class="W-100">{{LanguageSelector('Customer', 'العميل')}}</span>
        </div>
        <div class="D-Flex BG4" style="flex-direction: column; padding: 0.5em; border-radius: var(--rad2);">
          <span class="W-100 F-Bold F1_25em">{{SelectedCustomer.Company}}</span>
          <span class="W-100 F-Bold F1_25em">{{SelectedCustomer.PhoneNumber}}</span>
        </div>
      </div>
      <div :class="!BarcodeEntered ? 'ContentHidden': ''" class="W-100 Transition0_5">
        <!-- <div class="CookiesRowSplitter"/>
          <span class="W-100">{{LanguageSelector('Location', 'الموقع')}}</span>
        <div class="D-Flex" style="padding: 0.5em 3em;">
          <CookiesDropInputObject :ErrorData="ErrorsVisibilty & $store.state.CookiesMemory.EditOrderArea === ''" :class="CookiesDataSubAreas.length === 0 && 'Disabled'" @selected="AreaSelected" class="M-Auto" :Res="'EditOrderArea'" :Width="'6em'" :RowHeight="'2.3em'" :Options="CookiesDataSubAreas" :Selectors="['Name']" :Name="LanguageSelector('Select Area', 'اختر منطقة')"></CookiesDropInputObject>
        </div> -->
        <!-- <div class="D-Flex" style="padding: 0.5em 3em;" :class="CookiesDataSubAreas.length === 0 && 'Disabled'">
          <input v-model="Address" class="CookiesInput color2" type="Text" name="address" :placeholder="LanguageSelector('Address', 'العنوان')" autocomplete="off">
        </div> -->
        <div v-if="Access('EditPrice') || Access('EditFees')" class="CookiesRowSplitter"/>
        <div v-if="Access('EditPrice') || Access('EditFees')" class="D-Flex" style="flex-flow: wrap;">
          <div class="CookiesRowSpacer"/>
          <div class="D-Flex M-Auto Pad10 F1_25em W-100">
            <div class="D-Flex M-Auto FlexWrap" style="gap: 0.5em;">
              <CookiesNumberInput v-if="Access('EditPrice')" :MinWidth="'3em'" :Name="LanguageSelector('Total Amount', 'القيمة الكلية للطلب')" style="padding: 0 2em;     margin: auto 1em;" :Min="0" :Unit="$store.state.CookiesConfig.PriceUnit" :Default="5" :Step="0.25" :Res="'EditOrderAmmount'"></CookiesNumberInput>
              <CookiesNumberInput v-if="Access('EditFees')" :MinWidth="'3em'" :Name="LanguageSelector('Delivery Fees', 'عمولة التوصيل')" style="padding: 0 2em;     margin: auto 1em;" :Min="0" :Unit="$store.state.CookiesConfig.PriceUnit" :Default="DialogData.Fees" :Step="0.25" :Res="'EditOrderFees'"></CookiesNumberInput>
            </div>
          </div>
        </div>
        <div class="CookiesRowSplitter"/>
        <div class="CookiesRow">
          <span class="W-100">{{LanguageSelector('Client Info', 'معلومات الزبون')}}</span>
          <input v-model="Name"  class="CookiesInput color2" type="Text" name="name" :placeholder="LanguageSelector('Name', 'الاسم')" autocomplete="off">
          <input :class=" (ErrorsVisibilty & !PhoneNumber) && 'ErrorBorder'" v-model="PhoneNumber" class="CookiesInput color2" type="Text" name="phone" :placeholder="LanguageSelector('Phone', 'الهاتف')" autocomplete="off">
          <input v-model="Email" class="CookiesInput color2" type="Text" name="email" :placeholder="LanguageSelector('Email', 'البريد الالكتروني')" autocomplete="off">
        </div>
        <div class="CookiesRowSplitter"/>
        <span class="W-100">{{LanguageSelector('Notes', 'الملاحظات')}}</span>
        <div class="CookiesRowSpacer"/>
        <div class="">
          <textarea v-model="Notes" class="CookiesCommentBox" name="notes" :placeholder="LanguageSelector('Notes', 'ملاحظات')" autocomplete="off"></textarea>
        </div>
      </div>
      <button v-if="!NoBarCode & BarcodeValue === ''" @click="WithoutCode()" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{LanguageSelector('Continue without code', 'لا يوجد كود')}}</button>
    </div>
  </div>
</div>
<div class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'الغاء': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Cancel': ''}}</button>
    <button :class="!BarcodeEntered && 'Disabled'" @click="SaveOrder()" :style="'background: ' + $store.getters.Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'حفظ': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Save': ''}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  created () {},
  data () {
    return {
      BarCode: '',
      Name: '',
      PhoneNumber: '',
      Email: '',
      Type: '',
      ErrorsVisibilty: false,
      selectedFile: '',
      SelectedUserImage: '',
      UserImage: '',
      Location: '',
      Address: '',
      CookiesDataAreas: [],
      CookiesDataCustomers: [],
      CookiesDataSubAreas: [],
      SelectedArea: {},
      SelectedCustomer: '',
      BarcodeEntered: true,
      BarcodeValue: '',
      NoBarCode: true,
      Notes: '',
      OriginalOrder: {},
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    ReadCustomers () {
      this.Get('Customers').then(response => {
        this.CookiesDataCustomers = response
        this.SelectedCustomer = this.ObjectFinder(response, ['Id'], this.DeepFetcher(this.DialogData, ['Customer', 'CustomerID']))
      }, error => {
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSubAreas () {
      this.Loading = true
      this.Get('SubArea').then(response => {
        this.CookiesDataSubAreas = response
        this.Loading = false
      }, error => {
        error.CookiesError = 'Error in reading SubAreas'
        this.Loading = false
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomersWithAreas () {
      this.Get('Customers?IncludeAreas=true').then(response => {
        this.CookiesDataCustomers = response
        this.SelectedCustomer = this.ObjectFinder(response, ['Id'], this.DeepFetcher(this.DialogData, ['Customer', 'CustomerID']))
      }, error => {
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadOrder () {
      this.Get('Order?OrderID=' + this.DialogData.ID).then(response => {
        if (response.BarCode === '' || response.BarCode === null || response.BarCode === undefined) {
          this.NoBarCode = true
        } else {
          this.BarcodeValue = response.BarCode
        }
      }, error => {
        error.CookiesError = 'Error in reading the order'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveOrder () {
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      var OriginalItem = this.DeepCloner(this.OriginalOrder)
      var NewItem = this.DeepCloner(this.EditOrderData)
      var Patch = this.CookiesPatcher(OriginalItem, NewItem)
      if (Patch.length === 0) {
        this.Saving = false
        this.$router.go(-1)
        return
      }
      if (!this.selectedFile || this.selectedFile === null || this.selectedFile === undefined) {
        this.Post('PATCH', 'Order/EditOrder?Order_ID=' + this.OriginalOrder.ID, Patch).then(response => {
          this.Saving = false
          this.CloseDialog(this.DialogIndex)
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Editing Order'
          this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      } else {
        this.PostImage(this.selectedFile).then(response => {
          this.UserImage = response.url
          this.Post('PATCH', 'Order/EditOrder?Order_ID=' + this.OriginalOrder.ID, Patch).then(response => {
            this.Saving = false
            this.CloseDialog(this.DialogIndex)
          }, error => {
            this.Saving = false
            error.CookiesError = 'Error in Editing Order'
            this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Uploading Image'
          this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    },
    AreaSelected (element) {
      this.SelectedArea = element
      this.$store.state.CookiesMemory.EditOrderFees = element.Fees
    },
    CustomerSelected (element) {
      this.SelectedCustomer = element
      // this.$store.State.CookiesMemmory.EditOrderCustomer = ''
      this.$forceUpdate()
    },
    BarCodeKeyPressed () {
      if ((event.which) === 13 & this.BarCode !== '') {
        this.BarcodeEntered = true
        this.BarcodeValue = this.BarCode
        this.BarCode = ''
      }
    },
    BarCodeClicked () {
      this.BarcodeEntered = false
      this.BarcodeValue = ''
      this.BarCode = ''
    },
    WithoutCode () {
      this.BarcodeEntered = true
      this.NoBarCode = true
    }
  },
  mounted () {
    this.ReadCustomers()
    this.ReadSubAreas()
    // this.ReadCustomersWithAreas()
    this.ReadOrder()
    this.OriginalOrder = this.DeepCloner(this.DialogData)
    // this.$store.state.CookiesMemory.EditOrderArea = this.DeepFetcher(this.DialogData, ['SubArea'])
    this.$store.state.CookiesMemory.EditOrderAmmount = this.DeepFetcher(this.DialogData, ['AmountTotal'])
    this.$store.state.CookiesMemory.EditOrderFees = this.DeepFetcher(this.DialogData, ['Fees'])
    this.Name = this.DeepFetcher(this.DialogData, ['Client', 'Name'])
    this.PhoneNumber = this.DeepFetcher(this.DialogData, ['Client', 'PhoneNum'])
    this.Email = this.DeepFetcher(this.DialogData, ['Client', 'Email'])
    // this.Address = this.DeepFetcher(this.DialogData, ['Address'])
    // this.SelectedArea = this.DeepFetcher(this.DialogData, ['SubArea'])
    this.Notes = this.DeepFetcher(this.DialogData, ['Notes'])
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    BarCodeDetect () {
      return false
    },
    EditOrderData () {
      var EditedData = this.DeepCloner(this.OriginalOrder)
      EditedData.BarCode = this.BarcodeValue
      if (this.Access('EditPrice')) {
        EditedData.AmountTotal = this.$store.state.CookiesMemory.EditOrderAmmount
      }
      if (this.Access('EditFees')) {
        EditedData.Fees = this.$store.state.CookiesMemory.EditOrderFees
      }
      // EditedData.Area = this.SelectedArea
      // EditedData.Address = this.Address
      EditedData.Notes = this.Notes
      EditedData.Client.Name = this.Name
      EditedData.Client.PhoneNum = this.PhoneNumber
      EditedData.Client.Email = this.Email
      return EditedData
    },
    DialogError () {
      var DialogErrors = []
      // this.Name === '' && DialogErrors.push('Please Enter Order\'s Name')
      this.PhoneNumber === '' && DialogErrors.push('Please Enter Order\'s Name')
      this.$store.state.CookiesMemory.EditOrderCustomer === '' && DialogErrors.push('Please Enter Order\'s Customers')
      // this.$store.state.CookiesMemory.EditOrderArea === '' && DialogErrors.push('Please Enter Order\'s Area')
      // !this.PhoneValidator(this.PhoneNumber) && DialogErrors.push('Please Enter Order\'s Name')
      // !this.EmailValidator(this.Email) && DialogErrors.push('Please Enter Order\'s Email')
      return DialogErrors
    },
    isActive () {
      return this.$store.state.CookiesMemory.D_EditOrder_Active
    },
    BarCodeHandler () {
      if (this.BarcodeValue === '') {
        return 'AB123456789012'
      } else {
        return this.BarcodeValue
      }
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}
.barcode{
  display: flex;
  justify-content: center;
  width: 15em;
  max-width:100%;
  height:5em;
  background: var(--white);
  border: solid 1px rgb(167, 167, 167);
  border-radius: var(--rad3);
  overflow: hidden;
  filter: drop-shadow(var(--shadow1));
}
.barcodeBlur canvas{
  filter: blur(4px);
}
.UserImage{
  width: 10em;
  height: 10em;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: 5em;
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.UserImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
.ContentHidden{
  overflow: hidden;
  transform: scale(0);
  height: 0;
  opacity: 0;
  /* overflow: hidden; */
}
</style>
