<template>
<div>
  <div class="DialogPlaceHolder">
    <div @click="$store.state.UserManagment.Dialog = false" class="DialogShade"></div>
    <div class=" D-Flex W-100 Events-None" style="min-height: 100vh; padding-top:50px;">
      <div class="DialogBase">
        <div class="D-Flex W-100">
          <div class="M-Auto Shadow1 UserCircle" style="border-radius: 100%; background: var(--colorw2); border: solid 4px var(--colorw1); overflow: hidden;">
            <div class="W-100 H-100" :style="{'background': 'url(' + DefaultImageHandler($store.state.UserManagment.CurrentUser.User_Image) + ')'}"></div>
          </div>
        </div>
        <div class="CookiesRowSpacer"></div>
        <div class="CookiesRowSpacer"></div>
        <div class="CookiesRowSpacer"></div>
        <div class="CookiesRowSpacer"></div>
        <span class="M-Auto F1_5em F-Bold F-C2">{{$store.state.UserManagment.CurrentUser.User_Name}}</span>
        <div class="CookiesRowSpacer"></div>
        <span class="M-Auto F1_25em F-Bold F-C2">{{$store.state.UserManagment.CurrentUser.User_Email}}</span>
        <div class="CookiesRowSpacer"></div>
        <span class="M-Auto F1_25em F-Bold F-C2">{{$store.state.UserManagment.CurrentUser.User_Phone}}</span>
        <div class="CookiesRowSpacer"></div>
        <span class="M-Auto F1_25em F-Bold F-C1">{{$store.state.UserManagment.CurrentUser.User_Type}}</span>
        <div :id="'CookiesSignDialog'" class="CookiesDialogdBody">
          <div class="M-Auto BasePadding">
            <div class="DialogBody D-Flex M-Auto">
              <div class="CookiesRow F-S3 F-C2 W-100">
                <div style="margin-top:30px;" class="CookiesRow">
                  <button @click="SignOut" class="M-Auto F_5em" style="background: var(--colorr1)"><strong>Sign Out</strong></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      Email: '',
      Password: '',
      ErrorsVisibilty: false,
      UserDefaultImage: require('@/assets/defaults/UserImage.svg')
    }
  },
  mounted () {
    this.Email = this.$store.state.UserManagment.CurrentUser.User_Email
    try {
      var DialogPos = document.getElementById('CookiesSignDialog').offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    Errors () {
      var ERR = []
      if (!this.Email || this.Email === '') {
        ERR.push('Enter Email')
      }
      if (!this.Password || this.Password === '') {
        ERR.push('Enter Password')
      }
      return ERR
    }
  },
  methods: {
    DefaultImageHandler (ImageURL) {
      return ((ImageURL !== '') && ImageURL) ? ImageURL : this.UserDefaultImage
    },
    SignOut () {
      this.Post('POST', 'Users/Logout', {}).then(response => {
        this.$store.state.UserManagment.Dialog = false
        this.$router.replace('/SignIn')
      }, error => {
        error.CookiesError = 'Error in Signing Out'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  }
}
</script>
<style scoped>
.ErrorBorder{
  border: solid var(--colorr2) 2px !important;
  padding: 8px 23px !important;
}
.DialogBody{
  min-width: 220px;
  width: 300px;
  max-width: 70vw;
  transition: all 0.6s ease-in-out;
}
.BasePadding{
  padding: 50px;
  transition: all 0.6s ease-in-out;
}
.UserCircle{
  width:200px;
  height:200px;
  margin-top:-80px;
  transition: all 0.6s ease-in-out;
}
@media (max-width: 600px) {
  .BasePadding{
    padding: 25px;
  }
  .UserCircle{
    width:150px;
    height:150px;
    margin-top:-60px;
  }
}
.CookiesInput{
    height: 30px;
    width: 100%;
    border-radius: var(--rad2);
    background: var(--shade1);
    flex: auto;
    padding: 10px 25px;
    outline: none;
    font-size: 17px;
    color: var(rgba(1,1,1,0.8));
    margin: 6px;
    transition-property: all;
    transition-duration: 0.4s;
}
.DialogShade {
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -20;
}
.DialogShade::before{
  filter: blur(50px);
}
.DialogPlaceHolder{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  display: flex;
}
.DialogBase{
  background: var(--colorw1);
  margin: auto;
  border-radius: var(--rad1);
  filter: drop-shadow(var(--shadow1));
  min-width: 220px;
  max-width: 90vw;
  line-break: anywhere;
  /* min-height: 40vh;
  height: 40vh; */
  justify-content: center;
  text-align: center;
  display: inline-table;
  /* margin-top: 50px; */
  pointer-events: all;
}

.CookiesDialogHeader{
  width:100%;
  height:60px;
  background-color: var(--shade1);
  border-radius: var(--rad1);
  text-align: left;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  display: flex;
}
.CookiesDialogdBody{
  font-size: 1rem;
  height: calc(100% - 140px);
  border-radius: var(--rad1);
  /* overflow: hidden; */
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  display: flex;
  /* margin-bottom: 80px; */
  overflow-wrap: nowrap;
}
</style>
