<template>
  <td style="padding: 1em 1em;"><span class="PhoneElement">ID</span>{{value.HashedID}}</td>
  <td style="padding: 1em 0.6em;">
    <div class="Shadow1" style="background: white; border-radius: var(--rad3); display: inline-block; padding-bottom: 0.75em; color: black;">
      <vue-barcode style="width: 10em; height: 3em;" v-if="value.BarCode !== ''" :value="value.BarCode ? value.BarCode : ''" :options="{ displayValue: false, fontSize: 10, background: 'rgba(0,0,0,0)'}"></vue-barcode>
      <br>
      <span style="    display: block; margin-top: -1em; margin-bottom: -0.75em; padding-top: 0.5em;">{{value.BarCode}}</span>
    </div>
  </td>
  <td><span class="PhoneElement">{{LanguageSelector('Date Added', 'تاريخ الطلب')}}</span><strong>{{JsonDateParser(DeepFetcher(value, ['SubmissionDate']))}}</strong></td>
  <td><span class="PhoneElement">{{LanguageSelector('Expected Date', 'تاريخ التوصيل')}}</span><strong>{{JsonDateParser(DeepFetcher(value, ['ExpectedDeliveryDate']))}}</strong></td>
  <td><span class="PhoneElement">{{LanguageSelector('Line', 'الخط')}}</span><strong>{{DeepFetcher(value, ['Line', 'Name'])}}</strong></td>
  <td><span class="PhoneElement">{{LanguageSelector('Area', 'المنطقة')}}</span><strong>{{DeepFetcher(value, ['SubArea'])}}</strong></td>
  <td><span class="PhoneElement">{{LanguageSelector('Address', 'العنوان')}}</span><span>{{value['Address'] ? value['Address']: 'N/A'}}</span></td>
  <td class="RowNoClick"><span class="PhoneElement">{{LanguageSelector('Client', 'الزبون')}}</span><span>{{DeepFetcher(value, ['Client', 'Name'])}}</span><br><span class="F-Bold Pointer HoverGlow" @click="OpenWhatsapp(DeepFetcher(value, ['Client', 'PhoneNum']))">{{DeepFetcher(value, ['Client', 'PhoneNum'])}}</span></td>
  <td class="RowNoClick"><span class="PhoneElement">{{LanguageSelector('Customer', 'العميل')}}</span><span>{{DeepFetcher(value, ['Customer', 'Company'])}}</span><br><span class="F-Bold Pointer HoverGlow" @click="OpenWhatsapp(DeepFetcher(value, ['Customer', 'PhoneNumber']))">{{DeepFetcher(value, ['Customer', 'PhoneNumber'])}}</span></td>
  <td><span class="PhoneElement">{{LanguageSelector('Total', 'اجمالي المطلوب')}}</span>{{value['AmountTotal'] ? value['AmountTotal']: 'N/A'}} {{this.PriceUnit}}</td>
  <td><span class="PhoneElement">{{LanguageSelector('Delivery Fees', 'عمولة التوصيل')}}</span>{{value['Fees'] ? value['Fees']: 'N/A'}} {{this.PriceUnit}}</td>
  <td style="padding: 0.6em 0.4em">
    <CookiesIcon v-if="DeepFetcher(value, ['HasIssue'])" :ToolTip="DeepFetcher(value, ['IssueNote'])" class="CookiesRowIcon P-Rel IssueButton" :ID="'HasIssue'"><CookiesIcon v-if="Access('OrdersEdit')" @click="ChangeSelectedStatus(['RemoveIssue' , value])" class="CookiesRowIcon IssueDeleteButton P-Abs" style="z-index: 555" :ID="'Delete'"></CookiesIcon></CookiesIcon>
    <CookiesIcon :ToolTip="StatusMapper(DeepFetcher(value, ['Status']))" class="CookiesRowIcon" :ID="DeepFetcher(value, ['Status'])"></CookiesIcon>
  </td>
  <td v-if="!ArchivedOrders" style="padding: 0.6em 0.4em">
    <CookiesIcon :ToolTip="DeepFetcher(value, ['OrderDriver', 'DriverName']) ? DeepFetcher(value, ['OrderDriver', 'DriverName']) : LanguageSelector('No Driver Assigned', 'لم يتم تحديد سائق')" class="CookiesRowIcon" :ID="DeepFetcher(value, ['OrderDriver', 'DriverName']) ? 'AssignDriver' : 'NotAssigned'"></CookiesIcon>
  </td>
  <td style="padding: 0.6em 0.4em">
    <CookiesIcon :ToolTip="value.Payment_recived_From_Driver ? LanguageSelector('Driver Paid', 'تم محاسبة السائق') : LanguageSelector('Driver Not Paid', 'لم يتم محاسبة السائق')" :class="value.Payment_recived_From_Driver ? '' : 'O-25i'" class="CookiesRowIcon" :ID="'DriverPaid'"></CookiesIcon>
    <CookiesIcon :ToolTip="value.Payment_Delivered_To_Cusotmer ? LanguageSelector('Customer Paid', 'تم محاسبة العميل') : LanguageSelector('Customer Not Paid', 'لم يتم محاسبة العميل')" :class="value.Payment_Delivered_To_Cusotmer ? '' : 'O-25i'" class="CookiesRowIcon O-100" :ID="'CustomerPaid'"></CookiesIcon>
  </td>
  <td style="padding: 0.6em 0.4em">
    <CookiesIcon v-if="Access('OrdersEdit') & !ArchivedOrders & !value.Payment_recived_From_Driver & !value.Payment_Delivered_To_Cusotmer" :ToolTip="LanguageSelector('Edit', 'تعديل')" class="CookiesRowIcon HoverGlow" @click="OpenOrder(value)" :ID="'Edit'"></CookiesIcon>
    <CookiesIcon :ToolTip="DeepFetcher(value, ['Notes'])" :class="DeepFetcher(value, ['Notes']) !== '' ? '' : 'O-15i'" class="CookiesRowIcon O-100" :ID="'Info'"></CookiesIcon>
  </td>
  <!-- SELECTION COLUMN -->
  <td v-if="Access('OrdersEdit') & !ArchivedOrders & !value.Payment_recived_From_Driver & !value.Payment_Delivered_To_Cusotmer" class="MultiSelectCheckBox PhoneHider" style="z-index: 50; padding: 1em 1.5em;"><CookiesCheckBox class="F1_25em" :Res="'O_'+ value['ID'] +'_Select'"></CookiesCheckBox></td>
  <!-- <td v-if="ArchivedOrders" style="padding: 0.6em 0.4em">
    <CookiesIcon :ToolTip="LanguageSelector('Delete', 'حذف')" class="CookiesRowIcon HoverGlow" @click="Recover(value)" :ID="'Recover'"></CookiesIcon>
  </td> -->
  <OrderTimeLine :Order="value" class="OrderTimeLine" :OrdersHistory="OrdersHistory"></OrderTimeLine>
</template>
<script>
export default {
  emits: ['RemoveIssue'],
  data () {
    return {}
  },
  props: {
    index: { default: 0, Type: Number },
    value: { default: {} },
    OrdersHistory: { default: undefined, Type: Array },
    ArchivedOrders: { default: false, Type: Array }
  },
  methods: {
    OpenOrder (Order) {
      if (!this.Access('OrdersEdit')) {
        return
      }
      this.OpenDialog('EditOrder', this.LanguageSelector('Edit Order', 'تعديل طلب'), Order) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ChangeSelectedStatus (value) {
      this.$emit('RemoveIssue', value)
    }
  }
}
</script>

<style>
.PhoneElement{
  display: none;
  font-size: 0.9em;
  white-space: nowrap;
  color: v-bind('$store.getters.Theme.UserColors.fourth');
}
@media (max-width: 800px) {
  .PhoneHider{
    display: none;
  }
  .PhoneElement{
    display: block;
  }
  .CookiesGeneralTableRow td{
    border: none;
    max-width: none;
  }
}
.IssueButton > .IssueDeleteButton{
  top: 0.2em;
  left: 0.5em;
  width: 1.6em;
  height: 1.6em;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
  transform: scale(0) translateY(-3em);
  transition: 0.3s ease-out;
  transition-property: transform,opacity;
  background: v-bind('$store.getters.Theme.BG3');
  border-radius: 5em;
  padding: 0.4em;
  top: 2.2em;
  filter: drop-shadow(var(--shadow1))
}
.IssueButton:hover > .IssueDeleteButton{
  transform: scale(1) translateY(0);
  opacity: 1;
}
</style>
