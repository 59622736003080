<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <div class="M-Auto">
    <div class="DialogBody D-Flex" style="overflow: hidden; font-size: 22pt" id="printingBadge">
      <div class="D-Flex FlexWrap F-C2">
        <div class="CookiesRow F-C2 W-100" style="padding:0.2em; margin-top: 0;" :style="{background: $store.getters.Theme.UserColors.second}"></div>
        <div class="CookiesRowSpacer"/>
        <div class="CookiesRow F-C2 W-100">
          <span class="M-Auto F_5em" style="font-size: 22px !important;">{{DialogData.Name}}: <strong>{{DialogData.PlateNum}}</strong></span>
        </div>
        <div class="CookiesRow F-C2 W-100" style="margin-top: 0em; padding: 0.3em;" :style="{background: $store.getters.Theme.UserColors.third}">
          <div class="barcode M-Auto">
            <vue-barcode v-if="DialogData.BarCode !== ''" :value="DialogData.BarCode" :options="{ displayValue: true, fontSize: 16, height: 70, width: 1.5, background: 'rgba(0,0,0,0)'}" tag="svg"></vue-barcode>
          </div>
        </div>
        <div class="CookiesRow F-C2 W-100" style="padding: 0.3em;" :style="{background: $store.getters.Theme.UserColors.second}"></div>
      </div>
    </div>
    <div class="D-Flex" style="padding: 0.2em;">
      <CookiesIcon @click="PrintBadge" style="padding: 0.5em; border-radius: 100px;" ToolTip="Print" class="CookiesRowIcon HoverGlow" :ID="'Printer'" :style="{background: $store.getters.Theme.Light3}"></CookiesIcon>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    PrintBadge () {
      var bodd = document.getElementById('printingBadge')
      this.$store.state.PrintingOptions = {
        MinWidth: '',
        MaxWidth: '',
        FontSize: '',
        Padding: '',
        Margin: '',
        PageSize: 'A5 Portrait',
        NoHeader: true
      }
      var ToPrint = bodd.cloneNode([true])
      ToPrint.style.borderRadius = 0
      this.$store.state.Printing = ToPrint
    }
  }
}
</script>
<style scoped>

.DialogBody{
  width: 9cm;
  height: 5cm;
  background: white;
  border-radius: var(--rad1);
}
.barcode{
  display: flex;
  justify-content: center;
  width:7cm;
  max-width:7cm;
  background: var(--white);
  border: solid 1px rgb(167, 167, 167);
  border-radius: var(--rad3);
  overflow: hidden;
  filter: drop-shadow(var(--shadow1));
}
</style>
