<template>
  <div v-if="Access('AreasView')" class="D-Flex">
    <div class="D-Flex M-Auto F_75em">
      <input v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="$store.state.CookiesConfig.Language === 'ARA' ? 'بحث...': '' + $store.state.CookiesConfig.Language === 'ENG' ? 'Search...': ''">
    </div>
  </div>
    <div v-if="Access('AreasView')" class="BigBody">
      <div class="TableBodyContainer">
        <div class=" CookiesRow">
          <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
            <tr>
              <CookiesSortingTH @click="SortAreas (['Name'])" :Name="LanguageSelector('Area Name', 'اسم المنطقة')" :Value="['Name']" :sortColumn="sortColumn" ></CookiesSortingTH>
              <CookiesSortingTH @click="SortAreas (['Fees'])" :Name="LanguageSelector('Delivery Fees', 'عمولة التوصيل')" :Value="['Fees']" :sortColumn="sortColumn" ></CookiesSortingTH>
            </tr>
            <tr v-if="FilterdTable.length === 0 & !Loading"><td colspan="50">{{LanguageSelector('No Data', 'لا يوجد معلومات')}}</td></tr>
            <tr v-if="Loading"><td colspan="10" class="" style="padding: 1.5em 0.2em;"><div class="D-Flex W-100 H-100"><CookiesLoading class="M-Auto" style="place-content: center;"></CookiesLoading></div></td></tr>
            <template v-if="!Loading">
              <tr v-for='(value, index) in FilterdTable' :key='(value, index)' class="CookiesAreaRow CookiesGeneralTableRow">
                <td>{{value['Name'] ? value['Name']: ''}}</td>
                <td @keypress="KeyPressed(value, 'Fees')" oncopy="return false" oncut="return false" onpaste="return false" style="cursor: text; direction: ltr;" @blur="ValueChanged(value, 'Fees', true)" :ref="value['ID'] + '_Fees'" class="RowNoClick" :class="Access('AreasEdit') ? 'EditableCell' : ''" :contenteditable="Access('AreasEdit') ? 'true' : 'false'">{{value['Fees'] ? value['Fees']: 0}}</td>
              </tr>
            </template>
          </table> <!-- TABLE END --------------------- -->
        </div>
      </div>
    </div>
</template>

<script scoped>
export default {
  mounted () {
    this.ReadAreas()
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  data () {
    return {
      ImageSRC: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
      CookiesItemsCategories: [],
      CookiesAreas: [],
      Search: '',
      FilterStyle: false,
      sortColumn: {
        name: ['Name'],
        value: 1
      },
      RactiveHeader: {
        ID: ['ID'],
        Name: ['Name'],
        Fees: ['Fees'],
        DriverExtraFees: ['DriverExtraFees']
      },
      CookiesPages: [],
      Loading: true
    }
  },
  computed: {
    FilterdTable () {
      var FilteredArray = []
      var SortedWithIDs = this.sortedAreas.map(function (item) {
        item.Hashed_ID = '#' + item.ID
        return item
      })
      SortedWithIDs.forEach(item => {
        var FilterIt = true
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedAreas () {
      var Sorted = this.CookiesAreas
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    SelectedAreas () {
      var Selected = []
      this.FilterdTable.forEach(element => {
        if (this.$store.state.CookiesMemory['S_' + element.ID + '_Select'] & !(element.Area_Status === 'Done' || element.Area_Status === 'Canceled')) {
          Selected.push(element)
        }
      })
      return Selected
    }
  },
  methods: {
    ReadAreas () {
      this.Loading = true
      this.Get('Customers?ID=' + this.DialogData.Id).then(response => {
        if (response.Area !== null) {
          this.CookiesAreas = response.Area
        }
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Customer'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    CookiesAddArea () {
      this.OpenDialog('NewArea', this.LanguageSelector('New Area', 'منطقة جديدة'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    OpenArea (Area) {
      if (event.target.classList.length > 0) {
        if ((event.target.classList.includes('RowNoClick'))) {
          return
        }
      }
      this.OpenDialog('EditArea', 'Edit Area ' + Area.PlateNum, Area) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    Sorting (a, b) {
      var SortElement = this.sortColumn.name
      var SortDirection = this.sortColumn.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortAreas (name) {
      if (JSON.stringify(this.sortColumn.name) === JSON.stringify(name)) {
        this.sortColumn.value = this.sortColumn.value * -1
      } else {
        this.sortColumn.name = this.DeepCloner(name)
      }
    },
    ValueChanged (Area, Attribute, Num = false) {
      if (!this.Access('AreasEdit')) {
        return
      }
      var value = this.$refs[Area.ID + '_' + Attribute][0].innerText
      if (Num) {
        value = parseFloat(value)
      }
      var originalArea = this.DeepCloner(Area)
      var NewArea = this.DeepCloner(Area)
      this.DeepChanger(NewArea, [Attribute], value)
      var Patch = this.CookiesPatcher(originalArea, NewArea)
      this.Post('PATCH', 'EditCustomerArea?CustomerId=' + this.DialogData.Id + '&AreaID=' + Area.ID, Patch).then(response => {
        this.ReadAreas()
      }, error => {
        error.CookiesError = 'Error in updating Area Name'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    KeyPressed (Area, Attribute) {
      if ((event.which) === 13) {
        this.$refs[Area.ID + '_' + Attribute].blur()
      }
      this.OnlyNumbersKeyPress()
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadAreas()
    }
  }
}
</script>
<style scoped>
.CookiesRowIcon{
  padding: 0px 11px;
  cursor: pointer;
  width: 1.5em;
}
.BigPlaceholder{
  width: 75%;
  padding: 10px;
  /* min-height: 800px; */
  /* height: 80vh; */
}
.FilterPlaceholder{
  width: 15%;
  padding: 10px;
}
.FilterBG{
  display: none;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
@media (max-width: 1200px) {
  .FilterPlaceholder{
    position: fixed;
    z-index: 40;
    left: 0;
    padding: 10px 0px;
  }
  .FilterBG{
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.7);
    display: block;
    opacity: 1;
  }
  .FiltersContainer{
    border-radius: 0px var(--rad2) var(--rad2) 0px !important;
    /* overflow-y: scroll; */
  }
  .BigPlaceholder{
    width: 100%;
    padding: 5px 0px;
  }
  .FilterBlock{
    padding: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .FilterChunck{
    /* margin: auto !important; */
    width: unset !important;
  }
}
.BigContainer{
  background: v-bind('$store.getters.Theme.BG2');
  border-radius: var(--rad1);
  filter: drop-shadow(var(--shadow1));
  width: 100%;
  /* height: 100%; */
  justify-content: center;
  text-align: center;
  /* display: inline-table; */
  margin: auto;
  display: flex;
  flex-direction: column;
}
.FiltersContainer{
  background: var(--colorw1);
  border-radius: var(--rad1);
  filter: drop-shadow(var(--shadow1));
  /* height: 100%; */
  /* justify-content: center; */
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
}

.BigHeader{
  width:100%;
  position: fixed;
  /* height:8em; */
  background-color: var(--shade1);
  border-radius: 0;
  text-align: left;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  left: 0;
}
.FilterHeader{
  width:100%;
  height:60px;
  text-align: left;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.BigBody{
  font-size: 1rem;
  border-radius: var(--rad1);
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  display: flex;
  margin: auto;
  height: 70vh;
}

.DialogHeaderButton{
  padding: 0.2em 1.3em;
  margin: 0.6em 1em;
  /* margin: 10px 3px; */
  /* position: absolute; */
  left: 1em;
  font-weight: 500;
}

.FilterCategory{
  width: 100%;
  padding: 0.6em;
  /* font-size: 18px; */
  font-weight: 500;
  text-align: left;
}
.FilterChunck{
  padding: 0.3em;
  margin: unset;
  width: 100%;
  display: flex;
}
.PriceFilterChunck{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}

.FilterBlock{
  padding: 0.6em;
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  border-top: solid 1px var(--shade1)
}
.BigHeaderText{
  margin: auto 1em auto 1.5em;
}
@media (max-width: 500px) {
  .CookiesMainView {
      padding-right: 3px;
      padding-left: 3px;
  }
  .BigHeader{
    display: flex;
    flex-direction: column;
  }
  .BigHeader>>>*{
    font-size: 1.6em !important;
  }
  .DialogHeaderButton{
    margin-left: auto;
    margin-right: 1em;
  }
  .CookiesSearchInputContainer{
    margin: auto !important;
  }
  .StatusBar>>>*{
    font-size: 1.3em !important;
  }
  .BigBody>>>*{
    font-size: 3vw !important;
  }
  .StatusBar{
    margin-top: -6.5em !important;
  }
  .TableBodyContainer{
    margin-top: 0em!important;
  }
  .StatusIcons{
    margin-left: 2em;
  }
}
.TableBodyContainer{
  border-top: solid 1px rgba(0, 0, 0, 0.034);
  width: 100%;
  /* padding: 1.6em; */
  overflow-y: scroll;
  margin-top: 0em;
  min-width: 60vw;
}
@media (max-width: 1400px) {
  .BigPlaceholder>>>*{
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder>>>*{
    font-size: 1.2vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}
.AreasHeader{
  cursor: pointer;
}
.ChangeStatusIcon{
  width: 2.2em;
  padding: 0em 1em;
  cursor: pointer;
}
.ChangeStatusIcon:hover{
  opacity: 1 !important;
  filter: brightness(1.2);
}
.PayIcon{
  cursor: pointer;
}
.PayIcon:hover{
  opacity: 1 !important;
  filter: brightness(1.2);
}
.NoteCell{
  max-width: 10em;
  overflow: hidden;
  outline: none;
  cursor: text;
}
.NoteCell:focus{
  background: var(--color2);
}
</style>
