<template>
  <div class="Transition0_5"><CookiesIcon class="M-Auto" :Color="ArrowStyler.Color" style="width: 1em; height: 1em;" :style="'transform: ' + ArrowStyler.Transform" :ID="'SortingArrow'"></CookiesIcon></div>
</template>
<script>
export default {
  props: {
    Value: { default: 0 },
    Direction: { default: '', Type: String },
    NoColor: { default: false, Type: Boolean }
  },
  mounted () {},
  computed: {
    ArrowStyler () {
      var Style = []
      if (this.Direction.toLowerCase() === 'down') {
        Style.Color = 'var(--colorr2)'
        Style.Transform = 'rotate(0deg)'
      } else if (this.Direction.toLowerCase() === 'up') {
        Style.Color = 'var(--colorg2)'
        Style.Transform = 'rotate(180deg)'
      } else if (this.Direction.toLowerCase() === 'right') {
        Style.Color = 'var(--black)'
        Style.Transform = 'rotate(-90deg)'
      } else if (this.Direction.toLowerCase() === 'left') {
        Style.Color = 'var(--colorg2)'
        Style.Transform = 'rotate(90deg)'
      } else if (this.Value < 0) {
        Style.Color = 'var(--colorr2)'
        Style.Transform = 'rotate(0deg)'
      } else {
        Style.Color = 'var(--colorg2)'
        Style.Transform = 'rotate(180deg)'
      }
      if (this.NoColor) {
        Style.Color = ''
      }
      return Style
    }
  }
}
</script>

<style scoped>

</style>
