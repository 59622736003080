<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody D-Flex">
      <div class="CookiesRow F-C2">
        <div class="CookiesRow F1_25em W-100">
          <span class=" M-Auto color3">{{LanguageSelector('Are you sure you want to sign out ', ' هل انت متأكد من انك تريد تسجيل الخروج ')}}</span>
        </div>
        <div class="W-100" style="height:20px;"></div>
        <div class="M-Auto F-Bold">
          <button @click="$router.go(-1)" :style="'background: ' + $store.getters.Theme.Shade3" class="F-Bold CookiesButton DialogFooterButton">{{LanguageSelector('Cancel', 'الغاء')}}</button>
          <button @click="SignOut" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{LanguageSelector('Sign Out', 'تسجيل خروج')}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      DeleteConfirmed: false,
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    SignOut () {
      this.Saving = true
      this.LogOut()
    }
  },
  beforeUnmount () {
    if (this.DeleteConfirmed & this.DialogData.length > 2) {
      this.CloseDialog(this.DialogData[2])
    }
  }
}
</script>
<style scoped>

.DialogBody{
  width: 600px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesDialogdBody{
  margin-bottom: 0px;
}
</style>
