<template>
  <div class="D-Flex">
    <div class="D-Flex M-Auto F_75em">
      <input v-model="Search" class="CookiesSearchInput color2 F1_25em" :placeholder="$store.state.CookiesConfig.Language === 'ARA' ? 'بحث...': '' + $store.state.CookiesConfig.Language === 'ENG' ? 'Search...': ''">
    </div>
  </div>
    <div class="BigBody">
      <div class="TableBodyContainer">
        <div class=" CookiesRow">
          <table v-if="FilterdTable" class="W-100 CookiesGeneralTable" CELLSPACING=0> <!-- TABLE --------------------- -->
            <tr>
              <th @click="SortLines (RactiveHeader['Name'])" class="Pointer">{{LanguageSelector('Line Name', 'اسم الخط')}}
                <div style="margin-left: 0.3em; margin-top: -0.3em; width:0.7em; display: inline-block; vertical-align: middle; user-select: none;">
                  <div class="Transition0_5 color3" v-if="sortElment.name === RactiveHeader['Name']" :style="sortElment.value === -1 ? {transform: 'rotate(90deg)'} : sortElment.value === 1 ? {transform: 'rotate(-90deg)'} : '' " src="@/assets/icons/SortArrow.svg" alt="" style="width:100%">&#9654;</div>
                </div>
              </th>
              <th @click="SortLines (RactiveHeader['Driver'])" class="Pointer">{{LanguageSelector('Driver', 'السائق')}}
                <div style="margin-left: 0.3em; margin-top: -0.3em; width:0.7em; display: inline-block; vertical-align: middle; user-select: none;">
                  <div class="Transition0_5 color3" v-if="sortElment.name === RactiveHeader['Driver']" :style="sortElment.value === -1 ? {transform: 'rotate(90deg)'} : sortElment.value === 1 ? {transform: 'rotate(-90deg)'} : '' " src="@/assets/icons/SortArrow.svg" alt="" style="width:100%">&#9654;</div>
                </div>
              </th>
              <!-- <th></th> -->
            </tr>
            <tr v-for='(value, index) in FilterdTable' :key='(value, index)' class="CookiesLineRow">
              <!-- <td><strong>{{'#'+value['ID']}}</strong></td> -->
              <!-- <td>{{value['Type'] ? value['Type']: 'N/A'}}</td> -->
              <td class="EditableCell" @blur="ValueChanged(value, ['Name'], 'Line_' + value.ID)" :ref="'Line_' + value.ID" contenteditable="true">{{value['Name'] ? value['Name']: 'N/A'}}</td>
              <td><CookiesDropDown :IndexSelect="DriverSelector(value)" :Type="'Object'" :Selector="['Employee', 'Name']" @slection_changed="DriverChanged(value, ['DefaultDriver'], $store.state.CookiesMemory['LineDriver_' + value.ID])" :Radio="true" :Options="CookiesDriversOptions" :Res="'LineDriver_' + value.ID"></CookiesDropDown></td>
              <td><CookiesIcon @click="RemoveLine(value)" class="HoverGlow" style="width:2em; height:2em;" :ID="'Delete'"></CookiesIcon></td>
              <!-- <td><img @click="RemoveLine(value)" class="CookiesRowIcon RowNoClick" src="@/assets/icons/X.svg"></td> -->
            </tr>
          </table> <!-- TABLE END --------------------- -->
        </div>
      </div>
    </div>
</template>

<script scoped>
export default {
//   created () {
//     window.addEventListener('resize', this.WindowChanged)
//   },
//   unmounted () {
//     window.removeEventListener('resize', this.WindowChanged)
//   },
  mounted () {
    this.ReadLines()
    this.ReadDrivers()
    this.CookiesLines = this.DialogData
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  data () {
    return {
      ImageSRC: 'https://media.sproutsocial.com/uploads/2017/02/10x-featured-social-media-image-size.png',
      CookiesItemsCategories: [],
      CookiesLines: [],
      Search: '',
      FilterStyle: false,
      sortElment: {
        name: ['Name'],
        value: 1
      },
      RactiveHeader: {
        ID: ['ID'],
        Name: ['Name'],
        Fees: ['Fees'],
        Driver: ['DefultDriver', 'Name']
      },
      CookiesDataDrivers: []
    }
  },
  computed: {
    FilterdTable () {
      var FilteredArray = []
      var SortedWithIDs = this.sortedLines.map(function (item) {
        item.Hashed_ID = '#' + item.ID
        return item
      })
      SortedWithIDs.forEach(item => {
        var FilterIt = true
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    sortedLines () {
      var Sorted = this.CookiesLines
      return Sorted.sort(this.Sorting)
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    SelectedLines () {
      var Selected = []
      this.FilterdTable.forEach(element => {
        if (this.$store.state.CookiesMemory['S_' + element.ID + '_Select'] & !(element.Line_Status === 'Done' || element.Line_Status === 'Canceled')) {
          Selected.push(element)
        }
      })
      return Selected
    },
    SameStatus () {
      if (this.SelectedLines.length > 0) {
        var AllStatus = this.SelectedLines[0].Line_Status
      } else {
        return 'None'
      }
      this.SelectedLines.forEach(element => {
        if (element.Line_Status !== AllStatus) {
          AllStatus = 'Mix'
        }
      })
      return AllStatus
    },
    CurrentDate () {
      return this.$store.state.CurrentDate
    },
    CookiesDriversOptions () {
      var WithOption = [
        {
          Employee: {
            Name: this.LanguageSelector('Not Specified', 'غير محدد')
          }
        }
      ]
      // WithOption.concat(this.CookiesDataDrivers)
      this.CookiesDataDrivers.forEach(driver => {
        WithOption.push(driver)
      })
      return WithOption
    }
  },
  methods: {
    ReadLines () {
      this.Get('Area/getLine').then(response => {
        this.CookiesLines = response
      }, error => {
        error.CookiesError = 'Error in reading Lines'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDrivers () {
      this.Get('Employee/getdriver').then(response => {
        this.CookiesDataDrivers = response
      }, error => {
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Sorting (a, b) {
      var SortElement = this.sortElment.name
      var SortDirection = this.sortElment.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    SortLines (name) {
      if (this.sortElment.name === name) {
        this.sortElment.value = this.sortElment.value * -1
      } else {
        this.sortElment.name = name
      }
    },
    ValueChanged (Line, Attribute, ref, Num = false) {
      var value = this.$refs[ref].innerText
      if (Num) {
        value = parseFloat(value)
      }
      var originalLine = this.DeepCloner(Line)
      var NewLine = this.DeepCloner(Line)
      this.DeepChanger(NewLine, [Attribute], value)
      var Patch = this.CookiesPatcher(originalLine, NewLine)
      this.Post('PATCH', 'Area/EditLine?ID=' + Line.ID, Patch).then(response => {
        this.ReadLines()
      }, error => {
        error.CookiesError = 'Error in updating Line Name'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DriverChanged (Line, Attribute, value, Num = false) {
      if (Num) {
        value = parseFloat(value)
      }
      if (this.DeepFetcher(value, Attribute) === this.LanguageSelector('Not Specified', 'غير محدد')) {
        value = {}
      }
      this.Post('POST', 'Area/AssginLineDriver?LineID=' + Line.ID + '&DriverID=' + value.Id, {}).then(response => {
        this.ReadLines()
      }, error => {
        error.CookiesError = 'Error in updating Driver Line'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    RemoveLine (Line) {
      this.OpenDialog('DeleteDialog', 'Deleting Line', ['DeleteLine?ID=' + Line.ID, Line.Name]) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    DriverSelector (Line) {
      var DriverID = 0
      var I = 0
      try {
        DriverID = this.DeepFetcher(Line, ['DefultDriver', 'Employee', 'Id'])
        I = this.IndexFinder(this.CookiesDriversOptions, ['Employee', 'Id'], DriverID)
      } catch (error) {
        console.log(error)
      }
      if (I < 0) {
        I = 0
      }
      return I
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadLines()
    }
  }
}
</script>
<style scoped>
.CookiesRowIcon{
  padding: 0px 11px;
  cursor: pointer;
  width: 1.5em;
}
.BigPlaceholder{
  width: 75%;
  padding: 10px;
  /* min-height: 800px; */
  /* height: 80vh; */
}
.FilterPlaceholder{
  width: 15%;
  padding: 10px;
}
.FilterBG{
  display: none;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
@media (max-width: 1200px) {
  .FilterPlaceholder{
    position: fixed;
    z-index: 40;
    left: 0;
    padding: 10px 0px;
  }
  .FilterBG{
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background: rgba(0,0,0,0.7);
    display: block;
    opacity: 1;
  }
  .FiltersContainer{
    border-radius: 0px var(--rad2) var(--rad2) 0px !important;
    /* overflow-y: scroll; */
  }
  .BigPlaceholder{
    width: 100%;
    padding: 5px 0px;
  }
  .FilterBlock{
    padding: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .FilterChunck{
    /* margin: auto !important; */
    width: unset !important;
  }
}
.BigContainer{
  background: v-bind('$store.getters.Theme.BG2');
  border-radius: var(--rad1);
  filter: drop-shadow(var(--shadow1));
  width: 100%;
  /* height: 100%; */
  justify-content: center;
  text-align: center;
  /* display: inline-table; */
  margin: auto;
  display: flex;
  flex-direction: column;
}
.FiltersContainer{
  background: var(--colorw1);
  border-radius: var(--rad1);
  filter: drop-shadow(var(--shadow1));
  /* height: 100%; */
  /* justify-content: center; */
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  vertical-align: top;
}

.BigHeader{
  width:100%;
  position: fixed;
  /* height:8em; */
  background-color: var(--shade1);
  border-radius: 0;
  text-align: left;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  left: 0;
}
.FilterHeader{
  width:100%;
  height:60px;
  text-align: left;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.BigBody{
  font-size: 1rem;
  border-radius: var(--rad1);
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  display: flex;
  margin: auto;
  height: 70vh;
}

.DialogHeaderButton{
  padding: 0.2em 1.3em;
  margin: 0.6em 1em;
  /* margin: 10px 3px; */
  /* position: absolute; */
  left: 1em;
  font-weight: 500;
}

.FilterCategory{
  width: 100%;
  padding: 0.6em;
  /* font-size: 18px; */
  font-weight: 500;
  text-align: left;
}
.FilterChunck{
  padding: 0.3em;
  margin: unset;
  width: 100%;
  display: flex;
}
.PriceFilterChunck{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}

.FilterBlock{
  padding: 0.6em;
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  border-top: solid 1px var(--shade1)
}
.BigHeaderText{
  margin: auto 1em auto 1.5em;
}
@media (max-width: 500px) {
  .CookiesMainView {
      padding-right: 3px;
      padding-left: 3px;
  }
  .BigHeader{
    display: flex;
    flex-direction: column;
  }
  .BigHeader>>>*{
    font-size: 1.6em !important;
  }
  .DialogHeaderButton{
    margin-left: auto;
    margin-right: 1em;
  }
  .CookiesSearchInputContainer{
    margin: auto !important;
  }
  .StatusBar>>>*{
    font-size: 1.3em !important;
  }
  .BigBody>>>*{
    font-size: 3vw !important;
  }
  .StatusBar{
    margin-top: -6.5em !important;
  }
  .TableBodyContainer{
    margin-top: 0em!important;
  }
  .StatusIcons{
    margin-left: 2em;
  }
}
.CookiesLineRow:nth-child(even){
  background-color: var(--shade0);
}
.TableBodyContainer{
  border-top: solid 1px rgba(0, 0, 0, 0.034);
  width: 100%;
  padding: 1.6em;
  overflow-y: scroll;
  margin-top: 0em;
  min-width: 60vw;
}
@media (max-width: 1400px) {
  .BigPlaceholder>>>*{
    font-size: 0.8vw;
  }
}
@media (max-width: 1200px) {
  .BigPlaceholder>>>*{
    font-size: 1.2vw;
  }
  .FilterPlaceholder{
    font-size: 15px;
  }
}
.LinesHeader{
  cursor: pointer;
}
.ChangeStatusIcon{
  width: 2.2em;
  padding: 0em 1em;
  cursor: pointer;
}
.ChangeStatusIcon:hover{
  opacity: 1 !important;
  filter: brightness(1.2);
}
.PayIcon{
  cursor: pointer;
}
.PayIcon:hover{
  opacity: 1 !important;
  filter: brightness(1.2);
}
.NoteCell{
  max-width: 10em;
  overflow: hidden;
  outline: none;
  cursor: text;
}
.NoteCell:focus{
  background: var(--color2);
}
</style>
