<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <div class="M-Auto" style="">
    <div class="DialogBody D-Flex" style="overflow: hidden; font-size: 22pt" id="printingBadge">
      <div class="D-Flex FlexWrap F-C2">
        <div class="CookiesRow F-C2 W-100" style="padding:0.2em; margin-top: 0;" :style="{background: $store.getters.Theme.UserColors.second}"></div>
        <div class="CookiesRowSpacer"/>
        <div class="CookiesRow F-C2 W-100" style="margin-top: 0.75em; margin-bottom: -0.75en">
          <div class="UserImage M-Auto" :style="'background-image: url(' + DefaultImageHandler(DialogData.ImgURL, $store.state.CookiesConfig.UserImage) + ');'"></div>
        </div>
        <div class="CookiesRow F-C2 W-100">
          <span class="M-Auto F_5em" style="font-size: 22px !important;">{{DialogData.Name}}</span>
        </div>
        <div class="CookiesRow F-C2 W-100" style="padding-top: 0.2em;">
          <span class="M-Auto F_5em" style="font-size: 22px !important;">{{DialogData.PhoneNumber}}</span>
        </div>
        <div class="CookiesRow F-C2 W-100" style="margin-top: 1.3em; padding: 0.3em;" :style="{background: $store.getters.Theme.UserColors.third}">
          <div class="barcode M-Auto">
            <vue-barcode v-if="DialogData.BarCode !== ''" :value="DialogData.BarCode" :options="{ displayValue: true, fontSize: 16, height: 50, width: 1.5, background: 'rgba(0,0,0,0)'}" tag="svg"></vue-barcode>
          </div>
        </div>
        <div class="CookiesRow F-C2 W-100" style="padding: 0.3em;" :style="{background: $store.getters.Theme.UserColors.second}"></div>
      </div>
    </div>
    <div class="D-Flex" style="padding: 0.2em;">
      <CookiesIcon style="padding: 0.5em; border-radius: 100px;" ToolTip="Print" class="CookiesRowIcon HoverGlow" @click="PrintBadge(value)" :ID="'Printer'" :style="{background: $store.getters.Theme.Light3}"></CookiesIcon>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    PrintBadge () {
      var bodd = document.getElementById('printingBadge')
      this.$store.state.PrintingOptions = {
        MinWidth: '',
        MaxWidth: '',
        FontSize: '',
        Padding: '',
        Margin: '',
        PageSize: 'A5 portrait',
        NoHeader: true
      }
      var ToPrint = bodd.cloneNode([true])
      ToPrint.style.borderRadius = 0
      this.$store.state.Printing = ToPrint
    }
  }
}
</script>
<style scoped>

.DialogBody{
  width: 6.2cm;
  height: 10cm;
  background: white;
  border-radius: var(--rad1);
}
.UserImage{
  width: 2.75cm;
  height: 2.75cm;
  margin-bottom: 1em;
  background-color: var(--shade2);
  border-radius: 5em;
  /* box-shadow: inset var(--shadow1); */
  background-size: cover;
  background-position: center;
}
.UserImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
.barcode{
  display: flex;
  justify-content: center;
  width:5cm;
  max-width:5cm;
  background: var(--white);
  border: solid 1px rgb(167, 167, 167);
  border-radius: var(--rad3);
  overflow: hidden;
  filter: drop-shadow(var(--shadow1));
}
</style>
