<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <div class="M-Auto DialogContainer">
    <div class="DialogBody D-Flex">
      <div class="CookiesRow F-S3 F-C2">
        <span>Empty</span>
      </div>
    </div>
  </div>
</div>
<div class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">Cancel</button>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {}
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  }
}
</script>
<style scoped>

.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
</style>
