<template>
<div v-if="Access('Settlement')" style="white-space: nowrap;" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer color3">
    <div class="CookiesRow F-Bold F1_75em W-100" style="padding:5px; justify-content:">
      <div class="M-Auto D-Flex FlexWrap" style="white-space: normal;"><span><strong>{{' ' + DeepFetcher(DialogData, ['Header']) + ' '}}</strong></span></div>
    </div>
    <div class="CookiesRowSplitter"></div>
    <div class="DialogBody">
      <div class="CookiesRow F-Bold">
        <template v-if="!NormalUser">
          <div class="ContentChunck F1_25em" style="flex-direction: column;">
            <div class="W-100 D-Flex" style="margin-bottom: -0.7em;">
              <CookiesNumberInput :Name="LanguageSelector('count of 50 JDs', 'من فئة 50 دينار')" MinWidth="1.5em" style="margin-left: 0;" :Res="'D_New_Settlement_JD50'" :Default="0" ></CookiesNumberInput><CookiesIcon style="width:3em; height:3em; padding: 0 0.6em;" :ID="'JD50'"></CookiesIcon>
            </div>
            <div class="W-100 D-Flex" style="margin-bottom: -0.7em;">
              <CookiesNumberInput :Name="LanguageSelector('count of 20 JDs', 'من فئة 20 دينار')" MinWidth="1.5em" style="margin-left: 0;" :Res="'D_New_Settlement_JD20'" :Default="0" ></CookiesNumberInput><CookiesIcon style="width:3em; height:3em; padding: 0 0.6em;" :ID="'JD20'"></CookiesIcon>
            </div>
            <div class="W-100 D-Flex" style="margin-bottom: -0.7em;">
              <CookiesNumberInput :Name="LanguageSelector('count of 10 JDs', 'من فئة 10 دنانير')" MinWidth="1.5em" style="margin-left: 0;" :Res="'D_New_Settlement_JD10'" :Default="0" ></CookiesNumberInput><CookiesIcon style="width:3em; height:3em; padding: 0 0.6em;" :ID="'JD10'"></CookiesIcon>
            </div>
            <div class="W-100 D-Flex" style="margin-bottom: -0.7em;">
              <CookiesNumberInput :Name="LanguageSelector('count of 5 JDs', 'من فئة 5 دنانير')" MinWidth="1.5em" style="margin-left: 0;" :Res="'D_New_Settlement_JD5'" :Default="0" ></CookiesNumberInput><CookiesIcon style="width:3em; height:3em; padding: 0 0.6em;" :ID="'JD5'"></CookiesIcon>
            </div>
            <div class="W-100 D-Flex" style="margin-bottom: -0.7em;">
              <CookiesNumberInput :Name="LanguageSelector('count of 1 JDs', 'من فئة 1 دينار')" MinWidth="1.5em" style="margin-left: 0;" :Res="'D_New_Settlement_JD1'" :Default="0" ></CookiesNumberInput><CookiesIcon style="width:3em; height:3em; padding: 0 0.6em;" :ID="'JD1'"></CookiesIcon>
            </div>
            <div class="W-100 D-Flex" style="margin-bottom: -0.7em;">
              <CookiesNumberInput :Name="LanguageSelector('Coins', 'فكة')" :Max="0.99" :Step="0.1" MinWidth="1.5em" style="margin-left: 0;" :Res="'D_New_Settlement_JDCoins'" :Unit="$store.state.CookiesConfig.PriceUnit" :Default="0" ></CookiesNumberInput><CookiesIcon style="width:3em; height:3em; padding: 0 0.6em;" :ID="'JDCoins'"></CookiesIcon>
            </div>
          </div>
        </template>
        <div v-if="!NormalUser" class="CookiesRowSplitter"></div>
        <div v-if="NormalUser" class="ContentChunck F1_25em" style="margin-bottom: -0.7em;">
          <CookiesNumberInput :PreserveMemory="false" :Name="DialogData.Category === 'Cash' ? LanguageSelector('Available Ammount: ', 'القيمة الموجوده: ') : DialogData.BalanceType === 'Debiter' ? LanguageSelector('Payment Ammount: ', 'القيمة المدفوعة: ') : LanguageSelector('Payment Ammount: ', 'القيمة المقبوضة: ')" MinWidth="2em" style="margin-top:-8px;" :Unit="$store.state.CookiesConfig.PriceUnit" :Res="'D_New_Settlement_Ammount'" :Default="DeepFetcher(DialogData, ['NetAmmount'])" ></CookiesNumberInput>
        </div>
        <div v-if="!NormalUser" class="ContentChunck F1_25em" style="margin-bottom: -0.7em;">
          <span style="">{{DialogData.Category === 'Cash' ? LanguageSelector('Available Ammount: ', 'القيمة الموجوده: ') : DialogData.BalanceType === 'Debiter' ? LanguageSelector('Payment Ammount: ', 'القيمة المدفوعة: ') : LanguageSelector('Payment Ammount: ', 'القيمة المقبوضة: ')}}<strong class="F1_5em">{{DecimalFixer(CashAmmount)}} {{$store.state.CookiesConfig.PriceUnit}}</strong></span>
        </div>
        <div v-if="DialogData.Category !== 'Cash'" class="W-100 F1_25em" style="padding: 1em 0; margin-bottom: -0.7em;">
          <span v-if="DialogData.BalanceType === 'Debiter'">{{LanguageSelector('Settlement ammount: ', 'القيمة المستحقة: ')}}</span><span v-if="DialogData.BalanceType === 'Crediter'">{{LanguageSelector('Settlement ammount: ', 'القيمة المطلوبة: ')}}</span><span class="F1_75em">{{DecimalFixer(DeepFetcher(DialogData, ['NetAmmount']))}} {{$store.state.CookiesConfig.PriceUnit}}</span>
        </div>
        <div v-if="DialogData.Category === 'Cash'" class="W-100 F1_25em" style="padding: 1em 0; margin-bottom: -0.7em;">
          <span>{{LanguageSelector('Cash Ammount: ', 'قيمة الكاش: ')}}</span><span class="F1_75em">{{DecimalFixer(DeepFetcher(DialogData, ['NetAmmount']))}} {{$store.state.CookiesConfig.PriceUnit}}</span>
        </div>
        <div :style="DecimalFixer(CashAmmount) !== DecimalFixer(DeepFetcher(DialogData, ['NetAmmount'])) ? '': 'opacity: 0'" class="CookiesRowSplitter"></div>
        <div :style="DecimalFixer(CashAmmount) !== DecimalFixer(DeepFetcher(DialogData, ['NetAmmount'])) ? '': 'opacity: 0'" class="W-100 F1_25em" style="padding: 0.5em 0;">
          <span>{{LanguageSelector('Residual Ammount: ', 'القيمة المتبقية: ')}}</span>
          <span :style="DeepFetcher(DialogData, ['NetAmmount']) - CashAmmount < 0 ? 'color: var(--colorg3);': 'color: var(--colorr1);'" class="">{{DecimalFixer(CashAmmount - DeepFetcher(DialogData, ['NetAmmount']))}} {{$store.state.CookiesConfig.PriceUnit}}</span>
        </div>
        <div v-if="DialogData.Category !== 'Cash'" :style="CashAmmount - DeepFetcher(DialogData, ['NetAmmount']) !== 0 ? '': 'opacity: 0'" class="W-100 F1_25em D-Flex" style="padding: 0.5em 0;">
          <div class="M-Auto D-Flex" style="flex-direction: column;">
            <CookiesRadioButton style="padding: 0.2em 0;" :style="$store.getters.Direction === 'ltr' ? 'margin-left: 0;' : 'margin-right: 0;'" :Name="LanguageSelector('Delay for next Settlement', 'تأجيل للحساب القادم')" :Res="D_Settlement_Overflow" :Value="true" :Group="'OverflowType'"></CookiesRadioButton>
            <CookiesRadioButton style="padding: 0.2em 0;" :style="$store.getters.Direction === 'ltr' ? 'margin-left: 0;' : 'margin-right: 0;'" :Name="LanguageSelector('Deducted from our account ', 'اقتطاع من حسابنا')" :Res="D_Settlement_Overflow" :Value="false" :Group="'OverflowType'"></CookiesRadioButton>
          </div>
        </div>
      <!-- <div class="CookiesRowSplitter W-100"/> -->
      </div>
    </div>
      <!-- <div class="CookiesRow W-100">
        <textarea v-model="SettlementNotes" class="CookiesCommentBox" name="Comments" :placeholder="LanguageSelector('Notes', 'ملاحظات')" autocomplete="off"></textarea>
      </div> -->
  </div>
</div>
<div v-if="Access('Settlement')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold F1em CookiesButton DialogFooterButton">{{LanguageSelector('Cancel', 'الغاء')}}</button>
    <button @click="NewSettlement" class="F-Bold CookiesButton DialogFooterButton BGThird">{{LanguageSelector('Confirm', 'تأكيد')}}</button>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      SettlementNotes: '',
      Saving: false,
      NormalUser: true
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  created () {
    if (!this.Access('Settlement')) {
      return
    }
    try {
      this.NormalUser = this.EnableUpnormalUser ? !((this.DialogData.Category.toLowerCase() === 'driver') || (this.DialogData.Category.toLowerCase() === 'cash')) : true
    } catch (error) {
    }
  },
  mounted () {
    if (!this.Access('Settlement')) {
      return
    }
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    SettlementType () {
      if (this.$store.state.CookiesMemory.D_New_Settlement_Ammount < 0) {
        return 'DEBIT'
      } else {
        return 'CREDIT'
      }
    },
    OverFlow () {
      if (this.MEMRead('OverflowType') !== undefined) {
        return '&OverFlow=' + this.MEMRead('OverflowType')
      } else {
        return ''
      }
    },
    CashAmmount () {
      var Ammount = 0
      if (this.NormalUser) {
        Ammount = this.$store.state.CookiesMemory.D_New_Settlement_Ammount
      } else {
        Ammount += this.$store.state.CookiesMemory.D_New_Settlement_JD50 * 50
        Ammount += this.$store.state.CookiesMemory.D_New_Settlement_JD20 * 20
        Ammount += this.$store.state.CookiesMemory.D_New_Settlement_JD10 * 10
        Ammount += this.$store.state.CookiesMemory.D_New_Settlement_JD5 * 5
        Ammount += this.$store.state.CookiesMemory.D_New_Settlement_JD1 * 1
        Ammount += this.$store.state.CookiesMemory.D_New_Settlement_JDCoins
      }
      return Ammount
    }
  },
  methods: {
    NewSettlement () {
      this.Saving = true
      var NewSettlementData = {
        Category: this.DialogData.Category,
        Ammount: this.CashAmmount,
        Notes: this.SettlementNotes
      }
      this.Post('POST', this.DialogData.APIURL + '&Cash=' + NewSettlementData.Ammount + this.OverFlow, {}).then(response => {
        this.Saving = false
        this.$router.go(-1)
      }, error => {
        this.Saving = false
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  }
}
</script>
<style scoped>

.DialogBody{
  width: 800px;
  max-width: 80vw;
  /* min-width: 275px; */
}
.ContentChunck{
  display: flex;
  padding: 0.5em;
  margin: auto;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}

</style>
