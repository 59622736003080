<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Employee -------------------------------------- -->
      <div class="CookiesRow">
        <div class="CookiesRow W-100 D-Flex">
          <label class="UserImage M-Auto" :style="'background-image: url(' + DefaultImageHandler(SelectedUserImage, $store.state.CookiesConfig.UserImage) + ');'">
            <input @change="UserImageChanged()" name="UserImage" type="file" accept="image/*">
          </label>
        </div>
        <input v-model="Name" :class=" (ErrorsVisibilty & !Name) && 'ErrorBorder' " class="CookiesInput color2" type="Text" name="Phone" :placeholder="$store.state.CookiesConfig.Language === 'ARA' ? 'الاسم': '' + $store.state.CookiesConfig.Language === 'ENG' ? 'Name': ''" autocomplete="off">
        <input v-model="PhoneNumber" :class=" (ErrorsVisibilty & !PhoneValidator(PhoneNumber)) && 'ErrorBorder'" class="CookiesInput color2" type="Text" name="Phone" :placeholder="$store.state.CookiesConfig.Language === 'ARA' ? 'الهاتف': '' + $store.state.CookiesConfig.Language === 'ENG' ? 'Phone': ''" autocomplete="off">
        <div v-if="ObjectFinder(EmpTypes, ['value'], DialogData.Type) && ObjectFinder(EmpTypes, ['value'], DialogData.Type).salary" class="CookiesRowSplitter"></div>
        <div v-if="ObjectFinder(EmpTypes, ['value'], DialogData.Type) && ObjectFinder(EmpTypes, ['value'], DialogData.Type).salary" class="D-Flex M-Auto"><span class="M-Auto">Salary</span><CookiesNumberInput style="padding: 0 2em;" :Min="1" :Unit="$store.state.CookiesConfig.PriceUnit" :Res="'EditEmpSalary'" :PlaceHolder="'Salary'"></CookiesNumberInput></div>
      </div>
      <div class="W-100" style="padding:5px">
        <div class="CookiesRowSplitter"/>
        <div class="W-100">
          <span>{{LanguageSelector('Sign In Info', 'معلومات تسجيل الدخول')}}</span>
        </div>
        <div class="CookiesRowSpacer"/>
        <div class="W-100">
          <span class="F1_25em F-Bold color2">{{Email}}</span> <span @click="ChangePassword()" style="margin: 1em;" class="F-Bold colorThird Pointer Glow">{{LanguageSelector('Change Password', 'تغير كلمه المرور')}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'الغاء': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Cancel': ''}}</button>
    <button @click="SaveEmployee()" :style="'background: ' + $store.getters.Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'حفظ': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Save': ''}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  beforeCreate () {
    this.$store.state.CookiesMemory.EditEmpType = {}
  },
  created () {},
  data () {
    return {
      Name: '',
      BarCode: '',
      PhoneNumber: '',
      Email: '',
      Status: '',
      Identity: {},
      ErrorsVisibilty: false,
      selectedFile: '',
      SelectedUserImage: '',
      UserImage: '',
      OriginalEmployeeData: {},
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    SaveEmployee () {
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      var OriginalItem = this.DeepCloner(this.OriginalEmployeeData)
      var NewItem = this.DeepCloner(this.EditEmployeeData)
      var Patch = this.CookiesPatcher(OriginalItem, NewItem)
      if (Patch.length === 0 & this.selectedFile !== null) {
        this.Saving = false
        this.$router.go(-1)
        return
      }
      if (!this.selectedFile || this.selectedFile === null || this.selectedFile === undefined) {
        this.Post('PATCH', 'Employee/EditEmployee?ID=' + this.OriginalEmployeeData.Id, Patch).then(response => {
          this.Saving = false
          this.CloseDialog(this.DialogIndex)
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Editing Employee'
          this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      } else {
        this.PostImage(this.selectedFile).then(response => {
          this.SelectedUserImage = response.url
          OriginalItem = this.DeepCloner(this.OriginalEmployeeData)
          NewItem = this.DeepCloner(this.EditEmployeeData)
          Patch = this.CookiesPatcher(OriginalItem, NewItem)
          this.Post('PATCH', 'Employee/EditEmployee?ID=' + this.OriginalEmployeeData.Id, Patch).then(response => {
            this.Saving = false
            this.CloseDialog(this.DialogIndex)
          }, error => {
            this.Saving = false
            error.CookiesError = 'Error in Editing Employee'
            this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Uploading Image'
          this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    },
    ChangePassword () {
      this.OpenDialog('ChangePassDialog', this.LanguageSelector('Change Password', 'تغير كلمة المرور'), this.DialogData) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    UserImageChanged () {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        this.SelectedUserImage = this.DialogData.ImgURL
        return
      }
      this.SelectedUserImage = URL.createObjectURL(file)
    }
  },
  mounted () {
    this.OriginalEmployeeData = JSON.parse(JSON.stringify(this.DialogData))
    this.Name = this.DialogData.Name
    this.BarCode = this.DialogData.BarCode
    this.PhoneNumber = this.DialogData.PhoneNumber
    this.Email = this.DialogData.Email
    if (this.DialogData.Salary !== undefined || this.DialogData.Salary !== null) {
      this.$store.state.CookiesMemory.EditEmpSalary = this.DialogData.Salary
    }
    this.SelectedUserImage = this.DialogData.ImgURL
    this.UserImage = this.DialogData.ImgURL
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    EditEmployeeData () {
      var EditedData = this.DeepCloner(this.OriginalEmployeeData)
      var Salary = 0
      if (this.$store.state.CookiesMemory.EditEmpType.EN !== 'Temp Driver') {
        Salary = this.$store.state.CookiesMemory.EditEmpSalary
      }
      EditedData.Name = this.Name
      EditedData.PhoneNumber = this.PhoneNumber
      EditedData.Salary = Salary
      EditedData.ImgURL = this.SelectedUserImage
      return EditedData
    },
    DialogError () {
      var DialogErrors = []
      this.Name === '' && DialogErrors.push('Please Enter Employee\'s Name')
      !this.PhoneValidator(this.PhoneNumber) && DialogErrors.push('Please Enter Employee\'s Name')
      return DialogErrors
    },
    isActive () {
      return this.$store.state.CookiesMemory.D_EditEmployee_Active
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 10px 25px;
  outline: none;
  font-size: 17px;
  margin: 6px;
  position: relative;
}

.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}

.barcode{
  display: flex;
  justify-content: center;
  width:300px;
  max-width:100%;
  height:100px;
  background: var(--white);
  border: solid 1px rgb(167, 167, 167);
  border-radius: var(--rad3);
  overflow: hidden;
  filter: drop-shadow(var(--shadow1));
}
.UserImage{
  width: 10em;
  height: 10em;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: 5em;
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.UserImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
</style>
