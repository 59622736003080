<template>
<div v-if="Access('OrdersAdd')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto P-Rel DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Order -------------------------------------- -->
      <div>
        <span :class="!NoBarCode ? 'ContentHidden': ''" class="Transition0_5 W-100 F1em F-CR">{{LanguageSelector('No Code', 'لا يوجد كود')}}</span>
      </div>
      <input v-if="!NoBarCode" @blur="BarCodeBlurred()" @focus="BarCodeClicked()" @keypress="BarCodeKeyPressed()" :ref="'BarCode'" maxlength="13" style="text-align: center;" v-model="BarCode" :class=" (ErrorsVisibilty & !Name) && 'ErrorBorder' " class="CookiesInput color2" type="Text" name="Phone" :placeholder="LanguageSelector('BarCode', 'BarCode')" autocomplete="off">
      <div v-if="!NoBarCode" :class="BarcodeValue === '' && 'barcodeBlur'" class="barcode M-Auto Transition0_5">
        <vue-barcode :value="BarCodeHandler" :options="{ displayValue: true, fontSize: 30, height: 100, width: 2.2, background: 'rgba(0,0,0,0)'}"></vue-barcode>
      </div>
      <div v-if="!NoBarCode" class="CookiesRowSpacer"/>
      <div :class="BarcodeEntered ? 'ContentHidden': ''" class="Transition0_5 CookiesRowSpacer"/>
      <span :class="BarcodeEntered ? 'ContentHidden': ''" class="Transition0_5 W-100 F2em F-CR">{{LanguageSelector('Please Scan the BarCode', 'الرجاء مسح الكود')}}</span>
      <div :class="!BarcodeEntered ? 'ContentHidden': ''" class="CookiesRowSplitter Transition0_5"/>
      <div :class="!BarcodeEntered ? 'ContentHidden': ''" class="W-100 Transition0_5">
        <div class="D-Flex InputPadding">
          <CookiesDropInputObject @keypress="EnterDetect" :ErrorData="ErrorsVisibilty & $store.state.CookiesMemory.NewOrderCustomer === ''" @selected="CustomerSelected" class="M-Auto" :Res="'NewOrderCustomer'" :Width="'6em'" :RowHeight="'2.3em'" :Options="CookiesDataCustomers" :Selectors="['Company']" :Name="LanguageSelector('Select Customer', 'اختر العميل')"></CookiesDropInputObject>
        </div>
        <div class="CookiesRowSplitter"/>
        <span class="W-100">{{LanguageSelector('Location', 'الموقع')}}</span>
        <div :ref="'NewOrderArea_Input'" class="D-Flex InputPadding">
          <CookiesDropInputObject @keypress="EnterDetect" :ErrorData="ErrorsVisibilty & $store.state.CookiesMemory.NewOrderArea === ''" :class="CookiesDataSubAreas.length === 0 && 'Disabled'" @selected="AreaSelected" class="M-Auto" :Res="'NewOrderArea'" :Width="'6em'" :RowHeight="'2.3em'" :Options="CookiesDataSubAreas" :Selectors="['Name']" :Name="LanguageSelector('Select Area', 'اختر منطقة')"></CookiesDropInputObject>
        </div>
        <div class="D-Flex InputPadding" :class="CookiesDataSubAreas.length === 0 && 'Disabled'">
          <input @keypress="EnterDetect" v-model="Address" class="CookiesInput color2" type="Text" name="Phone" :placeholder="LanguageSelector('Address', 'العنوان')" autocomplete="off">
        </div>
        <div class="CookiesRowSplitter"/>
        <div class="D-Flex" style="flex-flow: wrap;">
          <div class="CookiesRowSpacer"/>
          <div class="D-Flex M-Auto F1_25em W-100">
            <div class="D-Flex M-Auto FlexWrap" style="gap: 0.5em;">
              <CookiesNumberInput @keypress="EnterDetect" :MinWidth="'3em'" :Name="LanguageSelector('Total Amount', 'القيمة الكلية للطلب')" style="padding: 0 2em;     margin: auto 1em;" :Min="0" :Unit="$store.state.CookiesConfig.PriceUnit" :Default="5" :Step="0.25" :Res="'NewOrderAmmount'"></CookiesNumberInput>
            </div>
          </div>
        </div>
        <div class="CookiesRowSplitter"/>
        <div class="CookiesRow">
          <span class="W-100">{{LanguageSelector('Client Info', 'معلومات الزبون')}}</span>
          <input @keypress="EnterDetect()" v-model="Name"  class="CookiesInput color2" type="Text" name="Phon" :placeholder="LanguageSelector('Name', 'الاسم')" autocomplete="off">
          <input @keypress="EnterDetect()" :class=" (ErrorsVisibilty & !PhoneNumber) && 'ErrorBorder'" v-model="PhoneNumber" class="CookiesInput color2" type="Text" name="Phone" :placeholder="LanguageSelector('Phone', 'الهاتف')" autocomplete="off">
          <input @keypress="EnterDetect()" v-model="Email" class="CookiesInput color2" type="Text" name="Phone" :placeholder="LanguageSelector('Email', 'البريد الالكتروني')" autocomplete="off">
        </div>
        <div class="CookiesRowSplitter"/>
        <span class="W-100">{{LanguageSelector('Notes', 'الملاحظات')}}</span>
        <div class="CookiesRowSpacer"/>
        <div class="">
          <textarea v-model="Notes" class="CookiesCommentBox" name="Comments" :placeholder="LanguageSelector('Notes', 'ملاحظات')" autocomplete="off"></textarea>
        </div>
      </div>
      <button v-if="!NoBarCode & BarcodeValue === ''" @click="WithoutCode()" style="background: var(--colorr1);" class="F-Bold F1em CookiesButton DialogFooterButton">{{LanguageSelector('Continue without code', 'لا يوجد كود')}}</button>
    </div>
  </div>
</div>
<div v-if="Access('OrdersAdd')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold F1em CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'الغاء': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Cancel': ''}}</button>
    <button :class="!BarcodeEntered && 'Disabled'" @click="SaveOrder(false)" :style="'background: ' + $store.getters.Theme.UserColors.main" style="" class="F-Bold F1em CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'حفظ': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Save': ''}}</button>
    <button :class="!BarcodeEntered && 'Disabled'" @click="SaveOrder(true)" :style="'background: ' + $store.getters.Theme.UserColors.main" style="" class="F-Bold F1em CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'حفظ و طلب جديد': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Save + New Order': ''}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  created () {},
  data () {
    return {
      BarCode: '',
      Name: '',
      PhoneNumber: '',
      Email: '',
      Type: '',
      ErrorsVisibilty: false,
      selectedFile: '',
      UserImage: '',
      Location: '',
      Address: '',
      CookiesDataCustomers: [],
      CookiesDataSubAreas: [],
      SelectedArea: {},
      SelectedCustomer: '',
      BarcodeEntered: false,
      BarcodeValue: '',
      Notes: '',
      NoBarCode: false,
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    ReadCustomers () {
      this.Get('Customers').then(response => {
        this.CookiesDataCustomers = response
      }, error => {
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSubAreas () {
      this.Loading = true
      this.Get('SubArea').then(response => {
        this.CookiesDataSubAreas = response
        this.Loading = false
      }, error => {
        error.CookiesError = 'Error in reading SubAreas'
        this.Loading = false
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomersWithAreas () {
      this.Get('Customers?IncludeAreas=true').then(response => {
        this.CookiesDataCustomers = response
      }, error => {
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveOrder (KeepMe) {
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      this.Post('POST', 'order/CreateORder', this.NewOrderData).then(response => {
        this.Saving = false
        if (KeepMe) {
          this.SelectedArea = {}
          this.BarcodeValue = ''
          this.Address = ''
          this.Location = ''
          this.PhoneNumber = ''
          this.Name = ''
          this.Email = ''
          this.Notes = ''
          this.MEMWrite('NewOrderAmmount', 5)
          this.MEMWrite('NewOrderArea', '')
          this.ErrorsVisibilty = false
          this.$refs.NewOrderArea_Input.querySelector('input').focus()
        } else {
          this.CloseDialog(this.DialogIndex)
        }
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Adding New Order'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    AreaSelected (element) {
      this.SelectedArea = element
    },
    CustomerSelected (element) {
      this.SelectedCustomer = element
      this.$forceUpdate()
    },
    BarCodeKeyPressed () {
      if ((event.which) === 13 & this.BarCode !== '') {
        this.BarcodeEntered = true
        this.BarcodeValue = this.BarCode
        this.BarCode = ''
      }
    },
    BarCodeClicked () {
      this.BarcodeEntered = false
      this.BarcodeValue = ''
      this.BarCode = ''
    },
    BarCodeBlurred () {
      if (!this.BarcodeEntered) {
        this.$refs.BarCode && this.$refs.BarCode.focus()
      }
    },
    WithoutCode () {
      this.BarcodeEntered = true
      this.NoBarCode = true
    },
    ResetCustomer () {
      this.Console(this.$store.state.CookiesMemory.NewOrderCustomer, 'border: 1px solid red')
    },
    EnterDetect () {
      if ((event.which) === 13) {
        this.SaveOrder(true)
      }
    }
  },
  mounted () {
    if (!this.Access('OrdersAdd')) {
      return
    }
    this.ReadCustomers()
    this.ReadSubAreas()
    this.$refs.BarCode.focus()
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    BarCodeDetect () {
      return false
    },
    NewOrderData () {
      return {
        Customer: {
          CustomerID: this.SelectedCustomer.Id
        },
        BarCode: this.BarcodeValue,
        AmountTotal: this.$store.state.CookiesMemory.NewOrderAmmount,
        // Area
        Area: {
          AreaID: this.SelectedArea.ID
        },
        Address: this.Address,
        Notes: this.Notes,
        Client: {
          Name: this.Name,
          PhoneNum: this.PhoneNumber,
          Email: this.Email
        },
        OrderDriver: {}
      }
    },
    DialogError () {
      var DialogErrors = []
      // this.Name === '' && DialogErrors.push('Please Enter Order\'s Name')
      this.PhoneNumber === '' && DialogErrors.push('Please Enter Order\'s Name')
      this.$store.state.CookiesMemory.NewOrderCustomer === '' && DialogErrors.push('Please Enter Order\'s Customers')
      this.$store.state.CookiesMemory.NewOrderArea === '' && DialogErrors.push('Please Enter Order\'s Area')
      // !this.PhoneValidator(this.PhoneNumber) && DialogErrors.push('Please Enter Order\'s Name')
      // !this.EmailValidator(this.Email) && DialogErrors.push('Please Enter Order\'s Email')
      return DialogErrors
    },
    isActive () {
      return this.$store.state.CookiesMemory.D_NewOrder_Active
    },
    BarCodeHandler () {
      if (this.BarcodeValue === '') {
        return 'AB123456789012'
      } else {
        return this.BarcodeValue
      }
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .InputPadding{
    padding: 0 !important;
  }
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}
.barcode{
  display: flex;
  justify-content: center;
  width: 15em;
  max-width:100%;
  height:5em;
  background: var(--white);
  border: solid 1px rgb(167, 167, 167);
  border-radius: var(--rad3);
  overflow: hidden;
  filter: drop-shadow(var(--shadow1));
}
.barcodeBlur canvas{
  filter: blur(4px);
}
.UserImage{
  width: 10em;
  height: 10em;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: 5em;
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.UserImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
.ContentHidden{
  overflow: hidden;
  transform: scale(0);
  height: 0;
  opacity: 0;
  /* overflow: hidden; */
}
.InputPadding{
  padding: 0.5em 3em;
}
</style>
