<template>
<div v-if="(($store.state.UserManagment.CurrentUser.UserType.toLowerCase() === 'admin' || (Access('Navigation') && !Access('Driver'))))" class="CookiesMenu D-Flex Shadow0 noprint" :class="$store.state.Printing !== '' && 'PrintingHider'">
  <div class="gradientLine D-Flex"></div>
  <div class="W-100 CookiesLogo Shadow1" style="height: 100px; margin-bottom:50px;">
    <div style="height:80%; width: 80%; background-size: contain; background-position: center; background-repeat: no-repeat; margin: 10%;" :style="'background-image: url(' + DefaultImageHandler($store.state.CookiesConfig.Logo, 'https://cookiesbis.com/assets/Logo.svg') + ');'"></div>
  </div>
  <!-- Items -->
  <template v-for="item in Items" :key="item">
    <router-link v-if="Access(item.Access) && !item.Down" :to="item.Link">
      <div class="MenuItem">
        <div style="min-width:100%" class="D-Flex"><CookiesIcon :IconsSheet="Icons" style="width: 2.5em; height: 2.5em;" class="M-Auto" :ID="item.Icon"></CookiesIcon></div>
        <div style="flex: 1; padding-left: 0.5em;" class="D-flex M-Auto"><span class="M-Auto color3 MenuVerticalText">{{Translate(item.Name)}}</span></div>
      </div>
    </router-link>
  </template>
  <div class="M-Auto SettingDownPusher" style="flex: auto"></div>
  <template v-for="item in Items" :key="item">
    <router-link v-if="Access(item.Access) && item.Down" :to="item.Link">
      <div class="MenuItem SettingsButton">
        <div style="min-width:100%" class="D-Flex"><CookiesIcon :IconsSheet="Icons" style="width: 2.5em; height: 2.5em;" class="M-Auto" :ID="item.Icon"></CookiesIcon></div>
        <div style="flex: 1; padding-left: 0.5em;" class="D-flex M-Auto"><span class="M-Auto color3 MenuVerticalText">{{Translate(item.Name)}}</span></div>
      </div>
    </router-link>
  </template>
</div>
<div :class="$store.state.CookiesConfig.NoCopyRights ? 'D-None' : ''" class="CookiesCopyrights NOPRINT Shadow1" :style="Access('Driver') && 'bottom: 0 !important;'">
  <div class="color3" style="font-size: 0.8em; margin: auto 0em;">
    powered by:
  </div>
  <a class="D-Flex" href="https://cookiesbis.com/" target="_blank">
    <img style="height: 70%; margin: auto 1em;" :src="$store.state.CookiesConfig.Theme === '1' ? require('@/assets/defaults/DarkCookies.svg') : require('@/assets/defaults/MilkCookies.svg')" alt="">
  </a>
</div>
<div @dblclick="FullScreen()" id="CookiesTopNav" class="CookiesTopNav D-Flex color3 noprint" :class="$store.state.Printing !== '' && 'PrintingHider'">
  <div class="M-Auto TopHeaderTitle">{{$store.state.CookiesConfig.Name}}</div>
  <div class="">
    <table class="">
      <tr class="">
        <td rowspan="2"><div style="width:2.5em; height:2.5em; border-radius: 100px; margin-right:0.2em;" :style="'background-image: url('+ DefaultImageHandler($store.state.CookiesConfig.UserImage) + ')'"></div></td>
        <td style="padding: 0.2em 0.5em;">{{$store.state.UserManagment.CurrentUser.Email}}</td>
        <td class="" @click="SignOut" rowspan="2" style="margin: 1em;"><button style="background: var(--colorr1); width: 7em; height: 2.5em;" class="F-Bold CookiesButton">{{LanguageSelector('Sign out', 'تسجيل خروج')}}</button></td>
      </tr>
      <tr>
        <!-- <td>email</td> -->
      </tr>
    </table>
  </div>
</div>
<div style="min-width: 300px; position:relative; z-index:10;">
</div>
</template>

<script>
export default {
  props: {
    Items: { default: [], Type: Array },
    IconsSheet: { default: '' }
  },
  data () {
    return {}
  },
  methods: {
    UserInfo () {
      this.$store.state.UserManagment.Dialog = true
    },
    SignOut () {
      this.OpenDialog('SignOut', this.LanguageSelector('Sign Out', 'تسجيل خروج')) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  computed: {
    CurrentPage () {
      return this.$route.matched[0].name
    },
    Icons () {
      return this.IconsSheet === '' ? require('@/assets/icons/ThemeIcons.svg') : this.IconsSheet
    }
  }
}
</script>

<style scoped>
.CookiesMenu{
  width: 4em;
  height: 100vh;
  position: fixed;
  background: v-bind('$store.getters.Theme.BG3');
  z-index: 500;
  flex-direction: column;
  font-size: 0.9em;
  backdrop-filter: v-bind('$store.getters.Theme.BackBlur');
}
.CookiesCopyrights{
  backdrop-filter: v-bind('$store.getters.Theme.BackBlur');
  justify-content: right;
  display: flex;
  position: absolute;
  width: 100vw;
  height: 2em;
  bottom: 0;
  background: linear-gradient(90deg, #00000000, v-bind('$store.getters.Theme.BG3'));
  font-size: 0.8em;
  z-index: 50;
}
@media (min-width: 700px) and (hover: hover) {
  .CookiesMenu:hover{
    width: 13em;
  }
  .CookiesMenu:hover >>> .MenuVerticalText{
    opacity: 1;
    font-size: 1em;
  }
}

.MenuVerticalText{
  opacity: 0;
  font-size: 0;
  display: inline-block;
}
.gradientLine{
  transition: none;
  height:100%;
  width: 1px;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(#00000000, v-bind('$store.getters.Theme.UserColors.main_50'), #00000000);
}
.MenuItem{
  display: flex;
  width: 4em;
  margin: 1em 0;
  opacity: 0.3;
}
.MenuItem > div > img{
  width: 2.3em;
  margin: auto;
}
.MenuItem:hover {
  filter: drop-shadow(0px 0px 5px v-bind('$store.getters.Theme.Shade3'));
}
.TopHeaderTitle{
  margin-left: 5em;
}
@media (max-width: 700px) , (hover: none) {
  .CookiesMenu {
    width: 100vw;
    height: 4em;
    position: fixed;
    bottom: 0;
    background: v-bind('$store.getters.Theme.BG3');
    z-index: 500;
    flex-direction: row;
    font-size: 2.8vw;
  }
  .CookiesCopyrights{
    font-size: 2.8vw;
  }
  .TopHeaderTitle{
    margin-left: 0em;
  }
  .router-link{
    margin: auto !important;
  }
  .CookiesLogo{
    display: none;
  }
  .CookiesMenu > * {
    margin: auto  !important;
  }
  .SettingDownPusher{
    display: none;
  }
  .gradientLine{
    height:1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #00000000, v-bind('$store.getters.Theme.UserColors.main_50'), #00000000);
  }
  .MenuItem{
    border-left: none !important;
    margin: auto;
  }
  .router-link-active > .MenuItem {
    height: 95%;
  }
  .router-link-active{
    height: 100%;
  }
  .CookiesTopNav{
    font-size: 0.5em;
    height: 22px !important;
  }
  .CookiesCopyrights{
    bottom: 4em !important;
  }
}
@media (min-width: 700px) , (hover: none) {
  .MenuItem{
    border-bottom: none !important;
  }
}
.CookiesTopNav{
  backdrop-filter: v-bind('$store.getters.Theme.BackBlur');
  background: v-bind('$store.getters.Theme.BG3');
  height: 48px;
  padding: 5px;
}
*{
  transition-property: font-size, width, opacity, border, height;
  transition-duration: 0.2s;
}
.LogoSpacer{
  width: 330px;
  opacity: 0;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.7);
  margin: 5px;
  border-radius: var(--rad3);
  text-decoration: none;
  text-align: left;
  justify-content: left;
  display: block;
  margin-left: 0px;
}
#nav a {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.7);
  margin: 5px;
  padding: 20px;
  border-radius: var(--rad3);
  text-decoration: none;
  text-align: left;
  justify-content: left;
}
.router-link-active > .MenuItem {
  text-decoration: none;
  opacity: 1;
  border-left: solid 3px v-bind('$store.getters.Theme.UserColors.fourth');
  border-bottom: solid 3px v-bind('$store.getters.Theme.UserColors.fourth');
}
.router-link-active > .MenuItem > div {
  font-size: 1em;
}
.MenuItem:hover {
  opacity: 1 !important;
}

.CookiesNav {
  height: 40px;
  padding: 16px;
  background:rgba(0, 0, 0, 0.62);
  border-radius: var(--rad2);
  filter: drop-shadow(4px 3px 7px rgba(0,0,0,0.3));
  text-align: center;
  display: flex;
}

.CookiesCustomLogo {
  width: 220px;
  height: 90px;
  background-color: white;
  border-radius: var(--rad2);
  margin-bottom: -80px;
  position: relative;
  z-index: 20;
}
.CookiesCustomLogo > img{
  margin: 4px 13px;
  height: 100%;
}
.CurrentPage{
  display: none;
  width: 80%;
  margin: auto;
}
@media (max-width: 1150px) {
  .CookiesNav{
    margin-top: 60px;
    padding: 13px;
    border-radius: 0px 0px var(--rad2) var(--rad2);
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .CookiesCustomLogo{
    position: fixed;
    top: 0px;
    left:0;
    margin: 0px;
    width: 100%;
    height: 60px;
    border-radius: 0px;
    filter: drop-shadow(var(--shadow1));
    min-width: 300px;
  }
  #nav a {
    color: rgba(255, 255, 255, 0.7);
    padding: 6px;
    border-radius: var(--rad3);
    text-decoration: none;
    text-align: left;
    justify-content: left;
    padding-top: 17px;
    margin: 2vw;
  }
  .NavText, .LogoSpacer, .SignOut, .UserBlock{
    display: none !important;
  }
  .CookiesCustomLogo > img{
    height: 80%;
  }
  .UserBlock{
    position: fixed;
    top: 0;
    right: 0;
    margin-top: -50px;
  }
  .UserBlockMobile{
    display: grid !important;
    grid-template-columns: 40px 120px !important;
    margin-right: 15px;
    font-size: 12px;
    text-align: left;
    color: var(--colorb2) !important;
  }
  .CurrentPage{
    display: flex;
    height: 13px;
    margin-top: -13px;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0) 100%);
    margin-top: 12px;
    margin-bottom: -13px;
    padding: 4px 0px;
  }
}

/* User Block >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
.UserBlock{
  display: grid;
  grid-template-columns: 40px 140px 85px;
  grid-template-rows: 20px;
  font-size: 14px;
  text-align: left;
  margin-right: 5px;
  margin-left: auto;
}
.UserBlockMobile{
  display: none;
  top: 10px;
  right: 10px;
  position: absolute;
  z-index: 50;
  grid-template-columns: 40px 140px 85px;
  grid-template-rows: 20px;
  font-size: 14px;
  text-align: left;
}
.UserBlock > *{
  margin: auto;
}
.UserImage{
  grid-area: 1 / 1 / span 2 / span 1;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  overflow: hidden;
}
.UserName{
  grid-area: 1 / 2 / span 1 / span 1;
  width: 100%;
}
.UserEmail{
  grid-area: 2 / 2 / span 1 / span 1;
}
.UserEmail, .UserName{
  margin: auto 10px;
  overflow: hidden;
  width: 100%;
}
.SignOut{
  grid-area: 1 / 3 / span 2 / span 1;
  margin-left: 15px;
}
.SignOut > button{
  box-shadow: none;
  border-radius: var(--rad3);
  padding: 10px 8px;
  background: var(--colorr1);
  color: var(--colorb1);
}
/* User Block <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */
</style>
