
import CookiesDictionery from '@/Dictionary/CookiesDictionary.csv'
// const https = require('https')
import { defineComponent } from 'vue'

/* global SortDirection,ConfigItem,ObjectPath,LedgerInterface */
export default defineComponent({
  data () {
    return {
      Patcher: require('rfc6902')
    }
  },
  computed: {
    DirectionStyler () {
      if (this.$store.getters.Direction === 'ltr') {
        return {
          first: 'var(--rad3) 0px 0px var(--rad3)',
          last: '0px var(--rad3) var(--rad3) 0px',
          borderLeft: 'solid 0.5px rgba(136, 136, 136, 0.1)',
          borderRight: ''
        }
      } else {
        return {
          first: '0px var(--rad3) var(--rad3) 0px',
          last: 'var(--rad3) 0px 0px var(--rad3)',
          borderLeft: '',
          borderRight: 'solid 0.5px rgba(136, 136, 136, 0.1)'
        }
      }
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    },
    PriceUnit () {
      return this.$store.state.CookiesConfig.PriceUnit
    },
    TableHeaderStyler () {
      return this.$store.state.CookiesConfig.Transparent ? '' : this.$store.getters.Theme.BG2
    },
    DefaultImage () {
      if (this.$store.state.CookiesConfig.Theme === '1') {
        return require('@/assets/defaults/DefaultLight.svg')
      } else {
        return require('@/assets/defaults/DefaultDark.svg')
      }
    },
    ComputedDictionary () :any {
      var ComputedDictionary :any = {}
      CookiesDictionery.forEach((word :any) => {
        ComputedDictionary[word.ENG] = word[this.$store.state.CookiesConfig.Language]
      })
      return ComputedDictionary
    },
    ServerDescription () {
      var Descriptions :any = {
        ARA: 'DescriptionAr',
        ENG: 'Description'
      }
      return Descriptions[this.$store.state.CookiesConfig.Language]
    },
    CurrentBackgroundColor () {
      try {
        var colors = {
          BGGradient: this.$store.getters.Theme.UserColors.Gradient,
          BGThird: this.$store.getters.Theme.UserColors.third,
          BGDimmed: this.$store.getters.Theme.UserColors.Dimmed,
          BG1Fixed: this.$store.getters.Theme.BG1Fixed
        }
        return colors[this.$store.state.CookiesConfig.background]
      } catch (error) {
        return ''
      }
    }
  },
  methods: {
    Get (API :string | Array<string>) :Promise<any> {
      const _this = this
      this.Console('Get', 'font-size: 4em')
      this.Console(API[0])
      return new Promise((resolve, reject) => {
        var CurrentCookie :string
        if (this.AuthorizationVersion === 2 && API[0] !== 'Config') {
          var SessionJWT = this.SessionRead('CookiesJWT')
          var LocalJWT = this.LocalRead('CookiesJWT')
          if (LocalJWT !== null && new Date().getTime() < LocalJWT.exp) {
            CurrentCookie = LocalJWT.value
            BeginGet()
          } else if (SessionJWT !== null && new Date().getTime() < SessionJWT.exp) {
            CurrentCookie = SessionJWT.value
            BeginGet()
          } else {
            this.RefreshToken().then(response => {
              if (response !== undefined) {
                CurrentCookie = response
                BeginGet()
              } else {
                this.$store.state.UserManagment.Status = false
                reject(new Error('Undefined Response'))
              }
            }, error => {
              this.$store.state.UserManagment.Status = false
              reject(error)
            })
          }
        } else {
          this.Console('Get Normally')
          BeginGet()
        }
        function BeginGet () {
          var RequestLink = ''
          if (typeof API === 'string') {
            RequestLink = API
          } else {
            var AddQuery = _this.DeepFetcher(API, [1])
            if (AddQuery === undefined) {
              AddQuery = ''
            }
            RequestLink = _this.DeepFetcher(_this.API_MAPPER, _this.DeepFetcher(API, [0])) + AddQuery
          }
          var AvailableOnTesting = false
          try {
            if (_this.DeepFetcher(_this.TestingData, RequestLink.split('?')[0]) !== undefined) {
              AvailableOnTesting = true
            }
          } catch (error) {
          }
          // testing mode /////////////////////////////////////////////////////////////////
          if (_this.Testing && _this.TestingData !== undefined && AvailableOnTesting) {
            try {
              setTimeout(() => {
                var ALLDATA = _this.DeepFetcher(_this.TestingData, RequestLink.split('?')[0])
                resolve(ALLDATA)
              }, 500 + 1000 * Math.random())
            } catch (error) {
              reject(error)
            }
          // Normal mode /////////////////////////////////////////////////////////////////
          } else {
            const RequestOptions: any = {
              method: 'GET',
              credentials: 'include',
              headers: {
                Authorization: 'Bearer ' + CurrentCookie
              }
            }
            _this.Console('Get: ' + _this.API_URL + RequestLink, 'color: greenyellow; font-size: 1.2em; font-weight: 800; padding:2em 0em')
            fetch(_this.API_URL + RequestLink, RequestOptions)
              .then(CookiesRES => {
                if (CookiesRES.ok) {
                  _this.Console(CookiesRES, 'color: greenyellow;')
                  CookiesRES.json()
                    .then(CookiesData => {
                      _this.Console(CookiesData, 'color: greenyellow;')
                      if (CookiesData.Status) { // API response
                        if (CookiesData.Data === null) {
                          resolve([])
                        } else {
                          resolve(CookiesData.Data)
                        }
                      } else {
                        reject(CookiesData)
                      }
                    })
                } else {
                  reject(CookiesRES)
                }
              })
              .catch(error => reject(error))
          }
        }
      })
    },
    Post (Method :string, API :string | Array<string>, Data :any) {
      const _this = this
      this.Console('Post', 'font-size: 4em')
      this.Console(API[0])
      return new Promise((resolve, reject) => {
        var CurrentCookie :string
        if (this.AuthorizationVersion === 2 && API[0] !== 'Login') {
          var SessionJWT = this.SessionRead('CookiesJWT')
          var LocalJWT = this.LocalRead('CookiesJWT')
          if (LocalJWT !== null && (new Date().getTime() < LocalJWT.exp || API[0] === 'Refresh')) {
            CurrentCookie = LocalJWT.value
            BeginPost()
          } else if (SessionJWT !== null && (new Date().getTime() < SessionJWT.exp || API[0] === 'Refresh')) {
            CurrentCookie = SessionJWT.value
            BeginPost()
          } else if (API[0] === 'Refresh') {
            BeginPost()
          } else {
            this.RefreshToken().then(response => {
              CurrentCookie = response
              BeginPost()
            }, error => {
              this.$store.state.UserManagment.Status = false
              reject(error)
            })
          }
        } else {
          this.Console('Post Normally')
          BeginPost()
        }
        function BeginPost () {
          var RequestLink = ''
          _this.Console(API)
          if (typeof API === 'string') {
            RequestLink = API
          } else {
            var AddQuery = _this.DeepFetcher(API, [1])
            if (AddQuery === undefined) {
              AddQuery = ''
            }
            RequestLink = _this.DeepFetcher(_this.API_MAPPER, _this.DeepFetcher(API, [0])) + AddQuery
          }
          _this.Console(RequestLink)
          var TheBody = []
          TheBody = Data
          // //////////
          const RequestOptions: any = {
            method: Method,
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': 'true',
              'Access-Control-Allow-Origin': '*',
              Authorization: 'Bearer ' + CurrentCookie
            },
            credentials: 'include',
            body: JSON.stringify(TheBody)
            // agent: httpsAgent
          }
          _this.Console('ffffffffffffffffffffffetch', 'color: orange;')
          _this.Console(Method + ': ' + _this.API_URL + RequestLink, 'color: orange !important; font-size: 1.2em; font-weight: 800; padding:2em 0em')
          _this.Console(TheBody, 'color: orange;')
          _this.Console(RequestOptions.body, 'color: orange;')
          fetch(_this.API_URL + RequestLink, RequestOptions)
            .then(CookiesResponse => {
              _this.Console('CookiesResponse')
              _this.Console(CookiesResponse)
              if (CookiesResponse.ok) {
                CookiesResponse.json()
                  .then(CookiesData => {
                    _this.Console(CookiesData)
                    if (CookiesData.accessToken !== undefined) {
                      resolve(CookiesData)
                    }
                    if (CookiesData === true) {
                      resolve(CookiesData)
                    }
                    if (CookiesData.Status) {
                      resolve(CookiesData.Data)
                    } else {
                      reject(CookiesData)
                    }
                  })
              } else {
                reject(CookiesResponse)
              }
            })
            .catch(error => reject(error))
        }
      })
    },
    RefreshToken () :Promise<string> {
      this.Console('Refreshing Token', 'color: orange, font-size: 2em;')
      var CurrentBAKERY :string
      var RememberMe :boolean
      var SessionBAKERY = this.SessionRead('CookiesBAKERY')
      var LocalBAKERY = this.LocalRead('CookiesBAKERY')
      if (LocalBAKERY !== null) { // -------------------- Remember me Refresher found
        CurrentBAKERY = LocalBAKERY.value
        RememberMe = true
      } else if (SessionBAKERY !== null) { // ------------ Dont Remember me Refresher found
        RememberMe = false
        CurrentBAKERY = SessionBAKERY.value
      } else { // --------------------------------------- No Refresher found
        return new Promise<string>((resolve, reject) => { reject(new Error('Singed Out')) })
      }
      return new Promise<string>((resolve, reject) => {
        this.Post('POST', ['Refresh'], { RefreshToken: CurrentBAKERY }).then(response => {
          var ExpirationTime = new Date().getTime() + (this.BakingMinitues * 60 * 1000)
          if (RememberMe) {
            this.LocalSave('CookiesJWT', { value: this.DeepFetcher(response, 'accessToken'), exp: ExpirationTime })
          } else {
            this.SessionSave('CookiesJWT', { value: this.DeepFetcher(response, 'accessToken'), exp: ExpirationTime })
          }
          if (resolve(this.DeepFetcher(response, 'accessToken')) !== undefined) {
            resolve(this.DeepFetcher(response, 'accessToken'))
          } else {
            reject(new Error('Undefined Response'))
          }
        }, error => {
          reject(error)
        })
      })
    },
    PostImage (File: any) {
      return new Promise((resolve, reject) => {
        const RequestData = new FormData()
        RequestData.append('file', File)
        RequestData.append('upload_preset', 'u7zjrai6')
        const RequestOptions = {
          method: 'POST',
          body: RequestData
        }
        fetch('https://api.cloudinary.com/v1_1/abudari-com/image/upload', RequestOptions)
          .then(CookiesResponse => {
            if (CookiesResponse.ok) {
              CookiesResponse.json()
                .then(CookiesData => {
                  resolve(CookiesData)
                })
            } else {
              reject(CookiesResponse)
            }
          })
          .catch(error => reject(error))
      })
    },
    ReadConfig () {
      this.Get(['Config']).then(response => {
        response.forEach((element :ConfigItem) => {
          this.$store.state.CookiesConfig[element.Name] = element.value
          this.$store.state.CookiesConfig[element.Name + 'ID'] = element.ID
        })
      }, error => {
        error.CookiesError = 'Error in reading Config'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Translate (Word :string) {
      try {
        if (this.ComputedDictionary[Word] === undefined) {
          var Words = Word.split(' ')
          var computedword = ''
          Words.forEach(word => {
            if (this.ComputedDictionary[word] === undefined) {
              if (this.$store.getters.Direction === 'ltr') {
                computedword += ' ' + word
              } else {
                computedword = word + ' ' + computedword
              }
            } else {
              if (this.$store.getters.Direction === 'ltr') {
                computedword += ' ' + this.ComputedDictionary[word]
              } else {
                computedword = this.ComputedDictionary[word] + ' ' + computedword
              }
            }
          })
          return computedword
        } else {
          return this.ComputedDictionary[Word]
        }
      } catch (error) {
        return Word
      }
    },
    OpenDialog (Dialog :string, Name :string, Data :any, Options = {}) {
      interface DialogObjInterface {
        Content?: any,
        Name?: string,
        Options?: any,
        Data?: any,
        [index: string]: any
      }
      var DialogObj :DialogObjInterface = {}
      DialogObj.Content = Dialog
      DialogObj.Name = Name
      DialogObj.Data = Data
      if (Options === undefined || Options === null) {
        DialogObj.Options = {}
      } else {
        DialogObj.Options = Options
      }
      if (DialogObj.Content === 'ErrorDialog') {
        this.Console(DialogObj)
        if (this.Errors) {
          this.$store.state.CookiesDialog.ErrorDialogs.push(DialogObj)
          this.Console(this.$store.state.CookiesDialog.ErrorDialogs)
        }
      } else {
        DialogObj.Num = this.$store.state.CookiesDialog.DialogsCount
        this.$store.state.CookiesDialog.OpenedDialogs.push(DialogObj)
        // const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute._value.query))
        const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute.value.query))
        CurrentQuery['Dialog' + this.$store.state.CookiesDialog.DialogsCount] = this.$store.state.CookiesDialog.DialogsCount
        this.$router.push({ query: CurrentQuery })
        this.$store.state.CookiesDialog.DialogsCount += 1
      }
    },
    CloseDialog (DialogIndex :number) {
      const Dialogs = this.$store.state.CookiesDialog.OpenedDialogs
      Dialogs.forEach((element, index) => {
        if (element.Num.toString() === DialogIndex.toString()) {
          this.$store.state.CookiesDialog.OpenedDialogs.splice(index, 1)
        }
      })
      // const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute._value.query))
      const CurrentQuery = JSON.parse(JSON.stringify(this.$router.currentRoute.value.query))
      const DialogsNums = this.$store.state.CookiesDialog.OpenedDialogs.map(function (item) {
        return item.Num
      })
      Object.keys(CurrentQuery).some(function (attr) {
        if (DialogsNums.find(element => element.toString() === CurrentQuery[attr].toString()) === undefined) {
          delete CurrentQuery[attr]
        }
      })
      this.$router.push({ query: CurrentQuery })
    },
    CloseErrorDialog (DialogIndex :any) {
      this.$store.state.CookiesDialog.ErrorDialogs.splice(DialogIndex, 1)
    },
    DecimalFixer (value :any, Decimals = 2) {
      try {
        return +parseFloat(value).toFixed(Decimals)
      } catch (error) {
        return 'N/A'
      }
    },
    CookiesSearcher (obj :any, search :string) :boolean {
      const _this = this
      return Object.keys(obj).some(function (attr) {
        if (((Array.isArray(obj[attr])) || (typeof obj[attr] === 'object')) && (obj[attr] !== null)) {
          return _this.CookiesSearcher(obj[attr], search)
        }
        if (obj[attr] !== null && obj[attr] !== undefined) {
          return obj[attr].toString().toLowerCase().includes(search.trim().toLowerCase())
        }
        return false
      })
    },
    DeepFetcher (object :any, path :string | Array<string | number>) {
      var value = object
      try {
        this.PathFixer(path).forEach(element => {
          value = value[element]
        })
        return value
      } catch (error) {
        return ''
      }
    },
    JsonDateParser (JsonDate :any) {
      var DateToParse = new Date(JsonDate)
      return DateToParse.toLocaleDateString(['ban', 'id'], { year: 'numeric', month: 'numeric', day: 'numeric' })
    },
    JsonTimeParser (JsonDate :any) {
      var options :any = { timeStyle: 'short' }
      var DateToParse = new Date(JsonDate)
      return DateToParse.toLocaleTimeString('en-US', options)
    },
    AttributeArray (array :Array<any>, attribute: ObjectPath) {
      const _this = this
      var names = array.map(function (item :any) {
        return _this.DeepFetcher(item, attribute)
      })
      return names
    },
    DefaultImageHandler (ImageURL :string, Default :string) {
      return ((ImageURL !== '') && (ImageURL !== undefined) && (ImageURL !== null)) ? ImageURL : Default
    },
    EmailValidator (email :string) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    PhoneValidator (Phone :string) {
      // const re = /(07)\w{8}$/
      const re = new RegExp('^[0-9+]{1,15}$') // ---- any number with a plus
      return re.test(Phone)
    },
    PasswordValidator (password :string, string = 'check') {
      // const passRegx = new RegExp('.*') // Anything on earth
      const passRegx = new RegExp('^[A-Za-z\\d@$!%*?&^#~]{5,25}$') // Any 5-25 characters
      const PassDescription = 'Password must be (5-25) Characters long'
      // const passRegx = new RegExp('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d@$!%*?&^#~]{5,25}$') // All CHaracters----- 5-25 characters ---- min: 1 letter ---- min: 1 number
      // const PassDescription = 'Password must be (5-25) Characters long & must contain at least one letter and one number'
      // const passRegx = new RegExp('^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*?&^#~])[A-Za-z\\d@$!%*?&^#~]{5,25}$') // All CHaracters----- 5-25 characters ---- min: 1 letter ---- min: 1 number
      // const PassDescription = 'Password must be (5-25) Characters long & must contain at least one letter and one number and one special character'
      if (string.toLowerCase() === 'check') {
        return passRegx.test(password)
      } else if (string.toLowerCase() === 'description') {
        return PassDescription
      }
    },
    OpenWhatsapp (number :string) {
      var Link = 'https://api.whatsapp.com/send?phone=962'
      window.open(Link + number.toString().substring(1), 'popUpWindow')
    },
    LanguageSelector (ENG :any, ARA :any) { // xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx Deprecated
      if (this.$store.state.CookiesConfig.Language === 'ENG') {
        return ENG
      } else if (this.$store.state.CookiesConfig.Language === 'ARA') {
        return ARA
      }
    },
    OnlyNumbersKeyPress () {
      const Myevent: any = event
      this.Console(44444444444444)
      this.Console(String.fromCharCode(Myevent.which))
      if (String.fromCharCode(Myevent.which) === '.' && (Myevent.target.innerText.indexOf('.') > -1)) {
        Myevent.preventDefault()
      // before TypeScript
      } else if (isNaN(Number(String.fromCharCode(Myevent.which))) && String.fromCharCode(Myevent.which) !== '.') {
      // } else if (String.fromCharCode(Myevent.which) !== '.') {
        Myevent.preventDefault()
      }
    },
    PrintIT (element :Document, size = [21, 29.7]) {
      this.$store.state.PrintingSize = size
      this.$store.state.Printing = element
    },
    DeepChanger (object :any, path :ObjectPath, newVal :any) {
      var x = object
      for (var i = 0; i <= path.length - 1; i += 1) {
        if (i === path.length - 1) {
          x[path[i]] = newVal
        } else {
          if (x[path[i]] === undefined) {
            if (typeof path[i + 1] !== 'number') {
              x[path[i]] = {}
            } else {
              x[path[i]] = []
            }
          }
          x = x[path[i]]
        }
      }
    },
    CookiesPatcher (OldObj :any, NewObj :any) {
      return this.Patcher.createPatch(OldObj, NewObj)
    },
    ObjectFinder (array :Array<any>, path :ObjectPath, value :any) {
      try {
        const found = array.find(element => this.DeepFetcher(element, path).toString() === value.toString())
        return found
      } catch (error) {
        return null
      }
    },
    IndexFinder (array :Array<any>, path :ObjectPath, value :any) {
      var found = array.findIndex(element => this.DeepFetcher(element, path) && (this.DeepFetcher(element, path).toString() === value.toString()))
      return found
    },
    DeepCloner (Obj :any) {
      return JSON.parse(JSON.stringify(Obj))
    },
    PlaySound (SoundName :any) {
      // console.log(SoundName)
    },
    Access (AccessFeature :string) {
      try {
        if (this.$store.state.UserManagment.CurrentUser.Email.toLowerCase() === 'system') {
          return false
        }
        if (this.$store.state.UserManagment.CurrentUser.UserType.toLowerCase() === 'admin') {
          return true
        }
        this.Console('AccessFeature, this.$store.state.UserManagment.CurrentUser.AccessList[AccessFeature]')
        this.Console(AccessFeature, this.$store.state.UserManagment.CurrentUser.AccessList[AccessFeature])
        return this.$store.state.UserManagment.CurrentUser.AccessList[AccessFeature]
      } catch (error) {
      }
    },
    StatusMapper (value :any, string = 'name', status :any = 'Default') {
      if (status === 'Default') {
        status = this.States
      }
      try {
        if (string.toLowerCase() === 'list') {
          var list :any = []
          Object.keys(status).forEach(function (item) {
            item !== 'Edited' && list.push(item)
          })
          return list
        }
        if (string.toLowerCase() === 'objlist') {
          var Objlist :any = []
          Object.keys(status).forEach(function (item) {
            item !== 'Edited' && Objlist.push(status[item])
          })
          return Objlist
        }
        if (string.toLowerCase() === 'value') {
          try {
            return status[value].value
          } catch (error) {
            return value
          }
        }
        if (string.toLowerCase() === 'name') {
          try {
            return status[value][this.LanguageSelector('ENG', 'ARA')]
          } catch (error) {
            return value
          }
        }
      } catch (error) {
        this.Console(error, 'color: red')
      }
    },
    DateAPIURL () {
      var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      return 'From=' + new Date(new Date(this.$store.state.CurrentDate.From).setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString() + '&To=' + new Date(new Date(this.$store.state.CurrentDate.To).setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString()
    },
    CreditMapper (type :number, value = null) {
      if (value === null) {
        if (type === 1) {
          return 'Credit'
        } else {
          return 'Debit'
        }
      } else {
        return value
      }
    },
    Console (value :any, style = '') {
      if (!this.ConsoleLogs) {
        return
      }
      var ErrorStack = new Error()
      if (ErrorStack.stack !== undefined) {
        console.log('%c%s', 'font-size: 0.7em; color: #555555', ErrorStack.stack.split('\n')[2])
      }
      if (style === undefined) {
        style = ''
      }
      this.ConsoleLogs && console.log('%c%s', style, value)
      if (typeof value !== 'string' && value !== undefined) {
        console.log(value)
      }
      // this.$store.dispatch('Console', { value: value, style: style, target: EventTarget })
    },
    LocalSave (StorageName :string, value :any) {
      window.localStorage.setItem(StorageName, JSON.stringify(value))
    },
    LocalRead (StorageName :string) :any {
      var SavedItem = window.localStorage.getItem(StorageName)
      if (SavedItem === null) {
        return null
      }
      return JSON.parse(SavedItem)
    },
    SessionSave (StorageName :string, value :any) {
      window.sessionStorage.setItem(StorageName, JSON.stringify(value))
    },
    SessionRead (StorageName :string) :any {
      var SavedItem = window.sessionStorage.getItem(StorageName)
      if (SavedItem === null) {
        return null
      }
      return JSON.parse(SavedItem)
    },
    MEMRead (path :ObjectPath) {
      if (typeof path === 'string') {
        path = [path]
      }
      return this.DeepFetcher(this.$store.state.CookiesMemory, path)
    },
    MEMWrite (path :ObjectPath, newValue :any) {
      if (typeof path === 'string') {
        path = [path]
      }
      return this.DeepChanger(this.$store.state.CookiesMemory, path, newValue)
    },
    TimeBeforeMounted () {
      var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      var DateFrom = new Date(new Date().setHours(0, 0, 0, 0) - TimeZoneDiff - (86400000 * 6)).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the beggining of today
      var DateTo = new Date(new Date().setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the end of today
      this.$store.state.CurrentDate.From = DateFrom
      this.$store.state.CurrentDate.To = DateTo
      return {
        DateFrom: DateFrom,
        DateTo: DateTo
      }
    },
    DateChanged (DateFrom :any, DateTo :any) {
      var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
      this.$store.state.CurrentDate.From = new Date(new Date(DateFrom).setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the beggining of today
      this.$store.state.CurrentDate.To = new Date(new Date(DateTo).setHours(23, 59, 59, 999) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-') // grapping the end of today
    },
    LedgerFinder (AllLedgers :Array<LedgerInterface>, LedgerType :string, Type :number) {
      var Ledger: LedgerInterface = {
        Credited: 0,
        Debited: 0
      }
      try {
        if (AllLedgers === null) { return }
        if (this.ObjectFinder(AllLedgers, ['Type'], LedgerType) !== undefined) {
          Ledger = this.ObjectFinder(AllLedgers, ['Type'], LedgerType)
          if (Type === 1) {
            Ledger.NetBalance = Ledger.Debited - Ledger.Credited
          } else {
            Ledger.NetBalance = Ledger.Credited - Ledger.Debited
          }
        }
      } catch (error) {
      }
      return Ledger
    },
    TotalOf (CookiesData :Array<any>, Path :ObjectPath) {
      try {
        if (typeof Path === 'string') {
          Path = [Path]
        }
        var Total = 0
        CookiesData.forEach(element => {
          Total += parseFloat(this.DeepFetcher(element, Path))
        })
        // const Total = CookiesData.reduce(function (Sum, element) {
        //   return Sum + parseFloat(this.DeepFetcher(element, Path))
        // })
        return Total
      } catch (error) {
      }
    },
    FullScreen () {
      interface ModifiedDocument extends Document {
        mozFullScreenElement?: Element,
        msFullscreenElement?: Element,
        webkitFullscreenElement?: Element,
        msExitFullscreen?: () => void,
        mozCancelFullScreen?: () => void,
        webkitExitFullscreen?: () => void,
      }
      var MyDocument: ModifiedDocument = document
      var MainApp: any = MyDocument.getElementById('CookiesApp')
      if (MyDocument.fullscreenElement === null) {
        if (MainApp.requestFullscreen) {
          MainApp.requestFullscreen()
        } else if (MainApp.webkitRequestFullscreen) { /* Safari */
          MainApp.webkitRequestFullscreen()
        } else if (MainApp.msRequestFullscreen) { /* IE11 */
          MainApp.msRequestFullscreen()
        }
      } else {
        MyDocument.exitFullscreen()
        try {
          if (MyDocument.exitFullscreen) {
            MyDocument.exitFullscreen()
          } else if (MyDocument.webkitExitFullscreen) { /* Safari */
            MyDocument.webkitExitFullscreen()
          } else if (MyDocument.msExitFullscreen) { /* IE11 */
            MyDocument.msExitFullscreen()
          }
        } catch (error) {
        }
      }
    },
    DayNumber (theDate :any) {
      return (new Date(theDate)).getTime() / (1000 * 60 * 60 * 24)
    },
    MinMaxCalculator (Units :Array<any>, Path :ObjectPath) {
      var min = this.DeepFetcher(Units[0], Path)
      var max = this.DeepFetcher(Units[0], Path)
      Units.forEach(unit => {
        var value = this.DeepFetcher(unit, Path)
        if (this.DeepFetcher(unit, Path) < min) min = value
        if (this.DeepFetcher(unit, Path) > max) max = value
      })
      if (max === min) {
        return min
      } else {
        return max + ' - ' + min
      }
    },
    /** fixes old functions with one path elements (string) and return that string in a singular array --- ie: 'path' > ['path'] */
    PathFixer (attribute :string | Array<string | number>) :Array<string | number> {
      if (typeof attribute === 'string') {
        return [attribute]
      } else {
        return attribute
      }
    },
    Requirments (_this :any) {
      try {
        if (_this.$el.nextSibling.querySelector('[CookiesInvalidInput = true]') === null) {
          return { Incomplete: false }
        } else {
          return { Incomplete: true }
        }
      } catch (error) {
        return { Incomplete: true }
      }
    },
    GeneralSort (MyData: Array<any>, Path: string | Array<string>, Direction :SortDirection) {
      const _this = this
      function BeginSorting (a :any, b :any) {
        var SortElement = Path
        var SortDirection = Direction
        if (SortDirection === undefined) { return 0 }
        try {
          if (_this.DeepFetcher(a, SortElement) > _this.DeepFetcher(b, SortElement)) {
            return SortDirection
          }
          if (_this.DeepFetcher(a, SortElement) < _this.DeepFetcher(b, SortElement)) {
            return SortDirection * -1
          }
          return 0
        } catch (error) {
          return 0
        }
      }
      var x = MyData.sort(BeginSorting)
      return x
    },
    SortTable (name :Array<string>, element :string) {
      if (element !== undefined && element !== null) {
        var _this :any = this
        if (JSON.stringify(_this[element].name) === JSON.stringify(name)) {
          _this[element].value = _this[element].value * -1
        } else {
          _this[element].name = name
        }
      }
    },
    ChangePassword (email: string) {
      this.OpenDialog('ChangePassDialog', this.Translate('Change Password'), { Email: email }) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CompareDates (Date1: string, Date2: string, Hours = false) {
      var DATE1Decoded
      var DATE2Decoded
      if (!Hours) {
        DATE1Decoded = new Date(new Date(Date1).setHours(0, 0, 0, 0)).getTime()
        DATE2Decoded = new Date(new Date(Date2).setHours(0, 0, 0, 0)).getTime()
      } else {
        DATE1Decoded = (new Date(Date1)).getTime()
        DATE2Decoded = (new Date(Date2)).getTime()
      }
      if (DATE1Decoded > DATE2Decoded) {
        return 1
      }
      if (DATE1Decoded < DATE2Decoded) {
        return 2
      }
      if (DATE1Decoded === DATE2Decoded) {
        return 0
      }
    },
    LogOut () {
      switch (this.AuthorizationVersion) {
        case 2:
          this.Post('POST', ['Logout'], '').then(response => {
            this.LocalSave('CookiesJWT', null)
            this.LocalSave('CookiesBAKERY', null)
            this.SessionSave('CookiesJWT', null)
            this.SessionSave('CookiesBAKERY', null)
            location.reload()
          }, error => {
            this.LocalSave('CookiesJWT', null)
            this.LocalSave('CookiesBAKERY', null)
            this.SessionSave('CookiesJWT', null)
            this.SessionSave('CookiesBAKERY', null)
            error.CookiesError = 'Error in Signing Out'
            this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
          break
        case 1:
          this.Post('POST', ['Logout'], '').then(response => {
            location.reload()
          }, error => {
            error.CookiesError = 'Error in Signing Out'
            this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
          break
      }
    },
    ArrayHandler (data :Array<string> | string) {
      if (Array.isArray(data)) {
        return data
      } else {
        return []
      }
    }
  }
})
