<template>
<div class="CookiesRow W-75" style="margin-top: 4em; font-size: 1.1em;">
  <!-- User Image -------------------------- -->
  <div class="CookiesRow">
    <div class="UserImage" :style="'background-image: url(' + DefaultImageHandler($store.state.UserManagment.CurrentUser.ImgURL, $store.state.CookiesConfig.UserImage) + ');'"></div>
  </div>
  <!-- UserName -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <div class="M-Auto F1_5em F-Bold">{{$store.state.UserManagment.CurrentUser.UserName}}</div>
  <!-- PhoneNumber -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <div class="M-Auto">{{$store.state.UserManagment.CurrentUser.PhoneNumber}}</div>
  <!-- Email -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <div class="M-Auto">{{$store.state.UserManagment.CurrentUser.Email}}</div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Account Settings',
  data () {
    return {}
  },
  mounted () {},
  computed: {},
  methods: {}
}
</script>

<style>
.UserImage{
  width: 10em;
  height: 10em;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: 5em;
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
</style>
