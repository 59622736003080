<template>
<th :class="Sortable && 'Pointer'" class="P-Rel" style="user-select: none; z-index: 20;">{{Name}}
  <div :style="(Sortable & JSON.stringify(sortColumn.name) === JSON.stringify(Value)) ? 'transform: scale(1);': 'transform: scale(0);'" class="CookiesSortingTHArrow NOPRINT">
    <div class="Transition0_5 color3 W-100" :style="sortColumn.value === 1 ? {transform: 'scaleY(-1)'} : sortColumn.value === -1 ? {transform: 'scaleY(1)'} : ''"><CookiesIcon style="width: 0.8em; height: 0.8em; padding-bottom: 0.6em;" :ID="'SortingArrow'"></CookiesIcon></div>
  </div>
</th>
</template>

<script>
export default {
  props: {
    Name: { default: '', Type: String },
    Value: { default: ['Date'], Type: Array },
    sortColumn: { default: { name: ['Date'], value: -1 }, Type: Object },
    Sortable: { default: true, Type: Boolean }
  }
}
</script>

<style>
.CookiesSortingTHArrow{
  /* margin-left: 0.3em;
  margin-right: 0.3em; */
  width:0em;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  transition: transform 0.2s ease;
}
.CookiesSortingTHArrow > div{
  padding-left: 0.3em;
  padding-right: 0.3em;
}
</style>
