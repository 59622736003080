<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Car -------------------------------------- -->
      <div class="CookiesRow">
        <input v-model="Name" :class=" (ErrorsVisibilty & !Name) && 'ErrorBorder' " class="CookiesInput color2" type="Text" name="Phone" placeholder="Type" autocomplete="off">
        <input v-model="PlateNum" :class=" (ErrorsVisibilty & !PlateNum) && 'ErrorBorder' " class="CookiesInput color2" type="Text" name="Phone" placeholder="Plate Num" autocomplete="off">
      </div>
    </div>
  </div>
</div>
<div class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$store.commit('CloseDialog', DialogIndex)" style="background: var(--colorr1); " class="F-Bold CookiesButton DialogFooterButton">Cancel</button>
    <button @click="SaveCar()" :style="'background: ' + $store.getters.Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">Save</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  created () {},
  data () {
    return {
      PlateNum: '',
      Email: '',
      Name: '',
      Car_Extra_Info: '',
      Company: '',
      ErrorsVisibilty: false,
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    SaveCar () {
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      this.Post('PATCH', 'Cars?ID=' + this.DialogData.ID, this.EditCarData).then(response => {
        this.Saving = false
        this.CloseDialog(this.DialogIndex)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Editing Car'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ChangePassword () {
      this.OpenDialog('ChangePassDialog', 'Change Password', this.DialogData) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    AttributeArray (array, attribute) {
      var names = array.map(function (item) {
        return item[attribute]
      })
      return names
    }
  },
  mounted () {
    console.log(this.DialogData)
    this.PlateNum = this.DialogData.PlateNum
    // this.Status = this.DialogData.Status
    this.Name = this.DialogData.Name
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    EditCarData () {
      return {
        PlateNum: this.PlateNum,
        Name: this.Name
      }
    },
    DialogError () {
      var DialogErrors = []
      this.PlateNum === '' && DialogErrors.push('Please Enter Car\'s Name')
      this.Name === '' && DialogErrors.push('Please Enter Car\'s Phone')
      return DialogErrors
    },
    isActive () {
      return this.$store.state.CookiesMemory.D_EditCar_Active
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 10px 25px;
  outline: none;
  font-size: 17px;
  margin: 6px;
  position: relative;
}

.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}
</style>
