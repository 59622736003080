<template>
<input v-model="$store.state.CookiesMemory[Res]" :class=" (ErrorsVisibilty & !Reseller_Phone) && 'ErrorBorder' " class="CookiesInput" type="text" name="Product Name" placeholder="Product Name" autocomplete="off">
</template>

<script>
export default {
  props: {
    Default: { default: '', type: String },
    Width: { default: '50px', type: String },
    Height: { default: '25px', type: String },
    Background: { default: 'var(--colorw2)', type: String },
    Options: { default: [] },
    Res: { type: String },
    Radio: { default: false, type: Boolean },
    Disabled: { default: false, type: Boolean }
  },
  data () {
    return {
      Opened: false
    }
  },
  mounted () {
    this.$store.state.CookiesMemory[this.Res] = this.Default
  },
  methods: {
    OptionSelected (option) {
      this.$store.state.CookiesMemory[this.Res] = option
      this.$emit('slection_changed')
    }
  },
  computed: {
    ALLOptions () {
      if (this.Radio) {
        return this.Options
      }
      return ['All'].concat(this.Options)
    },
    StyledOut () {
      return {
        width: this.Width,
        height: this.Height,
        background: this.Background
      }
    },
    Selected () {
      return this.$store.state.CookiesMemory[this.Res]
    }
  }
}
</script>

<style scoped>
</style>
