<template>
<div v-if="Access('FinancialRead')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <div class="M-Auto DialogContainer">
    <div class="DialogBody color2">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Customer -------------------------------------- -->
      <!-- <div class="D-Flex W-100 FlexWrap">
        <div class="DefaultTextAlign W-25" style="padding: 3em; flex: 1;">
          <div class="W-100">
            <span class="color2">{{LanguageSelector('Customer: ', 'العميل: ')}}</span>
          </div>
          <div class="W-100">
            <span class="F1_25em F-Bold color2">{{DeepFetcher(TheUser, ['Company'])}}</span>
          </div>
          <div class="W-100">
            <span class="F1_25em F-Bold color2">{{DeepFetcher(TheUser, ['PhoneNumber'])}}</span>
          </div>
        </div>
      </div> -->
      <!-- Data Block >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> -->
      <div class="W-100" style="padding:5px">
        <div class="CookiesRowSplitter"/>
        <div class="W-100 F-Bold">
          <span>{{LanguageSelector('Account History', 'تاريخ الحساب')}}</span>
        </div>
        <div class="M-Auto P-Rel" style="max-height:400px; overflow-y:scroll;">
        <div v-if="Loading.length > 0" class="W-100 H-100 P-Abs Inset0"><div class="M-Auto"><CookiesLoading></CookiesLoading></div></div>
          <table class="Cookies_Table1 Cookies_TableButtons" style="position: relative; border-radius:var(--rad2); min-width: 75%; margin: auto;"  cellspacing="0" cellpadding="0">
            <tr class="Disabled" v-if="!CookiesDataBalanceHis.length"><td colspan="10">{{LanguageSelector('No Entries','لا يوجد')}}</td></tr>
            <tr @click="OpenOlderBalance(entry)" v-for="entry in GeneralSort(CookiesDataBalanceHis, ['CloseDate'], -1)" :key="entry">
              <td><span>{{JsonDateParser(entry.OpenDate) + ' - '}}</span><strong>{{JsonTimeParser(entry.OpenDate)}}</strong></td>
              <td><strong>-</strong></td>
              <td><span>{{JsonDateParser(entry.CloseDate) + ' - '}}</span><strong>{{JsonTimeParser(entry.CloseDate)}}</strong></td>
              <td class="Arrow"><CookiesUpDownArrow class="F_75em O-75" :Direction="'right'" :Value="1"></CookiesUpDownArrow></td>
            </tr>
          </table>
        </div>
        <div class="CookiesRowSpacer"/>
      </div>
    </div>
  </div>
</div>
<div v-if="Access('FinancialRead')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{LanguageSelector('Close', 'خروج')}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  beforeCreate () {},
  created () {},
  data () {
    return {
      CookiesDataBalanceHis: [],
      CookiesDataLedgerOrders: [],
      TheUser: {},
      TheUserAccount: {},
      Loading: [],
      OrdersLoading: true
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    ReadBalances () {
      this.Loading.push('Loading')
      this.Get('Financial/GetBalanceHis?ID=' + this.TheUser.Id).then(response => {
        this.CookiesDataBalanceHis = response
        this.Loading.pop()
      }, error => {
        error.CookiesError = 'Error in reading Balances'
        this.Loading.pop()
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    OpenOlderBalance (Account) {
      var CashLedger
      if (this.DialogData.BalanceType === 'Driver') {
        this.Console(Account, 'background: blue;')
        CashLedger = this.LedgerFinder(Account.ledgerHis, this.LedgersMapper.D_Cash, 1)
        this.Console(CashLedger, 'background: blue;')
        if (CashLedger === null || CashLedger === undefined) {
          return
        }
        Account.LedgerID = CashLedger.ID
        Account.UserName = this.TheUser.Employee.Name
        Account.AccountID = this.TheUser.DriverBalance.ID
        Account.UserType = 'Driver'
      } else if (this.DialogData.BalanceType === 'Customer') {
        this.Console(Account, 'background: blue;')
        CashLedger = this.LedgerFinder(Account.ledgerHis, this.LedgersMapper.C_Collected, 1)
        if (CashLedger === null || CashLedger === undefined) {
          return
        }
        Account.LedgerID = CashLedger.ID
        Account.UserName = this.TheUser.Name
        Account.AccountID = this.TheUser.Balance.ID
        Account.UserType = 'Customer'
      }
      this.OpenDialog('BalanceHis', this.LanguageSelector('Account History', 'تاريخ الحساب'), Account) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  mounted () {
    this.TheUser = this.DialogData
    if (!this.Access('FinancialRead')) {
      return
    }
    this.ReadBalances()
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {}
  },
  computed: {
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 700px;
  max-width: 95vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 10px 25px;
  outline: none;
  font-size: 17px;
  margin: 6px;
  position: relative;
}

.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}

.barcode{
  display: flex;
  justify-content: center;
  width:300px;
  max-width:100%;
  height:100px;
  background: var(--white);
  border: solid 1px rgb(167, 167, 167);
  border-radius: var(--rad3);
  overflow: hidden;
  filter: drop-shadow(var(--shadow1));
}
.UserImage{
  width: 10em;
  height: 10em;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: 5em;
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.UserImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
.Cookies_TableButtons > tr:hover{
  background: v-bind('$store.getters.Theme.BG6');
  filter: drop-shadow(var(--shadow1));
}
.Cookies_TableButtons > tr{
  cursor: pointer;
  height: 3em;
  transition-duration: 0.2s;
  transition-property: filter, background;
}
.Cookies_TableButtons > tr > td.Arrow{
  transition-duration: 0.2s;
  transition-property: transform;
  padding-right: 1em !important;
}
.Cookies_TableButtons > tr:hover > td.Arrow{
  transform: translateX(0.2em);
}
.Cookies_TableButtons{
  padding: 1em;
}
.PrintButton{
  width: 6em;
  border-radius: 5em;
  background: v-bind('$store.getters.Theme.BG6');
  padding: 0.4em 0.4em;
  cursor: pointer;
  filter: drop-shadow(var(--shadow0));
  transition-property: transform, filter;
  transition-duration: 0.2s;
}
.PrintButton:hover{
  filter: drop-shadow(var(--shadow1));
  transform: translateY(-0.2em);
}
</style>
