<template>
<div :style="Name ==='' ? {'padding-right': '0px'} : {'padding-right': '0.2em'}" class="M-Auto" :class="Disabled ? 'CookiesInputDisabled' : ''">
  <label :style="Name ==='' ? $store.getters.Direction === 'ltr' ? {'padding-right': '0px'} : {'padding-left': '0px'} : ''" class="F-Bold SwitchInput" :class="DirectionalCheckBoxContainerClass"><span style="border-radius: var(--rad3); padding: 0.2em;">{{Name}}</span>
    <input @change="OnChange" type="radio" :name="Group" :checked="Checked">
    <span :class="DirectionalCheckBoxClass"></span>
  </label>
</div>
</template>

<script>
export default {
  name: 'CookiesRadio',
  data () {
    return {
      Checked: this.Default
    }
  },
  props: {
    Default: { default: false },
    Name: { default: '', Type: String },
    Group: { default: '', Type: String },
    Disabled: { default: false, Type: Boolean },
    Value: { default: '', Type: String }
  },
  mounted () {
    if (this.MEMRead(this.Group) === undefined) {
      this.$store.state.CookiesMemory[this.Group] = ''
    } else if (this.MEMRead(this.Group) === this.Value) {
      this.Checked = true
    } else {
      this.Checked = this.Default
    }
  },
  methods: {
    OnChange () {
      this.$store.state.CookiesMemory[this.Group] = this.Value
      this.$emit('Changed')
    }
  },
  computed: {
    CurrentValue () {
      return 1
    },
    DirectionalCheckBoxClass () {
      if (this.$store.getters.Direction === 'ltr') {
        return 'CookiesCheckBoxLeft'
      } else {
        return 'CookiesCheckBoxRight'
      }
    },
    DirectionalCheckBoxContainerClass () {
      if (this.$store.getters.Direction === 'ltr') {
        return 'CookiesCheckBoxContaierLeft'
      } else {
        return 'CookiesCheckBoxContaierRight'
      }
    }
  }
}
</script>

<style scoped>
.CookiesInputDisabled{
  opacity: 0.3;
  pointer-events: none;
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Cookies Check Box */
.CookiesCheckBoxLeft{
  width: 1.6em;
  background-color: var(--shade1_5);
  height: 1.6em;
  position: absolute;
  top: -0.2em;
  left: 0;
  cursor: pointer;
  border-radius: 0.5em;
}
.CookiesCheckBoxRight{
  width: 1.6em;
  background-color: var(--shade1_5);
  height: 1.6em;
  position: absolute;
  top: -0.2em;
  right: 0;
  cursor: pointer;
  border-radius: 0.5em;
}
.CookiesCheckBoxContaierLeft input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  white-space: nowrap;
}
.CookiesCheckBoxContaierLeft{
  position: relative;
  padding-left: 2em;
  padding-right: 0.7em;
  white-space: nowrap;
}
.CookiesCheckBoxContaierLeft input:checked ~ .CookiesCheckBoxLeft {
  background-color: var(--shade1_5);
}
.CookiesCheckBoxContaierLeft input:checked ~ .CookiesCheckBoxLeft:after {
  display: block;
}
.CookiesCheckBoxContaierLeft .CookiesCheckBoxLeft:after {
  left: 0.5em;
  top: -0.2em;
  width: 0.5em;
  height: 1.1em;
  border: solid v-bind('$store.getters.Theme.UserColors.fourth');
  border-width: 0 0.4em 0.4em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.CookiesCheckBoxContaierRight input{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  white-space: nowrap;
}
.CookiesCheckBoxContaierRight{
  position: relative;
  padding-right: 2em;
  padding-left: 0.7em;
  white-space: nowrap;
}
.CookiesCheckBoxContaierRight input:checked ~ .CookiesCheckBoxRight {
  background-color: var(--shade1_5);
}
.CookiesCheckBoxContaierRight input:checked ~ .CookiesCheckBoxRight:after {
  display: block;
}
.CookiesCheckBoxContaierRight .CookiesCheckBoxRight:after {
  left: 0.5em;
  top: -0.2em;
  width: 0.5em;
  height: 1.1em;
  border: solid v-bind('$store.getters.Theme.UserColors.fourth');
  border-width: 0 0.4em 0.4em 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.CookiesCheckBoxLeft::after{
  content: "";
  position: absolute;
  display: none;
}
.CookiesCheckBoxRight::after{
  content: "";
  position: absolute;
  display: none;
}
/* .CookiesRadioContaier input:checked ~ .CookiesRadio:after {
  display: block;
} */
/* .CookiesRadioContaier .CookiesRadio:after {
  left: 8px;
  top: -4px;
  width: 8px;
  height: 20px;
  border: solid v-bind('$store.getters.Theme.UserColors.fourth');
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
} */
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Cookies Check Box */
.SwitchInput:focus-within span{
  outline: 1px solid v-bind('$store.getters.Theme.UserColors.third_50') !important;
}
.SwitchInput{
  outline: 1px solid transparent;
}
</style>
