<template>
  <div class="ImageHolder">
    <div class="P-Abs" @click="$store.state.ImageNow = ''" style="width: 100%; height:100%"></div>
    <img class="ImageDom" :src="$store.state.ImageNow" alt="">
    <!-- <div class="M-Auto ButtonsDom"><CookiesIcon :ID="''"></CookiesIcon><img @click="$store.state.ImageNow = ''" src="@/assets/buttons/close.svg" class="UnderButtons" alt=""><img @click="OpenImage()" src="@/assets/buttons/open.svg" class="UnderButtons" alt=""></div> -->
    <div class="M-Auto ButtonsDom"></div>
  </div>
</template>

<script>
export default {
  name: 'Cookies Image Viewer',
  created () {},
  data () {
    return {}
  },
  props: {},
  methods: {
    OpenImage () {
      window.open(this.$store.state.ImageNow)
    }
  }
}
</script>

<style scoped>
.ImageHolder{
  background: rgba(0, 0, 0, 0.85);
  width: 100vw;
  height: 100vh;
  inset: 0;
  position: fixed;
  z-index: 6000;
  display: flex;
  flex-direction: column;
}
.ImageDom{
  max-height:85%;
  max-width: 85%;
  margin: auto;
  margin-bottom: 10px;
  filter: drop-shadow(var(--shadow1));
}
.ButtonsDom{
  margin-top: 10px ;
  filter: drop-shadow(var(--shadow1));
}
.UnderButtons{
  opacity: 0.7;
  width: 25px;
  padding: 10px 20px;
  cursor: pointer;
}
.UnderButtons:hover{
  opacity: 1;
}

</style>
