<template>
<div  class="CookiesInputHolder" :style="[StyledOut, {color: Color}]" :class="Disabled ? 'Disabled' : ''">
  <input @click="$store.state.CookiesMemory[Res] = ''" :min="NumMin" :max="'5'" :style="{color: Color, background: Background}" @change="ValueChanged()" @focus="NameOnFocus = true" @blur="DelayDefocus()" v-model="$store.state.CookiesMemory[Res]" :class="ErrorData && 'ErrorBorderDrop'" class="CookiesInputInside" :type="Type" name="Name" :placeholder="Name" autocomplete="off">
  <div :style="{background: Background}" v-if="Options.length > 0 && NameOnFocus && !NewValue" class="CookiesInputSearch">
    <div v-for="element in Options" :key="element"  @click="SelectElement(element)" :style="SearchInput(element)" class="CookiesInputSearchElement">
      <div class=" M-Auto">{{element}}</div>
    </div>
  </div>
  <div v-if="NewCheck & New" class="NewLayer D-Flex Events-None">
    <div class="NewTick">NEW</div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    Width: { default: '', type: String },
    Height: { default: '', type: String },
    Default: { default: '' },
    Background: { default: 'var(--colorw2)', type: String },
    Options: { default: [] },
    Res: { type: String },
    Disabled: { default: false, type: Boolean },
    Name: { default: '', type: String },
    ErrorData: { default: false },
    Type: { default: 'string', type: String },
    New: { default: true, type: Boolean },
    Color: { default: 'var(--colorb1)', type: String },
    NumMax: { default: 99999999999, type: Number },
    NumMin: { default: -99999999999, type: Number },
    ClickRest: { default: false, type: Boolean }
  },
  data () {
    return {
      NameOnFocus: false,
      NewValue: false
    }
  },
  mounted () {
    this.$store.state.CookiesMemory[this.Res] = this.Default
  },
  methods: {
    DelayDefocus () {
      setTimeout(() => {
        if (this.$store.state.CookiesMemory[this.Res] === '') {
          this.$store.state.CookiesMemory[this.Res] = this.Default
        }
        this.NameOnFocus = false
      }, 250)
    },
    SelectElement (element) {
      this.$store.state.CookiesMemory[this.Res] = element
      this.$emit('Selected', element)
    },
    SearchInput (item) {
      var itemLow = item.toString().toLowerCase()
      if (itemLow.search(this.$store.state.CookiesMemory[this.Res].toString().toLowerCase()) === -1) {
        return { display: 'none' }
      }
    },
    ResetValue () {
      this.$store.state.CookiesMemory[this.Res] = this.Default
    },
    ValueChanged () {
      if (this.Type === 'number') {
        if (parseFloat(this.$store.state.CookiesMemory[this.Res]) < this.NumMin || parseFloat(this.$store.state.CookiesMemory[this.Res]) > this.NumMax) {
          this.$store.state.CookiesMemory[this.Res] = this.Default
        } else {
          this.$store.state.CookiesMemory[this.Res] = parseFloat(this.$store.state.CookiesMemory[this.Res])
        }
      }
    }
  },
  computed: {
    ALLOptions () {
      if (this.Radio) {
        return this.Options
      }
      return ['All'].concat(this.Options)
    },
    StyledOut () {
      return {
        width: this.Width,
        height: this.Height
      }
    },
    Selected () {
      return this.$store.state.CookiesMemory[this.Res]
    },
    NewCheck () {
      if (this.Options.includes(this.$store.state.CookiesMemory[this.Res])) {
        return false
      } else {
        return true
      }
    }
  },
  emits: {
    Selected: null,
    NewStateChanged: null
  },
  watch: {
    NewCheck: function (newVal, oldVal) { // watch it
      this.$store.state.CookiesMemory[this.Res + '_New'] = this.NewCheck
    },
    Options: function (newVal, oldVal) { // watch it
      try {
        if (this.Options.indexOf(this.$store.state.CookiesMemory[this.Res]) === -1) {
          this.ResetValue()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.ErrorBorderDrop{
  border: solid var(--colorr2) 2px !important;
  padding: 0.5em 1.6em;
}
.CookiesInputInside{
  height: 100%;
  width: 100%;
  border: none;
  border-radius: var(--rad2);
  background: var(--shade1);
  flex: auto;
  padding: 0.6em 1em;
  outline: none;
  font-size: 1.1em;
  margin-top: -0.6em;
  margin-left: -1em;
  transition-property: all;
  transition-duration: 0.4s;
}
.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesInputSearch{
  width: 100%;
  max-height: 300px;
  z-index: 10;
  position: relative;
  border-radius: var(--rad2);
  filter: drop-shadow(var(--shadow1));
  margin-top: 0.3em;
  overflow-y: scroll;
}
.CookiesInputSearchElement{
  text-align: left;
  cursor: pointer;
  font-size: 1em;
  animation-name: Animation1;
  animation-duration: 0.5s;
  height: 30px;
  transition-property: all;
  transition-duration: 0.4s;
  margin: auto;
  padding: 0.3em 1.3em;
  margin: 0.3em;
  border-bottom: solid 1px rgba(0, 0, 0, 0.103);
  white-space: nowrap;
  overflow-x: hidden;
  z-index: 150;
}

.CookiesInputSearchElement:hover{
  font-size: 1.35em;
  background: var(--shade1);
  border-radius: var(--rad2);
}
@keyframes Animation1 {
  from {height: 0px; opacity: 0}
  to {height: 30px; opacity: 1}
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 0.6em 1.5em;
  outline: none;
  font-size:1.1em;
  position: relative;
}
.NewTick{
  padding: 0.1em 0.6em;
  border-radius: var(--rad3);
  background: var(--colorg2);
  display: inline;
  margin: auto 15px auto auto;
  right: 0;
  filter: drop-shadow(0 0 7px var(--colorg2));
}
</style>
