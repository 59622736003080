<template>
  <div class="W-100 H-100 LoadingWrapper D-Flex">
    <div style="animation-delay: 0s;"></div>
    <div style="animation-delay: 0.5s;"></div>
    <div style="animation-delay: 1s;"></div>
  </div>
</template>
<script>
export default {
}
</script>
<style scoped>
.LoadingWrapper{
  justify-content: center;
}
.LoadingWrapper > div{
  animation-iteration-count: infinite;
  animation-name: CookiesLoading;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  margin: 0.2em;
  width: 0.4em;
  height: 0.4em;
  background-color: v-bind('$store.getters.Theme.UserColors.second');
  border-radius: 5em;
  border: 0.1em solid v-bind('$store.getters.Theme.UserColors.second_50');
  filter: drop-shadow(0 0 0.3em v-bind('$store.getters.Theme.UserColors.second_50'));
}
@keyframes CookiesLoading {
  0% {opacity: 0.1; background-color: v-bind('$store.getters.Theme.color3');}
  20% {opacity: 1; background-color: v-bind('$store.getters.Theme.UserColors.second');}
  60% {opacity: 0.1; background-color: v-bind('$store.getters.Theme.color3');}
  100% {opacity: 0.1; background-color: v-bind('$store.getters.Theme.color3');}
}
</style>
