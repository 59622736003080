<template>
<div>
  <slot></slot>
  <svg class="W-100 H-100 IconFixer">
    <use :href="IconsSheet + '#' + ID" :style="Styler"></use>
  </svg>
</div>
</template>

<script>
export default {
  name: 'CookiesIcon',
  data () {
    return {}
  },
  props: {
    ID: { default: '', Type: String },
    Color: { default: '', Type: String },
    IconsSheet: { default: require('@/assets/icons/ThemeIcons.svg') }
  },
  methods: {
    ColorNotValid (color) {
      return color === undefined || color === null || color === ''
    }
  },
  computed: {
    Styler () {
      if (this.Color === '') {
        return '--ColorA: ' + this.$store.getters.Theme.color4 + ';' + '--ColorB: ' + this.$store.getters.Theme.color5 + ';' + '--ColorMain: ' + this.$store.getters.Theme.UserColors.main + ';' + '--ColorSecond: ' + this.$store.getters.Theme.UserColors.second + ';'
      } else if (typeof this.Color === 'string') {
        return '--ColorA: ' + this.Color + ';' + '--ColorB: ' + this.Color + ';' + '--ColorMain: ' + this.Color + ';' + '--ColorSecond: ' + this.Color + ';'
      } else {
        var ColorA
        var ColorB
        var ColorMain
        var ColorSecond
        try {
          ColorA = this.Color.ColorA
        } catch (error) {
        }
        try {
          ColorB = this.Color.ColorB
        } catch (error) {
        }
        try {
          ColorMain = this.Color.ColorMain
        } catch (error) {
        }
        try {
          ColorSecond = this.Color.ColorSecond
        } catch (error) {
        }
        if (this.ColorNotValid(ColorA)) {
          ColorA = this.$store.getters.Theme.color4
        }
        if (this.ColorNotValid(ColorB)) {
          ColorB = this.$store.getters.Theme.color5
        }
        if (this.ColorNotValid(ColorMain)) {
          ColorMain = this.$store.getters.Theme.UserColors.main
        }
        if (this.ColorNotValid(ColorSecond)) {
          ColorSecond = this.$store.getters.Theme.UserColors.second
        }
        return '--ColorA: ' + ColorA + ';' + '--ColorB: ' + ColorB + ';' + '--ColorMain: ' + ColorMain + ';' + '--ColorSecond: ' + ColorSecond + ';'
      }
    }
  }
}
</script>

<style>
.IconFixer{
  transform: scale(1,-1);
}
</style>
