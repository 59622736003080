<template>
<div class="CookiesCardFill color3">
  <div class="M-Auto CookiesCardCentered">
    <div class="NumbersBar F1_25em F-Light EvenContainer">
      <div class="NumbersBarItem D-Flex BG1"> <!-- Total Number -->
        <div class="Total F_75em F-Bold O-75 NumberTitle">{{LanguageSelector('My Cash', 'الشخصي')}}</div>
        <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
          <div class="M-Auto F3em F-Light colorFourth" style="">{{DecimalFixer(MyCash)}}</div>
        </div>
        <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
          <div class="M-Auto F1em F-ExtraLight colorFourth" style="margin-bottom: 0.85em;">{{$store.state.CookiesConfig.PriceUnit}}</div>
        </div>
      </div>
      <div class="NumbersBarItem D-Flex LeftVSep">
        <div class=" F_75em F-Bold O-50 NumberTitle">{{LanguageSelector('Customers', 'العملاء')}}</div>
        <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
          <div class="M-Auto F3em F-ExtraLight">{{DecimalFixer(CustomersTotalCash)}}</div>
        </div>
        <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
          <div class="M-Auto F1em F-ExtraLight" style="margin-bottom: 0.85em;">{{$store.state.CookiesConfig.PriceUnit}}</div>
        </div>
      </div>
      <div class="NumbersBarItem D-Flex LeftVSep">
        <div class=" F_75em F-Bold O-50 NumberTitle">{{LanguageSelector('Drivers', 'السائقون')}}</div>
        <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
          <div class="M-Auto F3em F-ExtraLight">{{DecimalFixer(DriversTotalCash)}}</div>
        </div>
        <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
          <div class="M-Auto F1em F-ExtraLight" style="margin-bottom: 0.85em;">{{$store.state.CookiesConfig.PriceUnit}}</div>
        </div>
      </div>
    </div>
    <div class="CookiesRow W-100 P-Rel" style="margin-top: 1em">
      <button v-if="Access('FinancialAdd')" @click="NewTrans()" class="CookiesButton F1_25em F-Bold  BGThird" style="margin: 0 1em;"><span class=" O-75">{{LanguageSelector('Add Transaction', 'حركة جديدة')}}</span></button>
      <button v-if="Access('Settlement')" @click="Settlement()" class="CookiesButton F1_25em F-Bold  BGThird" style=""><span class=" O-75">{{LanguageSelector('Cash Settlement', 'مطابقة الكاش')}}</span></button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      CookiesDataCustomers: [],
      CookiesDataDrivers: [],
      MyCash: 0,
      CashAccount: {},
      Today: true
    }
  },
  mounted () {
    this.ReadCustomers()
    this.ReadDrivers()
    this.ReadMyCash()
  },
  computed: {
    CustomersTotalCash () {
      var Total = 0
      this.CookiesDataCustomers.forEach(customer => {
        var DeliveredLedger = {}
        var CustomersLedgers = this.DeepFetcher(customer, ['Balance', 'ledgers'])
        if (this.ObjectFinder(CustomersLedgers, ['Type'], 17)) {
          DeliveredLedger = this.ObjectFinder(CustomersLedgers, ['Type'], 17)
          DeliveredLedger.NetBalance = DeliveredLedger.Credited - DeliveredLedger.Debited
        }
        customer.DeliveredLedger = DeliveredLedger
        Total += DeliveredLedger.NetBalance
      })
      return Total
    },
    DriversTotalCash () {
      var Total = 0
      this.CookiesDataDrivers.forEach(Driver => {
        var DeliveredLedger = {}
        var DriversLedgers = this.DeepFetcher(Driver, ['DriverBalance', 'ledgers'])
        if (this.ObjectFinder(DriversLedgers, ['Type'], 12)) {
          DeliveredLedger = this.ObjectFinder(DriversLedgers, ['Type'], 12)
          DeliveredLedger.NetBalance = DeliveredLedger.Debited - DeliveredLedger.Credited
        }
        Driver.DeliveredLedger = DeliveredLedger
        Total += DeliveredLedger.NetBalance
      })
      return Total
    },
    CurrentDate () {
      var DateRange = {}
      DateRange.To = this.$store.getters.CurrentDate.To
      DateRange.From = this.$store.getters.CurrentDate.From
      return DateRange
    }
  },
  methods: {
    ReadMyCash () {
      this.Loading = true
      this.Get('Financial/GetAccount?ID=-1').then(response => {
        if (response === null) {
          this.Loading = false
          return
        }
        this.MyCash = response[0].TotalDebited - response[0].TotalCredited
        this.CashAccount = response[0]
        this.Console(response, 'color: yellow; font-size: 4em;')
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadCustomers () {
      this.Loading = true
      this.Get('Customers').then(response => {
        if (response === null) {
          this.Loading = false
          return
        }
        this.CookiesDataCustomers = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Customers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    Settlement () {
      var TData = {}
      TData.Category = 'Cash'
      TData.APIURL = 'Financial/SuttleBalance'
      TData.Header = this.LanguageSelector('Cash Settlement', 'مطابقة الكاش')
      TData.NetAmmount = this.MyCash
      TData.BalanceType = 'Crediter'
      this.OpenDialog('Settlement', this.LanguageSelector('Settlement', 'تسوية حساب'), TData) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    ReadDrivers () {
      this.Loading = true
      this.Get('Employee/getdriver').then(response => {
        if (response === null) {
          this.Loading = false
          return
        }
        this.CookiesDataDrivers = response
        this.Loading = false
      }, error => {
        this.Loading = false
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewOrder () {
      this.OpenDialog('NewOrder', this.LanguageSelector('New Order', 'طلب جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    NewTrans () {
      var TData = {}
      TData.Category = 'Main'
      TData.Account = this.CashAccount
      this.Console(this.CashAccount)
      TData.Header = this.LanguageSelector('New Transaction', 'حركة ماليه جديده')
      this.OpenDialog('NewTrans', this.LanguageSelector('New Transaction', 'حركة جديدة'), TData) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadCustomers()
      this.ReadDrivers()
      this.ReadMyCash()
    }
  }
}
</script>

<style scoped>
.CookiesCardFill{
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.CookiesCardCentered{
  padding: 0.3em;
  height: 80%;
  width: 100%;
  position: relative;
}
.NumbersBar{
  width: 90%;
  background: var(--shade1);
  margin: auto;
  height: 65%;
  border-radius: var(--rad2);
  justify-content: center;
  overflow: hidden;
}
.CookieesNewLine{
  width: 100%;
}
.NumbersBarItem{
  height: 100%;
  /* vertical-align: middle; */
  display: flex;
  flex-direction: column;
  /* line-height: 1.4em; */
  position: relative;
  /* justify-content: center; */
}
.NumberTitle{
  margin-top: 0.2em;
}

.LeftVSep{
  border-left: solid 1px var(--shade1);
  margin-left: -1px;
}
@media (max-width: 700) {
  .NumbersBarItem{
    font-size: 2vw !important;
  }
  .CookiesButton{
    font-size: 1em !important;
  }
}
@media (max-width: 1200) {
  .NumbersBarItem{
    font-size: 0.4em !important;
  }
  .CookiesButton{
    font-size: 1em !important;
  }
}
.ToggleSelected{
  background: var(--white);
  opacity: 0.9;
}
.ToggleDeSelected{
  background: var(--wshade3);
  opacity: 0.4;
  color: var(--wshade3);
}
.NumberIcon{
  position: absolute;
  float: left;
  height:1.5em;
  z-index:-5;
}
[Tooltip]{
  z-index:10;
}
</style>
