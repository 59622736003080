<template>
<div class="CookiesRow W-75" style="margin-top: 4em; font-size: 1.1em;">
  <!-- Theme -------------------------- -->
  <span class="SettingsName">{{LanguageSelector('Theme', 'المظهر')}}</span>
  <div class="CookiesRow">
    <div @click="ChooseTheme('1')" :ToolTip="LanguageSelector('Light', 'فاتح')" class="Shadow1 Pointer" :style="'border: solid 0.25em ' + ( $store.state.CookiesConfig.Theme !== '1' ? $store.getters.Theme.BG1 : $store.getters.Theme.UserColors.main) + ';'" style="width: 2em; height: 2em; border-radius: 50%; margin: 0em 0.5em; background: rgb(240,240,240);"></div>
    <div @click="ChooseTheme('0')" :ToolTip="LanguageSelector('Dark', 'غامق')" class="Shadow1 Pointer" :style="'border: solid 0.25em ' + ( $store.state.CookiesConfig.Theme !== '0' ? $store.getters.Theme.BG1 : $store.getters.Theme.UserColors.main) + ';'" style="width: 2em; height: 2em; border-radius: 50%; margin: 0em 0.5em; background: rgb(35,35,35);"></div>
  </div>
   <!-- Color -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <span class="SettingsName">{{LanguageSelector('Main Color', 'اللون الرئيسي')}}</span>
  <div class="CookiesRow">
    <CookiesColorPicker @ColorChanged="EditConfig('color', $store.state.CookiesConfig.color)" :Res="'color'"></CookiesColorPicker>
  </div>
   <!-- Background -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <span class="SettingsName">{{LanguageSelector('Background', 'الخلفية')}}</span>
  <div class="CookiesRow BackgroundContainer">
    <div class="D-Flex">
      <CookiesRadioButton @Changed="EditConfig('background', MEMRead('SystemBackground'))" :Default="$store.state.CookiesConfig.background === 'BGGradient'" :Value="'BGGradient'" :Name="LanguageSelector('Gradient', 'متدرج')" :Group="'SystemBackground'"></CookiesRadioButton>
      <div class="M-Auto ColorBox BG2 Shadow1">
        <div class="BGGradient"></div>
      </div>
    </div>
    <div class="CookiesColSplitter"></div>
    <div class="D-Flex">
      <CookiesRadioButton @Changed="EditConfig('background', MEMRead('SystemBackground'))" :Default="$store.state.CookiesConfig.background === 'BGThird'" :Value="'BGThird'" :Name="LanguageSelector('Solid', 'ثابت')" :Group="'SystemBackground'"></CookiesRadioButton>
      <div class="M-Auto ColorBox BG2 Shadow1">
        <div class="BGThird"></div>
      </div>
    </div>
    <div class="CookiesColSplitter"></div>
    <div class="D-Flex">
      <CookiesRadioButton @Changed="EditConfig('background', MEMRead('SystemBackground'))" :Default="$store.state.CookiesConfig.background === 'BGDimmed'" :Value="'BGDimmed'" :Name="LanguageSelector('Darker', 'غامق')" :Group="'SystemBackground'"></CookiesRadioButton>
      <div class="M-Auto ColorBox BG2 Shadow1">
        <div class="BGDimmed"></div>
      </div>
    </div>
    <div class="CookiesColSplitter"></div>
    <div class="D-Flex">
      <CookiesRadioButton @Changed="EditConfig('background', MEMRead('SystemBackground'))" :Default="$store.state.CookiesConfig.background === 'BG1Fixed'" :Value="'BG1Fixed'" :Name="LanguageSelector('Blank', 'فارغ')" :Group="'SystemBackground'"></CookiesRadioButton>
      <div class="M-Auto ColorBox BG2 Shadow1">
        <div class="BG1Fixed"></div>
      </div>
    </div>
  </div>
   <!-- Color -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <span class="SettingsName">{{LanguageSelector('Transparency', 'الشفافية')}}</span>
  <div class="CookiesRow">
    <CookiesCheckBox @Changed="EditConfig('Transparent', MEMRead('Config_Transparency'))" :Default="$store.state.CookiesConfig.Transparent" :Type="'Switch'" :Res="'Config_Transparency'"></CookiesCheckBox>
  </div>
   <!-- Language -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <span class="SettingsName">{{LanguageSelector('Language', 'اللغة')}}</span>
  <div class="CookiesRow">
    <CookiesDropDown @slection_changed="LanguageChanged" :IndexSelect="LanguageIndex" :Res="'Config_Language'" :Width="'10em'" :Type="'Object'" :Selector="['Name']" :Radio="true" :Options="LanguagesArray"></CookiesDropDown>
  </div>
  <!-- Name -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <span class="SettingsName">{{LanguageSelector('App Name', 'الاسم')}}</span>
  <div class="CookiesRow">
    <input @blur="EditConfig('Name', $store.state.CookiesConfig.Name)" v-model="$store.state.CookiesConfig.Name" class="CookiesInput color2" type="text">
  </div>
  <!-- Price Unit -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <span class="SettingsName">{{LanguageSelector('Currency Name', 'اسم العملة')}}</span>
  <div class="CookiesRow">
    <input @blur="EditConfig('PriceUnit', $store.state.CookiesConfig.PriceUnit)" v-model="$store.state.CookiesConfig.PriceUnit" class="CookiesInput color2" type="text">
  </div>
  <!-- Logo -------------------------- -->
  <div class="CookiesRowSplitter"/>
  <span class="SettingsName">{{LanguageSelector('Logo', 'الشعار')}}</span>
  <div class="CookiesRow">
    <label class="LogoImageUpload Pointer Shadow1" :style="'background-image: ' + 'url(' + DefaultImageHandler($store.state.CookiesConfig.Logo, 'https://cookiesbis.com/assets/Logo.svg') + ')'">
      <input @change="LogoChanged()" name="LogoImageUpload" class="D-None" type="file" accept="image/*">
    </label>
  </div>
</div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'System Config',
  data () {
    return {
      LanguageIndex: -1,
      selectedFile: null,
      OriginalConfig: {},
      LanguagesArray: [],
      Saving: false
    }
  },
  mounted () {
    const _this = this
    Object.keys(this.Languages).some(function (Lang) {
      _this.LanguagesArray.push({
        Name: _this.Languages[Lang].Name,
        Code: Lang
      })
    })
    this.LanguageIndex = this.IndexFinder(this.LanguagesArray, ['Code'], this.$store.state.CookiesConfig.Language)
    this.MEMWrite('SystemBackground', this.$store.state.CookiesConfig.background)
    this.ReadConfigData()
  },
  computed: {},
  methods: {
    ReadConfigData () {
      this.Get('Config').then(response => {
        if (response === null) {
          return
        }
        response.forEach(element => {
          this.$store.state.CookiesConfig[element.Name] = element.value
          this.$store.state.CookiesConfig[element.Name + 'ID'] = element.ID
          this.OriginalConfig[element.Name] = element.value
          this.OriginalConfig[element.Name + 'ID'] = element.ID
        })
        this.Console(this.OriginalConfig)
      }, error => {
        error.CookiesError = 'Error in reading Cookies Config'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    SaveConfig (attr, value) {
      var newItem = {
        Name: attr,
        value: value
      }
      this.Console(newItem)
      this.Post('POST', 'Config/Add', newItem).then(response => {
        this.Saving = false
        this.ReadConfigData()
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Saving Config'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    EditConfig (attr, value) {
      this.Console(attr)
      this.Console(this.OriginalConfig)
      this.Console(this.OriginalConfig[attr])
      var GetItem = this.OriginalConfig[attr]
      if (GetItem === null || GetItem === undefined) {
        this.SaveConfig(attr, value)
        return
      }
      var ClonedItem = this.DeepCloner(GetItem)
      var OriginalItem = {
        value: ClonedItem
      }
      var NewItem = {
        value: value
      }
      var Patch = this.CookiesPatcher(OriginalItem, NewItem)
      if (Patch.length === 0) {
        this.Saving = false
        return
      }
      if (!this.selectedFile || this.selectedFile === null || this.selectedFile === undefined || attr !== 'Logo') {
        this.Post('PATCH', 'Config/Edit?ID=' + this.OriginalConfig[attr + 'ID'], Patch).then(response => {
          this.Saving = false
          this.ReadConfigData()
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Editing Config'
          this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      } else {
        this.PostImage(this.selectedFile).then(response => {
          NewItem.value = response.url
          Patch = this.CookiesPatcher(OriginalItem, NewItem)
          this.Post('PATCH', 'Config/Edit?ID=' + this.OriginalConfig[attr + 'ID'], Patch).then(response => {
            this.ReadConfigData()
            this.Saving = false
          }, error => {
            this.Saving = false
            error.CookiesError = 'Error in Editing Config'
            this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Uploading Image'
          this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    },
    ChooseTheme (theme) {
      this.$store.state.CookiesConfig.Theme = theme
      this.EditConfig('Theme', theme)
    },
    LanguageChanged (value) {
      this.$store.state.CookiesConfig.Language = value.Code
      this.EditConfig('Language', value.Code)
    },
    LogoChanged () {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        return
      }
      this.EditConfig('Logo', file)
    }
  }
}
</script>

<style>
.SettingsName{
  text-align: v-bind('$store.getters.DefaultTextAlign');
  min-width: 6em;
  font-size: 1.25em;
}
.LogoImageUpload{
  width: 10em;
  height: 10em;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 1em;
  border-radius: var(--rad2);
  border: 1px solid var(--shade1)
}
.ColorBox{
  border-radius: var(--rad1);
  display: flex;
  width: 3.8em;
  height: 2.2em;
}
.ColorBox > div{
  border-radius: var(--rad3);
  height: 1.5em;
  width: 3em;
  margin: auto;
}
@media (max-width: 1450px){
  .BackgroundContainer{
    flex-direction: column;
    gap: 0.3em;
  }
  .BackgroundContainer .CookiesRowSplitter{
    display: none;
  }
}
</style>
