<template>
<div class="TabContainer BGShade1 color2 D-Flex M-Auto" style="background: var(--shade2)">
  <template v-for="tab in Tabs" :key="tab">
    <router-link v-if="Router" @click="Selected(tab)" class="RepTabButton F-Bold" :to="tab.Link">{{tab.Name}}</router-link>
    <div v-else :class="SelectedTab.Value === tab.Value ? 'SelectedTab': ''" @click="Selected(tab)" class="RepTabButton D-Flex F-Bold Pointer">{{tab.Name}}<CookiesIcon class="M-Auto" style="width: 2em; height: 2em; display: inline-block;" v-if="tab.Icon !== undefined" :ID="tab.Icon"></CookiesIcon></div>
  </template>
</div>
</template>
<script>
export default {
  props: {
    Tabs: { default: [] },
    Router: { default: true, Type: Boolean },
    Default: { default: {} }
  },
  data () {
    return {
      SelectedTab: {}
    }
  },
  mounted () {
    this.SelectedTab = this.Default
  },
  methods: {
    Selected (tab) {
      this.$emit('selected', tab)
      this.SelectedTab = tab
    }
  }
}
</script>
<style scoped>
.RepTabButton{
  padding: 0.5em 3em;
  margin: 0.15em;
  text-decoration: none;
  filter: drop-shadow(var(--shadow2));
  color: v-bind('$store.getters.Theme.color3');
}
.RepTabButton:active{
  text-decoration: none;
  color: v-bind('$store.getters.Theme.color1');
}
.TabContainer{
  /* margin-top: 1em; */
  /* padding: 0.3em; */
  padding: 0.3em;
  border-radius: var(--rad3);
}
a.router-link-exact-active{
  background: v-bind('$store.getters.Theme.BG2');
  border-radius: var(--rad3);
  color: v-bind('$store.getters.Theme.color1');
  /* color: var(--colorw2); */
}
.SelectedTab {
  background: v-bind('$store.getters.Theme.BG2');
  border-radius: var(--rad3);
  color: v-bind('$store.getters.Theme.color1');
}
</style>
