<template>
<div class="CookiesCardFill color3">
  <div class="M-Auto CookiesCardCentered">
    <div class="NumbersBar F1_25em F-Light EvenContainer">
      <div class="NumbersBarItem D-Flex BG1"> <!-- Total Number -->
        <div class="Total F_75em F-Bold O-75 NumberTitle">{{LanguageSelector('Total', 'عدد الطلبات')}}</div>
        <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
          <div class="M-Auto F3em F-Light colorFourth" style="">{{TotalOrders}}</div>
        </div>
      </div>
      <template v-for="status in ['Received', 'OnDelivery', 'Delivered', 'Canceled']" :key="status">
        <div class="NumbersBarItem D-Flex LeftVSep">
          <div class=" F_75em F-Bold O-50 NumberTitle">{{StatusMapper(status)}}</div>
          <CookiesIcon class="F-Bold M-Auto O-75" style="width: 1.5em; height: 1.5em; margin-bottom: 0.2em;" :ID="status"></CookiesIcon>
          <div class="M-Auto W-100 H-100 P-Abs Inset0 D-Flex">
            <div class="M-Auto F3em F-ExtraLight">{{StatusCounter(status)}}</div>
          </div>
        </div>
      </template>
    </div>
    <div class="CookiesRow W-100 P-Rel" style="margin-top: 1em">
      <button v-if="Access('OrdersView')" @click="$router.replace('/Orders')" class="CookiesButton F1_25em F-Bold BGShade2" style="margin: 0 1em; box-shadow: none;"><span class=" O-75">{{LanguageSelector('View Orders', 'عرض الطلبات')}}</span></button>
      <button v-if="Access('OrdersAdd')" @click="NewOrder()" class="CookiesButton F1_25em F-Bold  BGThird" style="margin: 0 0.2em;"><span class=" O-75">{{LanguageSelector('New Order', 'طلب جديد')}}</span></button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      CookiesDataOrders: [],
      Today: true
    }
  },
  mounted () {
    this.ReadOrders()
  },
  computed: {
    TotalOrders () {
      try {
        return this.CookiesDataOrders.length
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    },
    Received () {
      try {
        var ReceivedCounter = 0
        this.CookiesDataOrders.forEach(element => {
          if (element.Status === 'Received') {
            ReceivedCounter++
          }
        })
        return ReceivedCounter
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    },
    OnDelivery () {
      try {
        var OnDeliveryCounter = 0
        this.CookiesDataOrders.forEach(element => {
          if (element.Status === 'OnDelivery') {
            OnDeliveryCounter++
          }
        })
        return OnDeliveryCounter
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    },
    Delivered () {
      try {
        var DeliveredCounter = 0
        this.CookiesDataOrders.forEach(element => {
          if (element.Status === 'Delivered') {
            DeliveredCounter++
          }
        })
        return DeliveredCounter
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    }
  },
  methods: {
    ReadOrders () {
      this.Get('Order').then(response => {
        this.CookiesDataOrders = response
      }, error => {
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    NewOrder () {
      this.OpenDialog('NewOrder', this.LanguageSelector('New Order', 'طلب جديد'), '') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    AddNewTrans () {
      this.OpenDialog('AddNewTrans', 'New Transaction') // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    StatusCounter (status) {
      try {
        var Counter = 0
        this.CookiesDataOrders.forEach(element => {
          if (element.Status === status) {
            Counter++
          }
        })
        return Counter
      } catch (error) {
        console.log(error)
        return 'N/A'
      }
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      this.ReadOrders()
    }
  }
}
</script>

<style scoped>
.CookiesCardFill{
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.CookiesCardCentered{
  padding: 0.3em;
  height: 80%;
  width: 100%;
  position: relative;
}
.NumbersBar{
  width: 90%;
  background: var(--shade1);
  margin: auto;
  height: 65%;
  border-radius: var(--rad2);
  justify-content: center;
  overflow: hidden;
}
.CookieesNewLine{
  width: 100%;
}
.NumbersBarItem{
  height: 100%;
  /* vertical-align: middle; */
  display: flex;
  flex-direction: column;
  /* line-height: 1.4em; */
  position: relative;
  /* justify-content: center; */
}
.NumberTitle{
  margin-top: 0.2em;
}

.LeftVSep{
  border-left: solid 1px var(--shade1);
  margin-left: -1px;
}
@media (max-width: 700) {
  .NumbersBarItem{
    font-size: 2vw !important;
  }
  .CookiesButton{
    font-size: 1em !important;
  }
}
@media (max-width: 1200) {
  .NumbersBarItem{
    font-size: 0.4em !important;
  }
  .CookiesButton{
    font-size: 1em !important;
  }
}
.ToggleSelected{
  background: var(--white);
  opacity: 0.9;
}
.ToggleDeSelected{
  background: var(--wshade3);
  opacity: 0.4;
  color: var(--wshade3);
}
.NumberIcon{
  position: absolute;
  float: left;
  height:1.5em;
  z-index:-5;
}
[Tooltip]{
  z-index:10;
}
</style>
