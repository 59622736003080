<template>
<div v-if="Access('EmployeesAdd')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Employee -------------------------------------- -->
      <div class="CookiesRow">
        <div class="CookiesRow W-100 D-Flex">
          <label class="UserImage M-Auto" :style="'background-image: url(' + DefaultImageHandler(SelectedUserImage, $store.state.CookiesConfig.UserImage) + ');'">
            <input @change="UserImageChanged()" name="UserImage" type="file" accept="image/*">
          </label>
        </div>
        <input v-model="Name" :class=" (ErrorsVisibilty & !Name) && 'ErrorBorder' " class="CookiesInput color2" type="Text" name="Phone" :placeholder="LanguageSelector('Name', 'الاسم')" autocomplete="off">
        <input v-model="PhoneNumber" :class=" (ErrorsVisibilty & !PhoneValidator(PhoneNumber)) && 'ErrorBorder'" class="CookiesInput color2" type="Text" name="Phone" :placeholder="LanguageSelector('Phone', 'الهاتف')" autocomplete="off">
        <CookiesDropDown :Radio="true" :Res="'NewEmpType'" :Width="'8em'" :Selector="LanguageSelector(['EN'], ['AR'])" :Type="'Object'" :Options="EmpTypes" :Name="''"></CookiesDropDown>
        <div v-if="$store.state.CookiesMemory.NewEmpType.salary" class="CookiesRowSplitter"></div>
        <div v-if="$store.state.CookiesMemory.NewEmpType.salary" class="D-Flex M-Auto"><span class="M-Auto">{{LanguageSelector('Salary', 'الراتب')}}</span><CookiesNumberInput style="padding: 0 2em;" :Min="1" :Unit="$store.state.CookiesConfig.PriceUnit" :Default="$store.state.CookiesMemory.NewEmpType.salary" :Res="'NewEmpSalary'" :PlaceHolder="'Salary'"></CookiesNumberInput></div>
        <div class="CookiesRowSplitter"/>
        <div class="W-100">
          <span>{{LanguageSelector('Sign In Info', 'معلومات تسجيل الدخول')}}</span>
        </div>
        <div class="W-100" style="direction: ltr;">
        <input v-model="Email" :class=" (ErrorsVisibilty & Email === '') && 'ErrorBorder'" class="CookiesInput color2 W-50" type="Text" name="Phone" :placeholder="$store.state.CookiesConfig.Language === 'ARA' ? 'البريد الالكتروني': '' + $store.state.CookiesConfig.Language === 'ENG' ? 'Email': ''" autocomplete="off">
        <span>@orderi.com</span>
        </div>
        <form class="W-100">
          <input v-model="NewPassWord" :type="Unhider" :class="PassValidaton.NewPass && 'ErrorBorder'" class="CookiesInput color2" name="New Password" :placeholder="LanguageSelector('New Password', 'كلمة السر')" autocomplete="off">
          <input v-model="NewPassWordCheck" :type="Unhider" :class="PassValidaton.ConfirmPass && 'ErrorBorder'" class="CookiesInput color2" name="Confirm New Password" :placeholder="LanguageSelector('Confirm New Password', 'تأكيد كلمة السر')" autocomplete="off">
        </form>
      </div>
      <div class="W-100" style="padding:5px">
        <CookiesCheckBox style="text-align:left;" :Name="$store.state.CookiesConfig.Language === 'ARA' ? 'كشف كلمة المرور': '' + $store.state.CookiesConfig.Language === 'ENG' ? 'Show passwords': ''" :Res="'NewEmp_NewPasswordUnHide'" :Type="'Switch'"></CookiesCheckBox>
      </div>
    </div>
  </div>
</div>
<div v-if="Access('EmployeesAdd')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{LanguageSelector('Cancel', 'الغاء')}}</button>
    <button @click="SaveEmployee()" :style="'background: ' + $store.getters.Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{LanguageSelector('Save', 'حفظ')}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  beforeCreate () {
    this.$store.state.CookiesMemory.NewEmpType = {}
  },
  created () {},
  data () {
    return {
      Name: '',
      BarCode: '',
      PhoneNumber: '',
      Email: '',
      Type: '',
      Salary: '',
      Status: '',
      Identity: {},
      ErrorsVisibilty: false,
      NewPassWord: '',
      NewPassWordCheck: '',
      selectedFile: '',
      SelectedUserImage: '',
      UserImage: '',
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    SaveEmployee () {
      this.Console(this.NewEmployeeData)
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      if (!this.selectedFile || this.selectedFile === null || this.selectedFile === undefined) {
        this.Saving = false
        this.Post('POST', 'Employee', this.NewEmployeeData).then(response => {
          this.CloseDialog(this.DialogIndex)
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Adding New Employee'
          this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      } else {
        this.PostImage(this.selectedFile).then(response => {
          this.UserImage = response.url
          this.Post('POST', 'Employee', this.NewEmployeeData).then(response => {
            this.Saving = false
            this.CloseDialog(this.DialogIndex)
          }, error => {
            this.Saving = false
            error.CookiesError = 'Error in Adding New Employee'
            this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
          })
        }, error => {
          this.Saving = false
          error.CookiesError = 'Error in Uploading Image'
          this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
        })
      }
    },
    UserImageChanged () {
      var file = event.target.files[0]
      this.selectedFile = file
      if (!file) {
        this.SelectedUserImage = ''
        return
      }
      this.SelectedUserImage = URL.createObjectURL(file)
    }
  },
  mounted () {
    if (!this.Access('EmployeesAdd')) {
      return
    }
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    NewEmployeeData () {
      var Salary = 0
      if (this.$store.state.CookiesMemory.NewEmpType.salary) {
        Salary = this.$store.state.CookiesMemory.NewEmpSalary
      }
      return {
        Name: this.Name,
        BarCode: this.BarCode,
        PhoneNumber: this.PhoneNumber,
        Email: this.Email + '@orderi.com',
        Type: this.$store.state.CookiesMemory.NewEmpType.value,
        Salary: Salary,
        Status: 'Active',
        PasswordHash: this.NewPassWord,
        ImgURL: this.UserImage
      }
    },
    DialogError () {
      var DialogErrors = []
      this.PassValidaton.ErrorsList.length > 0 && DialogErrors.push('Please Enter Employee\'s pass')
      this.Name === '' && DialogErrors.push('Please Enter Employee\'s Name')
      !this.PhoneValidator(this.PhoneNumber) && DialogErrors.push('Please Enter Employee\'s Name')
      this.Email === '' && DialogErrors.push('Please Enter Employee\'s Email')
      return DialogErrors
    },
    isActive () {
      return this.$store.state.CookiesMemory.D_NewEmployee_Active
    },
    PassValidaton () {
      var DialogErrors = {
        NewPass: false,
        ConfirmPass: false,
        ErrorsList: []
      }
      if (this.NewPassWord === '') {
        DialogErrors.ErrorsList.push('Please enter a new passord')
        if (this.ErrorsVisibilty) {
          DialogErrors.NewPass = true
        }
      }
      if (this.NewPassWord.length < 5) {
        DialogErrors.ErrorsList.push('Password must be at least 5 characters')
        if (this.ErrorsVisibilty) {
          DialogErrors.NewPass = true
        }
      }
      if (this.NewPassWordCheck !== this.NewPassWord && this.NewPassWordCheck !== '') {
        DialogErrors.ErrorsList.push('Passwords didn\'t match')
        if (this.ErrorsVisibilty) {
          DialogErrors.ConfirmPass = true
        }
      }
      return DialogErrors
    },
    Unhider () {
      return this.$store.state.CookiesMemory.NewEmp_NewPasswordUnHide ? 'text' : 'password'
    },
    BarCodeHandler () {
      if (this.BarCode === '') {
        return 'AB123456789012'
      } else {
        return this.BarCode
      }
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 10px 25px;
  outline: none;
  font-size: 17px;
  margin: 6px;
  position: relative;
}

.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}

.barcode{
  display: flex;
  justify-content: center;
  width:300px;
  max-width:100%;
  height:100px;
  background: var(--white);
  border: solid 1px rgb(167, 167, 167);
  border-radius: var(--rad3);
  overflow: hidden;
  filter: drop-shadow(var(--shadow1));
}
.barcodeBlur canvas{
  filter: blur(4px);
}
.UserImage{
  width: 10em;
  height: 10em;
  margin-bottom: 1.5em;
  background-color: var(--shade2);
  border-radius: 5em;
  cursor: pointer;
  box-shadow: inset var(--shadow1);
  background-size: cover;
  background-position: center;
}
.UserImage > input{
  width: 0;
  height: 0;
  pointer-events: none;
}
</style>
