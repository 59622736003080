<template>
<div :class="$store.state.Printing !== '' && 'PrintingHider'" v-if="CurrentDialogs.length > 0">
  <div v-for="(Dialog, index) in CurrentDialogs" :key="(Dialog, index)" class="DialogPlaceHolder color3" style="perspective: 200em;">
    <div @click="$router.go(-1)" class="DialogShade"></div>
    <div class="Shadow1 D-Flex M-Auto CookiesFade" style="max-height: 100vh; overflow-y: scroll; flex-direction: column;">
      <div class="DialogBase" :style="'background: ' + BackgroundChecker(Dialog.Options)">
        <div v-if="HeaderChecker(Dialog.Options)" class="CookiesDialogHeader F-Bold O-75" :style="'background: ' + BackgroundChecker(Dialog.Options)">
          <span class="M-Auto color1">{{Dialog.Name}}</span>
        </div>
        <div style="min-height: 30vh;">
          <component :DialogIndex="Dialog.Num" :DialogData="Dialog.Data" :is="Dialog.Content"></component>
        </div>
      </div>
    </div>
  </div>
</div>
<div :class="$store.state.Printing !== '' && 'PrintingHider'" v-if="ErrorDialogs.length > 0">
  <div v-for="(Dialog, index) in ErrorDialogs" :key="(Dialog, index)" class="DialogPlaceHolder">
    <div class="DialogShade"></div>
    <div class="Shadow1 D-Flex M-Auto" style="max-height: 100vh; overflow: scroll;">
      <div class="DialogBase">
        <div class="CookiesDialogHeader F-Bold F-S4 O-75">
          <span class="M-Auto color1">{{Dialog.Name}}</span>
        </div>
        <div style="min-height: 30vh;">
          <component :DialogIndex="index" :DialogData="Dialog.Data" :is="Dialog.Content"></component>
        </div>
      </div>
    </div>
  </div>
</div>

</template>
<script>

export default {
  name: 'Cookies Dialog',
  created () {},
  data () {},
  components: {},
  props: {},
  methods: {
    HeaderChecker (Options) {
      try {
        if (Options.header === undefined || Options.header) {
          return true
        } else {
          return false
        }
      } catch (error) {
        return true
      }
    },
    BackgroundChecker (Options) {
      try {
        if (Options.background === undefined) {
          return ''
        } else {
          return Options.background + ';'
        }
      } catch (error) {
        return 'blue;'
      }
    }
  },
  computed: {
    CurrentDialogs () {
      return this.$store.state.CookiesDialog.OpenedDialogs
    },
    ErrorDialogs () {
      return this.$store.state.CookiesDialog.ErrorDialogs
    }
  }
}
</script>

<style>
.PrintingHider{
  width: 0 !important;
  overflow: hidden;
  height: 0 !important;
  opacity: 0;
  display: none;
}
.DialogShade {
  backdrop-filter: v-bind('$store.getters.Theme.BackBlur');
  background: v-bind('$store.getters.Theme.BackShader');
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -80;
}
.DialogPlaceHolder{
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 5000;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  direction: v-bind('$store.getters.Direction');
}
.DialogBase{
  /* background: var(--colorw1); */
  background: v-bind('$store.getters.Theme.BG2');
  margin: auto;
  border-radius: var(--rad1);
  max-width: 90vw;
  line-break: anywhere;
  /* min-width: 40vw; */
  /* min-height: 40vh;
  height: 40vh; */
  justify-content: center;
  text-align: center;
  display: inline-table;
  /* margin-top: 50px; */
  pointer-events: all;
  overflow-x: hidden;
}

.CookiesDialogHeader{
  width:100%;
  height:60px;
  background-color: v-bind('$store.getters.Theme.BG4');
  border-radius: var(--rad2) var(--rad2) 0 0;
  text-align: left;
  vertical-align: middle;
  justify-content: center;
  text-align: center;
  display: flex;
  font-size: 22px;
  transition-property: font-size;
  transition-duration: 0.5s;
}
.CookiesDialogdBody{
  min-height: 30vh;
  font-size: 1.1rem;
  height: calc(100% - 140px);
  border-radius: var(--rad1);
  /* overflow: hidden; */
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  display: flex;
  /* margin-bottom: 80px; */
  overflow-wrap: nowrap;
}
@media (max-width: 550px){
  .CookiesDialogHeader{
    font-size: 4.5vw;
  }
  .CookiesDialogdBody{
    font-size: 0.9rem;
  }
}
.CookiesDialogFooter{
  width:100%;
  height:80px;
  background-color: v-bind('$store.getters.Theme.BG4');
  border-radius: var(--rad1);
  text-align: left;
  vertical-align: middle;
  margin-bottom: 0;
  margin-top: auto ;
  /* position: absolute; */
  bottom: 0;
  justify-content: center;
  text-align: center;
  display: flex;
}
.DialogFooterButton{
  margin: 5px;
}
</style>
