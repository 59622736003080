<template>
<div class="CookiesInputHolder color2" :ref="Res"  :style="StyledOut" :class="[Disabled ? 'Disabled' : '']">
  <input :disabled="Disabled" @change="InputChanged()" @focus="focused" @blur="DelayDefocus()" v-model="$store.state.CookiesMemory[Res]" :class="ErrorData && 'ErrorBorder'" class="CookiesInputInside InputColor color2" type="string" name="Name" :placeholder="Name" autocomplete="off">
  <div style="pointer-events: none;" class="P-Abs D-Flex Inset0 W-100 H-100">
    <div style="pointer-events: none;" class="M-Auto">
    </div>
    <div @click="RemoveSelection" v-if="Selected !== ''" class="HoverGlow" style="pointer-events: auto; width: 3em; margin: auto 0; opacity: 0.8;" :style="Disabled && 'pointer-events: none;'">
      <CookiesIcon style="width:2em; height: 2em;" :ID="'Removed'"></CookiesIcon>
    </div>
  </div>
  <div v-if="NameOnFocus && !NewValue" class="CookiesInputSearch">
    <div tabindex="0" @blur="DelayDefocus()" @keydown="KeyPressed(element, i)" :ref="Res + '_' + i" v-for="(element, i) in Options" :key="(element, i)" @click="SelectElement(element)" :style="[SearchInput(element), {height: RowHeight}]" class="CookiesInputSearchElement">
      <div v-if="Icon !== ''" class="ImageList" :style="[{backgroundImage: ('url(' + element[Icon] +'), url(' + DefautlImage +')')}, {width: RowHeight}, {height: RowHeight}]"></div>
      <div v-for="(name, Index) in SelectorsFixed" :key="(name, Index)" :class="Index === 0 && 'F-Bold'" :style=" $store.getters.Direction === 'ltr' ? 'text-align:left; padding-left:1em;' : 'text-align:right; padding-right:1em;'" class=" M-Auto W-100">
        <span class="colorFourth" v-if="Index !== 0">{{NameHandler(name)}}: </span><span>{{DeepFetcher(element, name)}}</span> <span>{{name.toString().toLowerCase().includes('cost') || name.toString().toLowerCase().includes('price') ? PriceUnit : ''}}</span>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    Width: { default: '', type: String },
    Height: { default: '', type: String },
    RowHeight: { default: '2em', type: String },
    Default: { default: '' },
    Background: { default: '', type: String },
    Options: { default: [] },
    Selectors: { default: ['Name'] },
    Icon: { default: '', type: String },
    Res: { type: String },
    Disabled: { default: false, type: Boolean },
    Radio: { default: false, type: Boolean },
    Name: { default: '', type: String },
    ErrorData: { default: false },
    PreserveMemory: { default: false }
  },
  emits: {
    selected: null,
    changed: null,
    removed: null
  },
  data () {
    return {
      NameOnFocus: false,
      NewValue: false,
      Selected: '',
      SelectedObj: {},
      DefautlImage: require('@/assets/defaults/DefaultImage.png')
    }
  },
  mounted () {
    if (this.MEMObjValue !== this.SelectedObj) {
      this.SelectedObj = this.MEMObjValue
      this.Selected = this.DeepFetcher(this.MEMObjValue, this.SelectorsFixed[0])
      this.MEMWrite(this.Res, this.Selected)
      // this.$emit('selected', this.SelectedObj)
    }
  },
  methods: {
    DelayDefocus () {
      if ((this.$refs[this.Res]).matches(':focus-within:not(:focus)')) {
        return
      }
      setTimeout(() => {
        if (this.$store.state.CookiesMemory[this.Res] === '') {
          this.Selected = ''
          this.SelectedObj = {}
          this.$emit('removed', '')
          this.MEMWrite(this.Res + '_Value', undefined)
        } else {
          var TextSelect = this.ObjectFinder(this.Options, this.Selectors[0], this.$store.state.CookiesMemory[this.Res])
          if (TextSelect) {
            this.$store.state.CookiesMemory[this.Res] = this.DeepFetcher(TextSelect, this.SelectorsFixed[0])
            this.$store.state.CookiesMemory[this.Res + '_Value'] = TextSelect
          } else {
            this.$store.state.CookiesMemory[this.Res] = this.Selected
            this.$store.state.CookiesMemory[this.Res + '_Value'] = this.SelectedObj
          }
        }
        this.NameOnFocus = false
      }, 250)
    },
    SelectElement (element) {
      this.$store.state.CookiesMemory[this.Res] = this.DeepFetcher(element, this.SelectorsFixed[0])
      this.Selected = this.$store.state.CookiesMemory[this.Res]
      this.SelectedObj = element
      this.NameOnFocus = false
      this.$emit('selected', element)
      this.MEMWrite(this.Res + '_Value', element)
    },
    SearchInput (item) {
      var SearchLow = this.$store.state.CookiesMemory[this.Res].toString().toLowerCase()
      // var itemLow = item.toString().toLowerCase()
      // if (itemLow.search(this.$store.state.CookiesMemory[this.Res].toString().toLowerCase()) === -1) {
      //   return { display: 'none' }
      // }
      // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
      if (SearchLow && SearchLow !== '') {
        var SearchIt = this.CookiesSearcher(item, SearchLow)
      } else {
        SearchIt = true
      }
      // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      if (SearchIt) {
        return { display: '' }
      } else {
        return { display: 'none' }
      }
    },
    InputChanged () {
      this.$emit('changed')
    },
    focused () {
      this.NameOnFocus = true
      this.Console('NameOnFocus')
      this.Console(this.$store.state.CookiesMemory[this.Res])
      this.Selected = this.$store.state.CookiesMemory[this.Res]
    },
    KeyPressed (element, i) {
      if ((event.which) === 27) { // esc
        this.$refs[this.Res + '_' + i].blur()
      } else if ((event.which) === 32 || (event.which) === 13) { // space + enter
        this.SelectElement(element)
      } else if ((event.which) === 40) { // down
        try {
          this.$refs[this.Res + '_' + (i + 1)].focus()
        } catch (error) {
        }
      } else if ((event.which) === 38) { // up
        try {
          this.$refs[this.Res + '_' + (i - 1)].focus()
        } catch (error) {
        }
      }
    },
    RemoveSelection () {
      this.$store.state.CookiesMemory[this.Res] = ''
      this.DelayDefocus()
    },
    NameHandler (value) {
      if (typeof value === 'string') {
        return this.Translate(value)
      } else {
        return this.Translate(value[value.length - 1])
      }
    }
  },
  computed: {
    ALLOptions () {
      if (this.Radio) {
        return this.Options
      }
      return [{ [this.Selectors[0]]: 'All' }].concat(this.Options)
    },
    FilteredOptions () {
      var SearchLow = this.$store.state.CookiesMemory[this.Res].toString().toLowerCase()
      if (SearchLow && SearchLow !== '') {
        return this.Options.filter(element => {
          this.CookiesSearcher(element, SearchLow)
        })
      } else {
        return this.Options
      }
    },
    StyledOut () {
      return {
        width: this.Width,
        height: this.Height,
        background: this.Background
      }
    },
    SelectorsFixed () {
      var StringFixer = this.PathFixer(this.Selectors) // handle single string prop into single array |||| 'prop' => ['prop']
      if ((typeof StringFixer[0]) === 'string') {
        return [StringFixer]
      } else {
        var Fixed = StringFixer.map(function (item) { // handle multiple prop for multi selector strings into arrays arrays |||| [['selector1'], ['selector2a', 'selector2b']] => [['selector1'], ['selector2a', 'selector2b']]
          if (typeof (item) === 'string') {
            return [item]
          }
          return item
        })
        return Fixed
      }
    },
    Resource () {
      return this.MEMRead(this.Res)
    },
    MEMValue () {
      return this.MEMRead(this.Res)
    },
    MEMObjValue () {
      return this.MEMRead(this.Res + '_Value')
    }
  },
  watch: {
    MEMValue: function (newVal, oldVal) { // watch it
      if (this.MEMValue === '') {
        this.Selected = ''
        this.SelectedObj = {}
      }
    },
    MEMObjValue: function (newVal, oldVal) { // watch it
      if (this.MEMObjValue !== this.SelectedObj && this.MEMObjValue !== undefined) {
        this.SelectedObj = this.MEMObjValue
        this.Selected = this.DeepFetcher(this.MEMObjValue, this.SelectorsFixed[0])
        this.MEMWrite(this.Res, this.Selected)
        this.$emit('selected', this.SelectedObj)
      }
    }
  },
  unmounted () {
    if (!this.PreserveMemory) {
      this.MEMWrite(this.Res, undefined)
      this.MEMWrite(this.Res + '_Value', undefined)
    }
  }
}
</script>

<style scoped>
.CookiesInputInside{
  width: -webkit-fill-available;
  border: 1px solid rgba(120,120,120,0);
  border-radius: var(--rad2);
  background: v-bind('$store.getters.Theme.InputColor');
  flex: auto;
  padding: 0.7em 1.2em;
  outline: none;
  transition-property: all;
  transition-duration: 0.4s;
  text-align: v-bind('$store.getters.DefaultTextAlign');
  direction: auto;
}
.CookiesInputInside:focus{
  outline: 1px solid v-bind('$store.getters.Theme.UserColors.third_50') !important;
}
.CookiesInputInside{
  outline: 1px solid transparent;
}
.CookiesInputInside::placeholder{
    opacity: 0.25;
    color: v-bind('$store.getters.Theme.color5');
}
.CookiesInputSearch{
  background: v-bind('$store.getters.Theme.BG3Fixed');
  width: 100%;
  max-height: 300px;
  z-index: 10;
  position: absolute;
  border-radius: var(--rad2);
  filter: drop-shadow(var(--shadow1));
  margin-top: 5px;
  overflow-y: scroll;
}
.CookiesInputSearchElement{
  cursor: pointer;
  background: v-bind('$store.getters.Theme.BG3');
  font-size: 0.85em;
  animation-name: Animation1;
  animation-duration: 0.5s;
  transition-property: all;
  transition-duration: 0.4s;
  margin: auto;
  padding: 5px;
  margin: 5px;
  border-bottom: solid 1px v-bind('$store.getters.Theme.Shade1');
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 150;
}

.CookiesInputSearchElement:hover{
  font-size: 0.9em;
  background: v-bind('$store.getters.Theme.Shade1');
  border-radius: var(--rad2);
}
.CookiesInputSearchElement:focus{
  outline: none;
  font-size: 0.9em;
  background: v-bind('$store.getters.Theme.Shade1');
  border-radius: var(--rad2);
}
@keyframes Animation1 {
  from {height: 0px; color: rgba(255, 255, 255, 0);}
  to {}
}
.CookiesInputHolder{
  position: relative;
  border: 1px solid rgba(120,120,120,0);
  border-radius: var(--rad2);
  flex: auto;
  outline: none;
  margin: 0.5em;
  transition-property: all;
  transition-duration: 0.4s;
  text-align: var(--7ba5bd90-_store_getters_DefaultTextAlign);
}
.ImageList{
  float:left;
  border-radius:var(--rad3);
  background-position: center;
  background-size: cover;
  margin-right: 1em;
}
</style>
