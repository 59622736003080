<template>
<div style="border-radius:0.5em; width: 100%;">
  <div v-if="MultiLevel" style="margin-bottom: 20px;" class="D-Flex NoPrint">
    <CookiesCheckBox style="padding: 0.1em 0.5em; border-radius: 0.5em; margin-right: 0.5em;" class="M-Auto BG2" :Name="'Multi Level'" :Default="true" :Type="'Switch'" :Res="'Table_Array_Hider'"></CookiesCheckBox>
  </div>
  <div v-if="Header" class="W-100 D-Flex"><span contenteditable style="margin-bottom: 0.5em;" class="M-Auto F1_5em F-Bold">{{TableName}}</span></div>
  <table class="Cookies_Table1 W-100 TableBG TablePrint" cellpading="0" cellspacing="0" style="border-collapse: collapse">
    <tr :class="PrintControls ? '' : 'D-None'" class="noprint PRINTROW ReportTable" style="background:none;">
      <th class="printtoggle ReportTable" style="background:none;" v-for="field in Fields" :key="field"><div :class="PrintStatus[field.name] && 'noprint'" @click="ChangePrintState(field.name)" v-if="field.print !== false" ><CookiesIcon style="width: 1.3em; height: 1.3em;" :ID="'Printer'"></CookiesIcon></div></th>
    </tr>
    <tr class="ReportTable" :class="SortingNow ? 'Disabled' : ''">
      <CookiesSortingTH class="ReportTable" v-for="field in Fields" :key="field" :class="[PrintStatus[field.name] && 'noprint', field.selector && 'NOPRINT',field.sort && 'sotrableheader']" @click="field.sort && SortTable(field.value)" :Sortable="field.sort ? true : false" :Name="!field.noheader ? field.name : ''" :Value="field.value" :sortColumn="sortColumn" ></CookiesSortingTH>
    </tr>
    <tr class="ReportTable" v-if="Loading"><td class="O-50 tdFull" :colspan="Fields.length"><div class="EvenContainer"><CookiesLoading class="M-Auto"></CookiesLoading></div></td></tr>
    <tr class="ReportTable" v-for="(element, index) in SortedData" :key="(element, index)" :class="[RowSelectCheck(element) ? '' : ' O-25 noprint ', Loading ? 'Disabled': '']">
      <td @click="field.Phone && OpenWhatsapp(DeepFetcher(element, field.second))" style="height: inherit;" class="ReportTable" :class="[field.bold && 'F-Bold', field.class, PrintStatus[field.name] && 'noprint', field.selector && 'NOPRINT', field.Phone && 'Pointer', field.type === 'editableText' ? 'EditableCell': '', HiglightingRows && RowFinder(element)]" v-for="field in Fields" :key="field" @blur="field.type === 'editableText' ? TextChanged(DeepFetcher(element, field.res)) : ''" :contenteditable="field.type === 'editableText' ? true: false">
        <span v-if="field.Phone"><img class="O-75 noprint" style="height: 0.8em; width: 0.8em;" src="@/assets/icons/Phone.svg"></span>
        <span v-if="field.type === 'index'">{{index + 1}}</span>
        <span v-if="field.type === null || field.type === undefined || field.type === '' || field.type === 'string' || field.type === 'text'" style="overflow-wrap: normal;">{{DeepFetcher(element, field.value)}}</span>
        <span v-if="(field.type === 'number' || field.number)" style="overflow-wrap: normal">{{DecimalFixer(DeepFetcher(element, field.value))}}</span>
        <span v-if="field.type === 'date'">{{JsonDateParser(DeepFetcher(element, field.value))}}</span>
        <span v-if="field.type === 'time'">{{JsonTimeParser(DeepFetcher(element, field.value))}}</span>
        <span v-if="field.type === 'datetime'">{{JsonDateParser(DeepFetcher(element, field.value)) + ' | ' + JsonTimeParser(DeepFetcher(element, field.value))}}</span>
        <span v-if="field.type === 'numberInput'"><CookiesNumberInput style="font-size: 0.95em;" :Min="field.min !== undefined ? typeof field.min === 'number'? field.min : DeepFetcher(element, field.min) : -99999999" :Max="field.max !== undefined ? typeof field.max === 'number'? field.max : DeepFetcher(element, field.max) : 99999999" :Unit="field.InputUnit" :Step="field.step" :Default="DeepFetcher(element, field.default) ? DeepFetcher(element, field.default) : 0" :Res="DeepFetcher(element, field.res)" :PreserveMemory="false"></CookiesNumberInput></span>
        <span v-if="field.type === 'delete'"><CookiesIcon @click="$emit('DeleteRow', element)" :Tooltip="Translate('Delete')" :title="Translate('Delete')" class="M-Auto Pointer" style="width: 1.5em; height: 1.5em;" :ID="'Delete'"></CookiesIcon></span>
        <span @vnodeMounted="SaveToMemory(DeepFetcher(element, field.res), DeepFetcher(element, field.value))" v-if="field.type === 'editableText'" :ref="'editableText_' + DeepFetcher(element, field.res)" class="" style="overflow-wrap: normal;" v-text="$store.state.CookiesMemory[DeepFetcher(element, field.res)]"></span>
        <div class="ItemLister" v-if="field.type === 'array' & $store.state.CookiesMemory.Table_Array_Hider">
          <span class="M-Auto" v-for="item in DeepFetcher(element, field.list)" :key="item">
            <span v-if="!field.number">{{DeepFetcher(item, field.value)}}</span>
            <span v-if="field.type === 'index'">{{index + 1}}</span>
            <span v-if="field.type === null || field.type === undefined || field.type === '' || field.type === 'string'" style="overflow-wrap: normal;">{{DeepFetcher(item, field.value)}}</span>
            <span v-if="(field.type === 'number' || field.number)" :class="field.coloredNum && DeepFetcher(item, field.value) > 0 ? 'colorg2': DeepFetcher(item, field.value) < 0 ? 'colorr2': ''">{{DecimalFixer(DeepFetcher(item, field.value))}}</span>
            <span v-if="field.type === 'date'">{{JsonDateParser(DeepFetcher(item, field.value))}}</span>
            <span v-if="field.type === 'time'">{{JsonTimeParser(DeepFetcher(item, field.value))}}</span>
            <span v-if="field.type === 'datetime'">{{JsonDateParser(DeepFetcher(item, field.value)) + ' | ' + JsonTimeParser(DeepFetcher(item, field.value))}}</span>
            <span v-if="field.pricetag === true">{{' ' + PriceUnit}}</span>
            <span v-if="field.unit">{{' ' + field.unit}}</span>
            <span v-if="field.second" class="F-Bold F_75em"> {{'('}}<span v-if="field.secondID">#</span><span v-if="field.secondPhone"><img class="O-75 noprint" style="height: 0.8em; width: 0.8em;" src="@/assets/icons/Phone.svg"></span>{{DeepFetcher(item, field.second)}}<span v-if="field.pricetag === true">{{' ' + PriceUnit}}</span><span v-if="field.unit">{{' ' + field.unit}}</span>{{')'}}</span>
          </span>
        </div>
        <CookiesCheckBox v-if="field.selector" :Res="field.Res + element[field.Res_ID]" :Default="field.DefaultValue"></CookiesCheckBox>
        <div class="ItemLister" v-if="field.type === 'array' & !$store.state.CookiesMemory.Table_Array_Hider">
          <span>Total of({{DeepFetcher(element, field.list).length}})</span>
        </div>
        <slot :field="field" :element="element"></slot>
        <span v-if="field.pricetag === true & field.type !== 'array'">{{' ' + PriceUnit}}</span>
        <span v-if="field.unit !== undefined & field.type !== 'array'">{{' ' + field.unit}}</span>
        <span :class="field.secondPhone && 'Pointer'" @click="field.secondPhone && OpenWhatsapp(DeepFetcher(element, field.second))" v-if="field.second !== undefined & field.type !== 'array'" class="F-Bold F_75em"> {{' ('}}<span v-if="field.secondID">#</span><span v-if="field.secondPhone"><img class="O-75 noprint" style="height: 0.8em; width: 0.8em;" src="@/assets/icons/Phone.svg"></span>{{DeepFetcher(element, field.second)}}<span v-if="field.pricetag === true">{{' ' + PriceUnit}}</span><span v-if="field.unit">{{' ' + field.unit}}</span>{{')'}}</span>
      </td>
    </tr>
    <tr class="ReportTable" v-if="SortedData.length === 0 & !Loading"><td class="O-50 tdFull" :colspan="Fields.length">{{LanguageSelector('No Data Avilable', 'لا يوجد اي معلومات')}}</td></tr>
    <tr :class="!Footer && 'NOPRINT D-None'" class="ReportTable TableFooter" v-if="SortedData.length > 0 & Footer">
      <td :class="[field.bold && 'F-Bold', field.class, PrintStatus[field.name] && 'noprint', field.selector && 'NOPRINT']" v-for="field in Fields" :key="field" class="TableFooter ReportTable" style="border:none">
        <div v-if="field.total">
          <span>{{LanguageSelector('Total (', 'المجموع (') + DecimalFixer(TotalOF(field.value)) + ')'}}</span>
          <span v-if="field.pricetag === true">{{' ' + PriceUnit}}</span>
          <span v-if="field.unit">{{' ' + field.unit}}</span>
        </div>
      </td>
    </tr>
  </table>
</div>
</template>

<script>
export default {
  props: {
    CookiesID: { default: '' },
    Data: { default: [], Type: Array },
    Fields: { default: [], Type: Array },
    Search: { default: '' },
    Decimals: { default: 3 },
    TableName: { default: '', Type: String },
    MultiLevel: { default: false, Type: Boolean },
    DefaultSort: { default: { name: ['Date'], value: 1 }, Type: Object },
    SaveClientSettings: { default: true, Type: Boolean },
    Footer: { default: true, Type: Boolean },
    Header: { default: true, Type: Boolean },
    PrintControls: { default: true, Type: Boolean },
    Loading: { default: false, Type: Boolean },
    RowHighliter: { default: {} }
  },
  data () {
    return {
      PrintStatus: {},
      SortingNow: { default: false, Type: Boolean },
      sortColumn: {
        name: ['Date'],
        value: 1
      },
      HiglightingRows: true
    }
  },
  mounted () {
    this.SortingNow = false
    this.ApplyTableSettings()
  },
  computed: {
    SortedData () {
      var Sorted = this.SearchedData
      return Sorted.sort(this.Sorting)
    },
    DirectionalStyler () {
      if (this.$store.getters.Direction === 'ltr') {
        return {
          SRFD: '0.4em',
          SRLD: '0em',
          RightBorder: '0.05em',
          LeftBorder: '0px'
        }
      } else {
        return {
          SRFD: '0em',
          SRLD: '0.4em',
          RightBorder: '0px',
          LeftBorder: '0.05em'
        }
      }
    },
    SearchedData () {
      // var modifiedSearch = this.Search.replace('#', '')
      var SearchResult = []
      if (this.Search && this.Search !== '') {
        this.IDFixedData.forEach(element => {
          if (this.CookiesSearcher(element, this.Search)) {
            SearchResult.push(element)
          }
        })
        return SearchResult
      } else {
        return this.IDFixedData
      }
    },
    IDFixedData () {
      var NewData = []
      this.Data.forEach(element => {
        NewData.push(this.IDFixer(element))
      })
      return NewData
    }
  },
  methods: {
    ChangePrintState (name) {
      this.PrintStatus[name] = !this.PrintStatus[name]
      this.LocalSave(this.CookiesID + '_TablePrint', this.PrintStatus)
    },
    SortTable (name) {
      this.SortingNow = true
      if (JSON.stringify(this.sortColumn.name) === JSON.stringify(name)) {
        this.sortColumn.value = this.sortColumn.value * -1
      } else {
        this.sortColumn.name = this.DeepCloner(name)
      }
      this.SortingNow = false
      this.LocalSave(this.CookiesID + '_TableSort', this.sortColumn)
    },
    TotalOF (name) {
      var TOTAL = 0
      this.SortedData.forEach(element => {
        TOTAL += this.DeepFetcher(element, name)
      })
      return TOTAL
    },
    Sorting (a, b) {
      var SortElement = this.sortColumn.name
      var SortDirection = this.sortColumn.value
      try {
        if (this.DeepFetcher(a, SortElement) > this.DeepFetcher(b, SortElement)) {
          return SortDirection
        }
        if (this.DeepFetcher(a, SortElement) < this.DeepFetcher(b, SortElement)) {
          return SortDirection * -1
        }
        return 0
      } catch (error) {
        return 0
      }
    },
    // CookiesSearcher (obj, search) {
    //   const _this = this
    //   return Object.keys(obj).some(function (attr) {
    //     if (((Array.isArray(obj[attr])) || (typeof obj[attr] === 'object')) && (obj[attr] !== null)) {
    //       return _this.CookiesSearcher(obj[attr], search)
    //     }
    //     if (obj[attr] !== null) {
    //       return obj[attr].toString().toLowerCase().includes(search.trim().toLowerCase())
    //     }
    //     return false
    //   })
    // },
    IDFixer (Obj) {
      this.Fields.forEach(field => {
        if (field.id !== undefined & field.id) {
          this.DeepChanger(Obj, field.value, ('#' + this.DeepFetcher(Obj, field.value)))
        }
        // if (field.secondID) {
        //   this.DeepChanger(Obj, field.second, ('#' + this.DeepFetcher(Obj, field.second)))
        // }
      })
      return Obj
    },
    ApplyTableSettings () {
      if (this.CookiesID !== '' & this.SaveClientSettings) {
        var TableSort = this.LocalRead(this.CookiesID + '_TableSort')
        var TablePrint = this.LocalRead(this.CookiesID + '_TablePrint')
        if (TableSort !== null) {
          this.sortColumn = TableSort
        } else {
          this.sortColumn = this.DefaultSort
        }
        if (TablePrint !== null & this.PrintControls) {
          this.PrintStatus = TablePrint
        } else {
          this.Fields.forEach((element) => {
            this.PrintStatus[element.name] = false
          })
        }
      } else {
        this.sortColumn = this.DefaultSort
        this.Fields.forEach((element) => {
          this.PrintStatus[element.name] = false
        })
      }
    },
    RowSelectCheck (element) {
      try {
        var Selectorfield = this.ObjectFinder(this.Fields, ['selector'], true)
        if (Selectorfield === undefined || Selectorfield === null) {
          return true
        }
        return this.MEMRead(Selectorfield.Res + element[Selectorfield.Res_ID])
      } catch (error) {
        return true
      }
    },
    SaveToMemory (Res, Path) {
      this.MEMWrite(Res, Path)
    },
    TextChanged (Res) {
      try {
        const Ref = this.$refs['editableText_' + Res]
        this.MEMWrite(Res, Ref.innerHTML.trim())
      } catch (error) {
      }
    },
    RowFinder (element) {
      try {
        if (this.RowHighliter.Rows.includes(this.DeepFetcher(element, this.RowHighliter.Value))) {
          return 'Highlighted'
        } else {
          return ''
        }
      } catch (error) {
        return ''
      }
    }
  },
  watch: {
    DefaultSort: function (newVal, oldVal) { // watch it
      this.ApplyTableSettings()
    }
  }
}
</script>
<style scoped>
.printtoggle{
  padding: 0;
  text-align: center;
  vertical-align: bottom;
}
.printtoggle > div{
  background-color: v-bind('$store.getters.Theme.Light1');
  width: 1.2em;
  margin: auto auto 0 auto;
  padding: 0.3em 1.7em 0 1.7em;
  border-radius: 0.3em 0.3em 0 0;
  cursor: pointer;
  vertical-align: bottom;
  position: relative;
}
.printtoggle > div.noprint{
  opacity: 0.2;
}
.printtoggle >>> img{
  width:1em;
}
.TableBG > tr > td{
  border-right-style: solid;
  border-right-width: v-bind('DirectionalStyler.RightBorder');
  border-right-color: v-bind('$store.getters.Theme.BG1');
  border-left-style: solid;
  border-left-width: v-bind('DirectionalStyler.LeftBorder');
  border-left-color: v-bind('$store.getters.Theme.BG1');
}
td.noprint, th.noprint{
  opacity: 0.6;
  background: v-bind('CurrentBackgroundColor');
  background-attachment: fixed ;
}
td.noprint> *, th.noprint> *{
  opacity: 0.2 !important;
}
.TableBG > tr{
  position: relative;
  height: 2em;
}
.TableBG > tr.PRINTROW::before{
  background: none;
}
.TableBG > tr::before{
  background-color: v-bind('$store.getters.Theme.BG2');
  content: '';
  width: 100%;
  /* height: 100%; */
  position: absolute;
  left: 0;
  z-index: -5;
}
.TableBG > tr:nth-child(2):before{
  border-top-left-radius: var(--rad2);
}
.TableBG > tr:nth-child(2):before{
  border-top-right-radius: var(--rad2);
}
.TableBG > tr:last-child:before{
  border-bottom-left-radius: var(--rad2);
}
.TableBG > tr:last-child:before{
  border-bottom-right-radius: var(--rad2);
}
.TableBG > tr:nth-child(2){
  border-top-left-radius: v-bind('DirectionalStyler.SRFD');
}
.TableBG > tr:nth-child(2){
  border-top-right-radius: v-bind('DirectionalStyler.SRLD');
}
.TableBG > tr:nth-child(2) > th:first-child{
  border-top-left-radius: v-bind('DirectionalStyler.SRFD');
  border-top-right-radius: v-bind('DirectionalStyler.SRLD');
}
.TableBG > tr:nth-child(2) > th:last-child{
  border-top-right-radius: v-bind('DirectionalStyler.SRFD');
  border-top-left-radius: v-bind('DirectionalStyler.SRLD');
  border-left: v-bind('DirectionalStyler.LeftBorder');
  border-Right: v-bind('DirectionalStyler.Right');
}
.TableBG > tr:last-child > td:first-child{
  border-bottom-left-radius: v-bind('DirectionalStyler.SRFD');
  border-bottom-right-radius: v-bind('DirectionalStyler.SRLD');
}
.TableBG > tr:last-child > td:last-child{
  border-bottom-left-radius: v-bind('DirectionalStyler.SRLD');
  border-bottom-right-radius: v-bind('DirectionalStyler.SRFD');
  border-left: v-bind('DirectionalStyler.LeftBorder');
  border-Right: v-bind('DirectionalStyler.Right');
}
.TableBG > tr:last-child > td.tdFull{
  border-bottom-left-radius: var(--rad2) !important;
  border-bottom-right-radius: var(--rad2) !important;
}
.TableFooter{
  background-color: v-bind('$store.getters.Theme.BG6');
}
table.TableBG > tr:nth-child(odd){
    background-color: v-bind('$store.getters.Theme.BG2');
}
table.TableBG > tr:nth-child(even){
    background-color: v-bind('$store.getters.Theme.BG4');
}
.ItemLister{
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: wrap;
}
.ItemLister > *{
  border-top: solid 0.2px var(--shade2);
  width: 100%;
}
.ItemLister > *:first-child{
  border-top: none;
}
.sotrableheader{
  position: relative;
}
.sotrableheader:hover::after{
  content: '';
  background: v-bind('$store.getters.Theme.BG2');
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  z-index: 1;
  opacity: 0.5;
}
.Highlighted{
  animation: RowHighlight 1.2s ease-in-out;
}
@keyframes RowHighlight {
  0%{}
  30%{
    background-color: v-bind('$store.getters.Theme.UserColors.main');
  }
  50%{
    background-color: v-bind('$store.getters.Theme.UserColors.main');
  }
  100%{}
}
</style>
