<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <div class="CookiesRow" style="direction: ltr;">
        <div class="CookiesRow W-100"><span class="M-Auto"><span v-if="['RemoveIssue'].includes(DialogData.NewStatus)">{{LanguageSelector('Remove Issue', 'حذف التنبيه')}}</span><span v-if="['HasIssue'].includes(DialogData.NewStatus)">{{LanguageSelector('Add Issue on ', 'وضع تنبيه على ')}}</span><span v-if="!DriverNeeded & !['HasIssue', 'RemoveIssue'].includes(DialogData.NewStatus)" class="">{{LanguageSelector('Changing Status of', 'تعديل') + '  '}}</span><span v-if="DriverNeeded & !['HasIssue', 'RemoveIssue'].includes(DialogData.NewStatus)" class="">{{LanguageSelector('Changing Status of', 'تنزيل') + '  '}}</span><strong v-if="!['RemoveIssue'].includes(DialogData.NewStatus)" class="F1_25em">({{DialogData.OrdersList.length}})</strong><span v-if="!['RemoveIssue'].includes(DialogData.NewStatus)">{{'  ' + LanguageSelector('orders', 'طلب')}}</span></span></div>
        <div class="CookiesRow M-Auto" style="padding: 1em 0em;">
          <CookiesIcon v-if="!DriverNeeded & !['HasIssue', 'RemoveIssue'].includes(DialogData.NewStatus)" :ToolTip="DialogData.OldStatus === 'OnDelivery' ? 'On Delivery': DialogData.OldStatus" class="CookiesRowIcon F1_5em" :ID="DialogData.OldStatus"></CookiesIcon>
          <CookiesIcon v-if="!DriverNeeded & !['HasIssue', 'RemoveIssue'].includes(DialogData.NewStatus)" style="margin: auto 0.5em;" class="CookiesRowIcon" :ID="'Arrow'"></CookiesIcon>
          <CookiesIcon :class="DriverNeeded ? 'F3em' : ''" :ToolTip="DialogData.NewStatus === 'OnDelivery' ? 'On Delivery': DialogData.NewStatus" class="CookiesRowIcon F1_5em" :ID="DialogData.NewStatus"></CookiesIcon>
        </div>
        <div v-if="DriverNeeded" class="CookiesRowSplitter W-100"></div>
        <div :class="SelectedDriver !== '' && 'D-None'" v-if="DriverNeeded" class="D-Flex W-100" style="padding: 0.5em 3em;">
          <CookiesDropInputObject :ErrorData="ErrorsVisibilty" @selected="DriverSelected" class="M-Auto" :Res="'ActionDriver'" :Width="'6em'" :RowHeight="'2.3em'" :Options="CookiesDataDrivers" :Selectors="[['Employee', 'Name'], ['Employee', 'PhoneNumber']]" :Name="LanguageSelector('Select Driver', 'اختر السائق')"></CookiesDropInputObject>
        </div>
        <div v-if="DriverNeeded" class="W-100 P-Rel DefaultDirection" style="padding: 0.5em 1em;">
          <span v-if="SelectedDriver === ''" class="M-Auto O-50">{{LanguageSelector('Please select a driver', 'الرجاء اختيار سائق')}}</span>
          <template v-else>
            <div class="W-100"><span class="M-Auto F-Bold">{{DeepFetcher(SelectedDriver, ['Employee', 'Name'])}}</span></div>
            <div class="W-100"><span class="M-Auto F-Bold">{{DeepFetcher(SelectedDriver, ['Employee', 'PhoneNumber'])}}</span></div>
            <div class="W-100"><span class="M-Auto F-Bold">|| {{DeepFetcher(SelectedDriver, ['Employee', 'BarCode'])}} ||</span></div>
          </template>
          <CookiesIcon v-if="SelectedDriver !== ''" @click="(()=> {$store.state.CookiesMemory.ActionDriver = '', SelectedDriver = ''})" class="P-Abs HoverGlow" style="right: 0; top: 0; width: 1.75em; height: 100%;" :ID="'Delete'"></CookiesIcon>
        </div>
        <div v-if="DialogData.NewStatus === 'Returned'" class="CookiesRowSplitter W-100"></div>
        <div v-if="DialogData.NewStatus === 'Returned'" class="W-100 P-Rel DefaultDirection" style="padding: 0.5em 1em;">
          <CookiesNumberInput style="padding: 0.2em;" :MinWidth="'2em'" :Default="1" :Min="1" :Name="LanguageSelector('Number of Returned items', 'عدد المرتجعات')" :Res="'ActionQuantity'"></CookiesNumberInput>
          <CookiesNumberInput style="padding: 0.2em;" :MinWidth="'1em'" :Default="0" :Min="0" :Max="DialogData.AmountTotal" :Unit="$store.state.CookiesConfig.PriceUnit" :Name="LanguageSelector('Value of Returned items', 'قيمة المرتجعات')" :Res="'ActionValue'"></CookiesNumberInput>
        </div>
        <div v-if="DialogData.NewStatus === 'Delayed'" class="CookiesRowSplitter W-100 "></div>
        <div v-if="DialogData.NewStatus === 'Delayed'" class="W-100 P-Rel DefaultDirection" style="padding: 0.5em 1em;">
          <input :min="DateMin" :class="SelectedDate === '' & ErrorsVisibilty ? 'ErrorBorder' : ''" class="CookiesInput" type="Date" v-model="SelectedDate">
        </div>
        <div v-if="['Redirected'].includes(DialogData.NewStatus)" class="W-100 P-Rel DefaultDirection D-Flex" style="padding: 0.5em 1em; flex-direction: column; text-align: left;">
          <div class="CookiesRowSplitter"></div>
          <div class="D-Flex" style="padding: 0.5em 0.5em;">
            <CookiesDropInputObject :ErrorData="ErrorsVisibilty & MEMRead('RedirectOrderArea') === '' || SelectedArea.ID === DialogData.AreaID"  @selected="AreaSelected" class="M-Auto" :Res="'RedirectOrderArea'" :Width="'6em'" :RowHeight="'2.3em'" :Options="CookiesDataAreas" :Selectors="['Name']" :Name="LanguageSelector('Select Area', 'اختر منطقة')"></CookiesDropInputObject>
          </div>
        </div>
        <div v-if="['Redirected', 'Canceled'].includes(DialogData.NewStatus)" class="W-100 P-Rel DefaultDirection D-Flex" style="padding: 0.5em 1em; flex-direction: column; text-align: left;">
          <div class="CookiesRowSplitter"></div>
          <div class="W-100 D-Flex">
            <span class="M-Auto F-Bold">{{LanguageSelector('Extra Fees On: ', 'احتساب القيمة الاضافيه على: ')}}</span>
          </div>
          <div class="CookiesRowSpacer"></div>
          <div :class="$store.state.CookiesMemory.RedirectPayee === '' & ErrorsVisibilty ? 'ErrorBorder' : ''" style="border-radius: var(--rad3);" class="M-Auto DefaultDirection DefaultTextAlign">
            <CookiesRadioButton class="P_25em" :Name="LanguageSelector('Customer', 'حساب العميل')" :Value="1" :Group="'RedirectPayee'"></CookiesRadioButton>
            <CookiesRadioButton class="P_25em" :Name="LanguageSelector('Client', 'حساب الزبون')" :Value="2" :Group="'RedirectPayee'"></CookiesRadioButton>
            <CookiesRadioButton class="P_25em" :Name="LanguageSelector('No Extra Fees', 'لا احد')" :Value="0" :Group="'RedirectPayee'"></CookiesRadioButton>
          </div>
          <div class="CookiesRowSpacer"></div>
          <CookiesNumberInput v-if="MEMRead('RedirectPayee') !== 0" :MinWidth="'2em'" :Min="0" :Name="LanguageSelector('Extra Ammount', 'القيمة الاضافية')" :Unit="PriceUnit" :Default="DialogData.OlderAreaFees" :Res="'ActionExtraFees'"></CookiesNumberInput>
        </div>
        <div v-if="['HasIssue'].includes(DialogData.NewStatus)" class="CookiesRowSplitter W-100"></div>
      <div v-if="['HasIssue'].includes(DialogData.NewStatus)" class="CookiesRow W-75">
        <textarea v-model="ChangeStatusNote" class="CookiesCommentBox" name="Comments" :placeholder="LanguageSelector('Notes', 'ملاحظات')" autocomplete="off"></textarea>
      </div>
      </div>
    </div>
  </div>
</div>
<div class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'الغاء': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Cancel': ''}}</button>
    <button @click="ConfirmChange()" :style="'background: ' + $store.getters.Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'تأكيد': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Confirm': ''}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  created () {},
  data () {
    return {
      Name: '',
      ErrorsVisibilty: false,
      ChangeStatusNote: '',
      SelectedDriver: '',
      SelectedDate: '',
      SelectedArea: '',
      CookiesDataAreas: [],
      CookiesDataDrivers: [],
      DateMin: '',
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    ReadDrivers () {
      this.Get('Employee/getdriver').then(response => {
        if (response === null) {
          return
        }
        this.CookiesDataDrivers = response
      }, error => {
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadSubAreas () {
      this.Get('SubArea').then(response => {
        if (response === null) {
          return
        }
        this.CookiesDataAreas = response
      }, error => {
        error.CookiesError = 'Error in reading SubAreas'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ConfirmChange () {
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Console(this.DialogData.ApiUrl + this.DataToSend.DriverID + this.DataToSend.AreaID + this.DataToSend.Payee + this.DataToSend.Returned + this.DataToSend.HasIssue + this.DataToSend.NewDate, this.DataToSend.Orders)
      // var x = true
      // if (x) {
      //   return
      // }
      this.Saving = true
      this.Post('POST', this.DialogData.ApiUrl + this.DataToSend.DriverID + this.DataToSend.AreaID + this.DataToSend.Payee + this.DataToSend.Returned + this.DataToSend.HasIssue + this.DataToSend.NewDate, this.DataToSend.Orders).then(response => {
        this.Saving = false
        this.CloseDialog(this.DialogIndex)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in updating Orders Status'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DriverSelected (Driver) {
      this.SelectedDriver = Driver
    },
    AreaSelected (element) {
      this.SelectedArea = element
    }
  },
  mounted () {
    var TimeZoneDiff = (new Date()).getTimezoneOffset() * 60000
    this.DateMin = new Date(new Date().setHours(0, 0, 0, 0) - TimeZoneDiff).toISOString().replace(/T.*/, '').split('-').join('-')
    if (this.DriverNeeded) {
      this.ReadDrivers()
    }
    if (this.DialogData.NewStatus === 'Redirected') {
      this.ReadSubAreas()
    }
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    DataToSend () {
      var DataNow = {
        Orders: this.DialogData.OrdersList,
        NewStatus: this.DialogData.NewStatus,
        DriverID: '',
        NewDate: '',
        Returned: '',
        AreaID: '',
        HasIssue: '',
        Payee: ''
        // Note: this.ChangeStatusNote
      }
      if (this.DialogData.NewStatus === 'Returned') {
        DataNow.Returned = '&ReturnedItems=' + this.$store.state.CookiesMemory.ActionQuantity + '&ReturnedCash=' + this.$store.state.CookiesMemory.ActionValue
      }
      if (this.DriverNeeded) {
        DataNow.DriverID = '?Driver_ID=' + this.SelectedDriver.Id
      }
      if (this.DialogData.NewStatus === 'Redirected') {
        DataNow.AreaID = '&AreaID=' + this.SelectedArea.ID
      }
      if (this.DialogData.NewStatus === 'Canceled' || this.DialogData.NewStatus === 'Redirected') {
        DataNow.Payee = '&ExtraDelivery=' + this.$store.state.CookiesMemory.RedirectPayee
        if (this.MEMRead(['RedirectPayee']) !== 0) {
          DataNow.Payee += '&CollectedCash=' + this.MEMRead(['ActionExtraFees'])
        }
      }
      if (this.DialogData.NewStatus === 'Delayed') {
        DataNow.NewDate = '&ExpectedDelivery=' + this.SelectedDate
      }
      if (this.DialogData.NewStatus === 'HasIssue') {
        DataNow.HasIssue = '?HasIssue=1' + '&IssueNote=' + this.ChangeStatusNote
      }
      return DataNow
    },
    DialogError () {
      var DialogErrors = []
      this.SelectedDriver === '' & this.DriverNeeded && DialogErrors.push('Please Select Driver')
      this.SelectedDate === '' & this.DialogData.NewStatus === 'Delayed' && DialogErrors.push('Please Enter New Date')
      this.DialogData.NewStatus === 'Redirected' && this.$store.state.CookiesMemory.RedirectOrderArea === '' && this.SelectedArea.ID === this.DialogData.AreaID && DialogErrors.push('Please Select New Area')
      this.$store.state.CookiesMemory.RedirectPayee === '' & ['Redirected', 'Canceled'].includes(this.DialogData.NewStatus) && DialogErrors.push('Please Select Extra Fees Payee')
      return DialogErrors
    },
    DriverNeeded () {
      return (['AssignDriver'].indexOf(this.DialogData.NewStatus) >= 0)
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 800px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 10px 25px;
  outline: none;
  font-size: 17px;
  margin: 6px;
  position: relative;
}

.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}
</style>
