<template>
<div class="CookiesCardFill">
  <div class="M-Auto">
    <span style="font-Size: 30px; opacity: 0.2;">Empty Card</span>
  </div>
</div>
</template>

<script>
export default {
  created () {},
  data () {
    return {}
  },
  props: {},
  methods: {}
}
</script>

<style scoped>
.CookiesCardFill{
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  display: flex;
}
</style>
