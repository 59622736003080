
import { defineComponent } from 'vue'
import CookiesCoreMixin from '@/modules/CookiesCoreMixin.vue'
const __default__ = defineComponent({
  mixins: [CookiesCoreMixin],
  data () {
    return {
      Email: '',
      Password: '',
      ErrorsVisibilty: false,
      UserNameServerErrors: '',
      PassServerErrors: '',
      ServerError: ''
    }
  },
  mounted () {
    var savedEmail = this.LocalRead('CookiesUserName')
    this.Console('savedEmail')
    this.Console(savedEmail)
    if (savedEmail !== null) {
      this.Email = savedEmail
      this.MEMWrite('SignInRememberMe', true)
    }
    try {
      const SigninDialog = document.getElementById('CookiesSignDialog')
      const DialogPos = SigninDialog ? SigninDialog.offsetTop - 60 : 0
      window.scrollTo(0, DialogPos)
    } catch (error) {
    }
  },
  computed: {
    Errors () {
      var ERR = []
      if (!this.Email || this.Email === '') {
        ERR.push('Enter Email')
      }
      if (!this.Password || this.Password === '') {
        ERR.push('Enter Password')
      }
      return ERR
    },
    UserType () {
      return this.$store.state.UserManagment.CurrentUser.UserType
    }
  },
  methods: {
    SignIN () {
      const _this = this
      if (this.Errors.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.UserNameServerErrors = ''
      this.PassServerErrors = ''
      this.ErrorsVisibilty = false
      // ///testing only///////////////////
      // this.$store.state.UserManagment.CurrentUser.User_Type = 'ADMIN'
      // this.$store.state.UserManagment.CurrentUser.User_Name = 'Khaled'
      // this.$store.state.UserManagment.CurrentUser.User_Email = 'Mail@Mail.com'
      // this.$store.state.UserManagment.Status = true
      // if (this.$store.state.UserManagment.CurrentUser.User_Type === 'ADMIN') {
      //   this.$router.replace('/')
      // } else {
      //   this.$router.replace('/Sales')
      // }
      // ///////////////////////////////
      switch (this.AuthorizationVersion) {
        case 2: // JWT Tokken
          var RememberMe = this.$store.state.CookiesMemory.SignInRememberMe
          if (RememberMe) {
            this.LocalSave('CookiesUserName', this.Email)
          } else {
            this.LocalSave('CookiesUserName', null)
          }
          this.Post('POST', ['Login'], { UserName: this.Email, Password: this.Password, RememberMe: this.$store.state.CookiesMemory.SignInRememberMe }).then(response => {
            var ExpirationTime = new Date().getTime() + (this.BakingMinitues * 60 * 1000)
            if (RememberMe) {
              this.LocalSave('CookiesJWT', { value: this.DeepFetcher(response, 'accessToken'), exp: ExpirationTime })
              this.LocalSave('CookiesBAKERY', { value: this.DeepFetcher(response, 'refreshToken') })
            } else {
              this.SessionSave('CookiesJWT', { value: this.DeepFetcher(response, 'accessToken'), exp: ExpirationTime })
              this.SessionSave('CookiesBAKERY', { value: this.DeepFetcher(response, 'refreshToken') })
            }
            this.$emit('SignedIn')
          }, error => {
            this.Console('error3')
            this.Console(error)
            try {
              error.json()
                .then((CookiesResponse: any) => {
                  handleErrors(CookiesResponse)
                })
            } catch (errorinside) {
              handleErrors(error)
            }
            error.json()
              .then((CookiesResponse: any) => {
                handleErrors(CookiesResponse)
              })
            function handleErrors (errortobehandeled: any) {
              _this.Console('error')
              _this.Console(errortobehandeled)
              _this.Console(errortobehandeled.errors)
              var Errors = errortobehandeled.errors
              if (Errors.UserName !== undefined) {
                _this.UserNameServerErrors = Errors.UserName[0]
              }
              if (Errors.Password !== undefined) {
                _this.PassServerErrors = Errors.Password[0]
              }
              _this.ErrorsVisibilty = true
              _this.ServerError = error.Description
            }
          })
          break
        case 1: // Cookies
          this.Post('POST', ['Login'], { UserName: this.Email, Password: this.Password, RememberMe: this.$store.state.CookiesMemory.SignInRememberMe }).then(response => {
            this.$emit('SignedIn')
          }, error => {
            this.ErrorsVisibilty = true
            this.ServerError = error.Description
          })
          break
        default:
          break
      }
    }
  }
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "baa1ce70": (_ctx.$store.getters.Theme.BackBlur),
  "369e5d93": (_ctx.$store.getters.Theme.BG2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__