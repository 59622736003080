import { createApp } from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from './router'

// -- Styles -- //
import '@/style/CookiesGlobal.css'
import '@/style/CookiesCardsSizes.css'

// -- Plugins components -- //
import VueBarcode from '@chenfengyuan/vue-barcode'

// -- Core functions -- //
import CookiesCoreFunctions from '@/modules/CookiesCoreMixin.vue'

// -- App Specific Data -- //
const AppData = {
  data () {
    return {
      // API_URL: 'https://94.127.214.84:8089/', // live
      // API_URL: 'https://94.127.214.84:8091/', // testing
      // API_URL: 'https://146.71.78.183:8081/', // vpsserver
      API_URL: 'https://orderi.cookiesbis.com/', // testing
      Errors: false,
      ConsoleLogs: false,
      // ///////////////////////////////////////////////
      AuthorizationVersion: 1,
      EnableUpnormalUser: false,
      API_MAPPER: {
        Config: 'Config',
        // >>>> AuthorizationVersion [[ 1 ]] >>>>>>>>>>>>>>>>>>>>>>>>>>>>
        Authorization: 'Users/Getuserauthorization',
        Login: 'Users/Login',
        Logout: 'Users/LogOut'
      },
      LedgersMapper: {
        D_Cash: 12,
        C_Collected: 17,
        Expenses: 3,
        Stock: 1,
        Salary: 4,
        Loans: 5,
        Fees: 6,
        ToBeCollctedFee: 7,
        CanceledFees: 8,
        CanceledStock: 9,
        ReturnedStock: 10,
        OnDelivaryOrders: 11,
        Cash: 12,
        ToBeCollectedRevenue: 13,
        CanceledRevenue: 14,
        CollectedRevenue: 15,
        FeesDiff: 16,
        Collected: 17,
        Diff: 18,
        Done: 19,
        TempDriverFees: 20,
        Gas: 21,
        Maintenance: 22
      },
      AccountMapper: {
        M_Expences: -5,
        M_Expenses: -5,
        M_Revenue: -4,
        M_Fees: -3,
        M_Stock: -2,
        M_Cash: -1
      },
      EmpTypes: [
        {
          AR: 'سائق',
          EN: 'Driver',
          value: 'Driver',
          salary: 220
        },
        {
          AR: 'سائق عمولة',
          EN: 'Temp Driver',
          value: 'TempDriver'
        },
        {
          AR: 'مدخل بيانات',
          EN: 'Data Entry',
          value: 'DataEntry',
          salary: 240
        },
        {
          AR: 'محاسب',
          EN: 'Financial',
          value: 'Financial',
          salary: 200
        },
        {
          AR: 'متابعة',
          EN: 'Support',
          value: 'Support',
          salary: 260
        },
        {
          AR: 'مدير',
          EN: 'Admin',
          value: 'Admin'
        }
      ],
      States: {
        Ordered: {
          ARA: 'مدخل جديد',
          ENG: 'Ordered',
          value: 'Ordered'
        },
        Collecting: {
          ARA: 'قيد التحصيل',
          ENG: 'Collecting',
          value: 'Collecting'
        },
        Collected: {
          ARA: 'تم التحصيل',
          ENG: 'Collected',
          value: 'Collected'
        },
        OnDelivery: {
          ARA: 'قيد التوصيل',
          ENG: 'On Delivery',
          value: 'OnDelivery'
        },
        Delivered: {
          ARA: 'تم التوصيل',
          ENG: 'Delivered',
          value: 'Delivered'
        },
        Canceled: {
          ARA: 'ملغي',
          ENG: 'Canceled',
          value: 'Canceled'
        },
        Returned: {
          ARA: 'مرتجع',
          ENG: 'Returned',
          value: 'Returned'
        },
        Received: {
          ARA: 'في المستودع',
          ENG: 'Received',
          value: 'Received'
        },
        ToBeReturned: {
          ARA: 'للارجاع',
          ENG: 'To Return',
          value: 'ToBeReturned'
        },
        ToBeReturnedItem: {
          ARA: 'مرتجع للارجاع',
          ENG: 'Returned Item',
          value: 'ToBeReturnedItem'
        },
        CustomerPaid: {
          ARA: 'تم محاسبة العميل',
          ENG: 'Customer Paid',
          value: 'CustomerPaid'
        },
        DriverPaid: {
          ARA: 'تم محاسبة السائق',
          ENG: 'Driver Paid',
          value: 'DriverPaid'
        },
        Done: {
          ARA: 'تم التسليم',
          ENG: 'Done',
          value: 'Done'
        },
        Closed: {
          ARA: 'مغلقة',
          ENG: 'Closed',
          value: 'Closed'
        },
        Redirected: {
          ARA: 'محول',
          ENG: 'Redirected',
          value: 'Redirected'
        },
        Delayed: {
          ARA: 'مؤجل',
          ENG: 'Delayed',
          value: 'Delayed'
        },
        Edited: {
          ARA: 'تعديل',
          ENG: 'Edited',
          value: 'Edited'
        }
      },
      Languages: {
        ENG: {
          Name: 'English'
        },
        ARA: {
          Name: 'العربية'
        }
      }
    }
  },
  computed: {
    NavItems () {
      return [
        {
          Name: this.LanguageSelector('Main', 'الرئيسيه'),
          Icon: 'Main',
          Access: 'MainView',
          Link: '/'
        },
        {
          Name: this.LanguageSelector('Orders', 'الطلبات'),
          Icon: 'Packages',
          Access: 'OrdersView',
          Link: '/Orders'
        },
        {
          Name: this.LanguageSelector('Drivers', 'السائقون'),
          Icon: 'Drivers',
          Access: 'DriversView',
          Link: '/Drivers'
        },
        {
          Name: this.LanguageSelector('Customers', 'العملاء'),
          Icon: 'Customers',
          Access: 'CustomersView',
          Link: '/Customers'
        },
        {
          Name: this.LanguageSelector('Areas', 'المناطق'),
          Icon: 'Location',
          Access: 'AreasView',
          Link: '/Areas'
        },
        {
          Name: this.LanguageSelector('Employees', 'الموطفين'),
          Icon: 'Employees',
          Access: 'EmployeesView',
          Link: '/Employees'
        },
        {
          Name: this.LanguageSelector('Cars', 'السيارات'),
          Icon: 'Cars',
          Access: 'CarsView',
          Link: '/Cars'
        },
        {
          Name: this.LanguageSelector('Reports', 'التقارير'),
          Icon: 'Reports',
          Access: 'ReportsView',
          Link: '/Reports'
        },
        {
          Name: this.LanguageSelector('Settings', 'الاعدادات'),
          Icon: 'Settings',
          Access: 'SettingsView',
          Link: '/Settings',
          Down: true
        }
      ]
    }
  }
}
// -- Creating the APP -- //
const CookiesApp = createApp(App)
CookiesApp.use(store).use(router).mixin(CookiesCoreFunctions).mixin(AppData)

// -- Folders Loading -- //
CookiesDynamicLoader(require.context('@/components', true, /\.vue$/))
CookiesDynamicLoader(require.context('./components', true, /\.vue$/))

// -- Folder Content Loading ------------------------------------------------------ ////
function CookiesDynamicLoader (VueComponents) {
  VueComponents.keys().forEach((comp) => {
    var ComponentName = comp.split('/').pop().split('.')[0]
    CookiesApp.component(ComponentName, VueComponents(comp).default)
  })
}
// -- Mounting the APP
CookiesApp.component(VueBarcode.name, VueBarcode)
  .mount('#CookiesApp')
