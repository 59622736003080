import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/Orders',
    name: 'Orders',
    component: () => import('../views/Orders.vue')
  },
  {
    path: '/Customers',
    name: 'Customers',
    component: () => import('../views/Customers.vue')
  },
  {
    path: '/Employees',
    name: 'Employees',
    component: () => import('@/views/Employees.vue')
  },
  {
    path: '/Cars',
    name: 'Cars',
    component: () => import('../views/Cars.vue')
  },
  {
    path: '/Areas',
    name: 'Areas',
    redirect: '/Areas/MainAreas',
    component: () => import('../views/Areas.vue'),
    children: [
      {
        path: 'MainAreas',
        name: 'MainAreas',
        props: { Search: { default: '', Type: String } },
        component: () => import('../views/Areas/MainAreas.vue')
      },
      {
        path: 'SubAreas',
        name: 'SubAreas',
        props: { Search: { default: '', Type: String } },
        component: () => import('../views/Areas/SubAreas.vue')
      },
      {
        path: 'Lines',
        name: 'Lines',
        props: { Search: { default: '', Type: String } },
        component: () => import('../views/Areas/Lines.vue')
      }
    ]
  },
  {
    path: '/Drivers',
    name: 'Drivers',
    component: () => import('../views/Drivers.vue')
  },
  {
    path: '/DriverPortal',
    name: 'DriverPortal',
    component: () => import('../views/DriverPortal.vue')
  },
  {
    path: '/Reports',
    name: 'Reports',
    redirect: '/Reports/Orders/order',
    component: () => import('../views/Reports.vue'),
    children: [
      {
        path: 'Orders/:Type',
        name: 'ReportingOrders',
        component: () => import('../views/Reporting/OrdersReporting.vue')
      },
      {
        path: 'Customers',
        name: 'ReportingCustomers',
        component: () => import('../views/Reporting/CustomersReporting.vue')
      },
      {
        path: 'Drivers',
        name: 'ReportingDrivers',
        component: () => import('../views/Reporting/DriversReporting.vue')
      },
      {
        path: 'Revenue',
        name: 'ReportingRevenue',
        component: () => import('../views/Reporting/Revenue.vue')
      },
      {
        path: 'Expenses',
        name: 'ReportingExpenses',
        component: () => import('../views/Reporting/Expenses.vue')
      }
    ]
  },
  {
    path: '/Settings',
    name: 'Settings',
    redirect: '/Settings/System',
    component: () => import('../views/Settings.vue'),
    children: [
      {
        path: 'System',
        component: () => import('@/components/Settings/SystemConfig.vue')
      },
      {
        path: 'Account',
        component: () => import('@/components/Settings/Account.vue')
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  mode: 'history',
  routes
})
export default router
