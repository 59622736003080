<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer">
    <CookiesIcon :ID="DialogData.Icon" style="width: 3.5em; height: 3.5em; margin-top: -1em;" class="M-Auto"></CookiesIcon>
    <div class="CookiesRowSpacer"></div>
    <div class="DialogBody D-Flex">
      <div class="CookiesRow F-C2">
        <div class="CookiesRow F1_25em W-100">
          <span class=" M-Auto color3"><strong>{{DialogData.Text}}</strong></span>
          <div class="CookiesRowSpacer"></div>
        </div>
        <div class="W-100" style="height:20px;"></div>
        <div class="M-Auto F-Bold">
          <button @click="$router.go(-1)" :style="'background: ' + $store.getters.Theme.Shade3" class="F-Bold CookiesButton DialogFooterButton">{{Translate('Cancel')}}</button>
          <button @click="Confirm()" class="F-Bold CookiesButton DialogFooterButton BGMain" :class="DialogData.RedButton ? 'BGRed': ''">{{Translate('Confirm')}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      DeleteConfirmed: false,
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  mounted () {
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  methods: {
    Confirm () {
      var Method = 'POST'
      var URL = ''
      var Data = {}
      var DialogsToClose = 1
      if (this.DialogData.Method !== undefined) {
        Method = this.DialogData.Method
      }
      if (this.DialogData.URL !== undefined) {
        URL = this.DialogData.URL
      }
      if (this.DialogData.Data !== undefined) {
        Data = this.DialogData.Data
      }
      if (this.DialogData.CloseMyDialog) {
        DialogsToClose = 2
      }
      this.Saving = true
      this.Post(Method, URL, Data).then(response => {
        this.DeleteConfirmed = true
        this.Saving = false
        this.CloseDialog(this.$router.go(-1 * DialogsToClose))
      }, error => {
        this.Saving = false
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  }
}
</script>
<style scoped>

.DialogBody{
  width: 600px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesDialogdBody{
  margin-bottom: 0px;
}
</style>
