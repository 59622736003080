import { createStore } from 'vuex'

export default createStore({
  state: {
    CurrentDate: {
      From: '',
      To: ''
    },
    Printing: '',
    PrintingOptions: {},
    CookiesChart: {
      data: [1, 2, 3],
      options: ['A', 'B', 'C'],
      colors: ['#ff6b64', '#0091c4', '#67b45d', '#309587', '#ff9f64', '#cc6678', '#ffd238', '#ff9bf8', '#ffc064', '#50ceff'],
      Gradientcolors: ['linear-gradient(#e66465, #9198e5)', '#0091c4']
    },
    CookiesConfig: {
      Name: 'Cookies BIS',
      color: '#1FCAD6',
      background: 'BGGradient',
      Transparent: 'true',
      Theme: '1',
      Language: 'ARA',
      PriceUnit: 'دينار',
      DefaultImage: require('@/assets/defaults/DefaultImage.png'),
      UserImage: require('@/assets/defaults/UserImage.svg'),
      Logo: require('@/assets/defaults/CookiesBIS.svg')
    },
    CookiesMemory: {}
  },
  getters: {
    Theme: state => {
      var SelectedColor: any = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(state.CookiesConfig.color)
      var r = parseInt(SelectedColor[1], 16)
      var g = parseInt(SelectedColor[2], 16)
      var b = parseInt(SelectedColor[3], 16)
      var hsl: any = RGBToHSL(r, g, b)
      var h = hsl[0]
      var s = hsl[1]
      var l = hsl[2]
      hsl = 'hsl(' + h + ',' + s + '%,' + l + '%)'
      var margin = 7
      var s2 = s
      var l2 = Math.min(l + 3 * margin, 100)
      var s3 = Math.max(s - margin, 0)
      var l3 = l
      var s4 = Math.max(s - margin, 0)
      var l4 = Math.max(l - 2 * margin, 0)
      var UserColors = {
        mainR: r,
        mainG: g,
        mainB: b,
        main: 'hsla(' + h + ', ' + s + '%, ' + l + '%)',
        second: 'hsl(' + h + ', ' + s2 + '%, ' + l2 + '%)',
        third: 'hsl(' + h + ', ' + s3 + '%, ' + l3 + '%)',
        fourth: 'hsl(' + h + ', ' + s4 + '%, ' + l4 + '%)',
        main_75: 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + 0.75 + ')',
        main_50: 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + 0.5 + ')',
        main_25: 'hsla(' + h + ', ' + s + '%, ' + l + '%, ' + 0.25 + ')',
        third_75: 'hsla(' + h + ', ' + s3 + '%, ' + l3 + '%, ' + 0.75 + ')',
        third_50: 'hsla(' + h + ', ' + s3 + '%, ' + l3 + '%, ' + 0.5 + ')',
        third_25: 'hsla(' + h + ', ' + s3 + '%, ' + l3 + '%, ' + 0.25 + ')',
        second_75: 'hsla(' + h + ', ' + s2 + '%, ' + l2 + '%, ' + 0.75 + ')',
        second_50: 'hsla(' + h + ', ' + s2 + '%, ' + l2 + '%, ' + 0.5 + ')',
        second_25: 'hsla(' + h + ', ' + s2 + '%, ' + l2 + '%, ' + 0.25 + ')',
        fourth_75: 'hsla(' + h + ', ' + s4 + '%, ' + l4 + '%, ' + 0.75 + ')',
        fourth_50: 'hsla(' + h + ', ' + s4 + '%, ' + l4 + '%, ' + 0.5 + ')',
        fourth_25: 'hsla(' + h + ', ' + s4 + '%, ' + l4 + '%, ' + 0.25 + ')',
        Dimmed: 'hsla(' + h + ', ' + s3 / (state.CookiesConfig.Theme === '1' ? 3 : 5) + '%, ' + (state.CookiesConfig.Theme === '1' ? 20 : 10) + '%)',
        Gradient: 'linear-gradient(45deg, hsl(' + h + ', ' + s3 / (state.CookiesConfig.Theme === '1' ? 2 : 5) + '%, ' + (state.CookiesConfig.Theme === '1' ? l4 : l4 / 8) + '%), hsl(' + h + ', ' + s3 / (state.CookiesConfig.Theme === '1' ? 2 : 5) + '%, ' + (state.CookiesConfig.Theme === '1' ? l2 : l4 / 3) + '%))'
      }
      function RGBToHSL (r: any, g: any, b: any) {
        // Make r, g, and b fractions of 1
        r /= 255
        g /= 255
        b /= 255
        // Find greatest and smallest channel values
        var cmin = Math.min(r, g, b)
        var cmax = Math.max(r, g, b)
        var delta = cmax - cmin
        var h = 0
        var s = 0
        var l = 0
        // Calculate hue
        // No difference
        if (delta === 0) {
          h = 0
        } else if (cmax === r) {
          h = ((g - b) / delta) % 6
        } else if (cmax === g) {
          h = (b - r) / delta + 2
        } else {
          h = (r - g) / delta + 4
        }
        h = Math.round(h * 60)
        // Make negative hues positive behind 360°
        if (h < 0) {
          h += 360
        }
        // Calculate lightness
        l = (cmax + cmin) / 2
        // Calculate saturation
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
        // Multiply l and s by 100
        s = +(s * 100).toFixed(1)
        l = +(l * 100).toFixed(1)
        return [h, s, l]
      }
      if (state.CookiesConfig.Theme === '1') {
        return { // light
          BG1: state.CookiesConfig.Transparent === 'true' ? 'rgba(204, 204, 204, 0.7)' : '#cccccc',
          BG2: state.CookiesConfig.Transparent === 'true' ? 'rgba(239, 239, 239, 0.7)' : '#efefef',
          BG3: state.CookiesConfig.Transparent === 'true' ? 'rgba(254, 254, 254, 0.7)' : '#fefefe',
          BG4: state.CookiesConfig.Transparent === 'true' ? 'rgba(224, 224, 224, 0.7)' : '#e0e0e0',
          BG5: state.CookiesConfig.Transparent === 'true' ? 'rgba(224, 224, 224, 0.7)' : '#e0e0e0',
          BG6: state.CookiesConfig.Transparent === 'true' ? 'rgba(254, 254, 254, 0.7)' : '#fefefe',
          BG7: state.CookiesConfig.Transparent === 'true' ? 'rgba(254, 254, 254, 0.7)' : '#fefefe',
          BG8: state.CookiesConfig.Transparent === 'true' ? 'rgba(224, 224, 224, 0.7)' : '#e0e0e0',
          BG1Fixed: '#cccccc',
          BG2Fixed: '#efefef',
          BG3Fixed: '#fefefe',
          BG4Fixed: '#e0e0e0',
          BG5Fixed: '#e0e0e0',
          BG6Fixed: '#fefefe',
          BG7Fixed: '#fefefe',
          BG8Fixed: '#e0e0e0',
          BG1_75: 'rgba(204,204,204,0.9)',
          BG2_75: 'rgba(239,239,239,0.75)',
          color1: 'rgba(0, 0, 0, 1)',
          color2: 'rgba(0, 0, 0, 0.85)',
          color3: 'rgba(0, 0, 0, 0.7)',
          color4: 'rgba(95, 95, 95, 0.9)',
          color5: 'rgba(50, 50, 50, 0.9)',
          Shade0: 'rgba(0, 0, 0, 0.05)',
          Shade1: 'rgba(0, 0, 0, 0.1)',
          Shade2: 'rgba(0, 0, 0, 0.2)',
          Shade3: 'rgba(0, 0, 0, 0.3)',
          Shade4: 'rgba(0, 0, 0, 0.4)',
          Light1: 'rgba(255, 255, 255, 0.15)',
          Light2: 'rgba(255, 255, 255, 0.4)',
          Light3: 'rgba(255, 255, 255, 0.6)',
          Light4: 'rgba(255, 255, 255, 0.8)',
          InputColor: state.CookiesConfig.Transparent === 'true' ? 'rgba(0, 0, 0, 0.13)' : 'rgba(0, 0, 0, 0.08)',
          UserColors: UserColors,
          gradient: 'linear-gradient(90deg, rgba(235,235,235,1) 0%, rgba(223,226,223,0.9) 49%, rgba(202,214,215,0.95) 100%)',
          BGGradient: 'linear-gradient(90deg, rgba(235,235,235,1) 0%, rgba(223,226,223,0.9) 49%, rgba(202,214,215,0.95) 100%)',
          BackBlur: 'brightness(1.1) blur(10px)',
          BackShader: 'rgba(0,0,0,0.3)'
        }
      } else {
        return { // dark
          BG1: state.CookiesConfig.Transparent === 'true' ? 'rgba(27, 26, 29, 0.6)' : '#1b1a1d',
          BG2: state.CookiesConfig.Transparent === 'true' ? 'rgba(44, 45, 49, 0.6)' : '#2c2d31',
          BG3: state.CookiesConfig.Transparent === 'true' ? 'rgba(24, 22, 24, 0.6)' : '#181618',
          BG4: state.CookiesConfig.Transparent === 'true' ? 'rgba(35, 35, 39, 0.6)' : '#232327',
          BG5: state.CookiesConfig.Transparent === 'true' ? 'rgba(31, 32, 34, 0.6)' : '#1f2022',
          BG6: state.CookiesConfig.Transparent === 'true' ? 'rgba(31, 32, 34, 0.6)' : '#1f2022',
          BG7: state.CookiesConfig.Transparent === 'true' ? 'rgba(31, 32, 34, 0.6)' : '#1f2022',
          BG8: state.CookiesConfig.Transparent === 'true' ? 'rgba(24, 22, 24, 0.6)' : '#181618',
          BG1Fixed: '#1b1a1d',
          BG2Fixed: '#2c2d31',
          BG3Fixed: '#181618',
          BG4Fixed: '#232327',
          BG5Fixed: '#1f2022',
          BG6Fixed: '#1f2022',
          BG7Fixed: '#1f2022',
          BG8Fixed: '#181618',
          BG1_75: 'rgba(27,26,29,0.9)',
          BG2_75: 'rgba(44,45,49,0.75)',
          color1: '#ffffff',
          color2: 'rgba(255, 255, 255, 0.8)',
          color3: 'rgba(255, 255, 255, 0.55)',
          color4: 'rgba(250, 250, 250, 0.8)',
          color5: 'rgba(195, 195, 195, 0.8)',
          Shade0: 'rgba(255, 255, 255, 0.035)',
          Shade1: 'rgba(255, 255, 255, 0.05)',
          Shade2: 'rgba(255, 255, 255, 0.1)',
          Shade3: 'rgba(255, 255, 255, 0.2)',
          Shade4: 'rgba(255, 255, 255, 0.3)',
          Light1: 'rgba(0, 0, 0, 0.1)',
          Light2: 'rgba(0, 0, 0, 0.2)',
          Light3: 'rgba(0, 0, 0, 0.3)',
          Light4: 'rgba(0, 0, 0, 0.4)',
          InputColor: state.CookiesConfig.Transparent === 'true' ? 'rgba(0, 0, 0, 0.35)' : 'rgba(0, 0, 0, 0.25)',
          UserColors: UserColors,
          gradient: 'linear-gradient(90deg, rgba(18,18,18,1) 0%, rgba(14,14,14,0.96) 49%, rgba(12,12,12,0.93) 100%)',
          BGGradient: 'linear-gradient(90deg, rgba(18,18,18,1) 0%, rgba(14,14,14,0.96) 49%, rgba(12,12,12,0.93) 100%)',
          BackBlur: 'brightness(1.2) blur(18px)',
          BackShader: 'rgba(0,0,0,0.7)'
        }
      }
    },
    Direction: state => {
      var d = 'ltr'
      if (state.CookiesConfig.Language === 'ARA') {
        d = 'rtl'
      }
      return d
    },
    DefaultTextAlign: state => {
      var d = 'left'
      if (state.CookiesConfig.Language === 'ARA') {
        d = 'right'
      }
      return d
    },
    CurrentDate: state => {
      return state.CurrentDate
    }
  },
  modules: {
    UserManagment: {
      state: {
        CurrentUser: {
          Type: '',
          AccessList: {}
        }, // UserTypes: ADMIN, EMPLOYEE, RESELLER
        Dialog: false,
        Status: false
      }
    },
    CookiesDialog: {
      state: {
        OpenedDialogs: [],
        DialogsCount: 1,
        ErrorDialogs: []
      },
      getters: {
        CurrentDialogsLength: state => {
          return state.OpenedDialogs.length
        }
      }
    }
  }
})
