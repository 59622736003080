<template>
<div v-if="Access('AreasAdd')" :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <DialogDisabler v-if="Saving"></DialogDisabler>
  <div class="M-Auto DialogContainer" style="">
    <div class="DialogBody">
      <div class=" D-Flex W-100" style="text-align:left; margin-bottom:15px; white-space: nowrap;">
      </div>
      <!-- Area -------------------------------------- -->
      <div class="CookiesRow">
        <input v-model="Name" :class=" (ErrorsVisibilty & !Name) && 'ErrorBorder' " class="CookiesInput color2" type="Text" :placeholder="LanguageSelector('Line Name', 'اسم الخط')" autocomplete="off">
        <div class="CookiesRowSpacer"></div>
      </div>
    </div>
  </div>
</div>
<div v-if="Access('AreasAdd')" class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'الغاء': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Cancel': ''}}</button>
    <button @click="SaveLine()" :style="'background: ' + $store.getters.Theme.UserColors.main" class="F-Bold CookiesButton DialogFooterButton">{{$store.state.CookiesConfig.Language === 'ARA' ? 'حفظ': ''}}{{$store.state.CookiesConfig.Language === 'ENG' ? 'Save': ''}}</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  created () {},
  data () {
    return {
      Name: '',
      ErrorsVisibilty: false,
      Saving: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    SaveLine () {
      if (this.DialogError.length > 0) {
        this.ErrorsVisibilty = true
        return
      }
      this.Saving = true
      this.Post('POST', 'Area/addline', this.NewAreaData).then(response => {
        this.Saving = false
        this.CloseDialog(this.DialogIndex)
      }, error => {
        this.Saving = false
        error.CookiesError = 'Error in Adding New Line'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  mounted () {
    if (!this.Access('AreasAdd')) {
      return
    }
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    NewAreaData () {
      return {
        Name: this.Name
      }
    },
    DialogError () {
      var DialogErrors = []
      this.Name === '' && DialogErrors.push('Please Enter Area\'s Name')
      return DialogErrors
    },
    isActive () {
      return this.$store.state.CookiesMemory.D_NewArea_Active
    }
  }
}
</script>

<style scoped>

.DialogBody{
  width: 400px;
  max-width: 80vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}
.CookiesInputHolder{
  height: 30px;
  width: 200px;
  border: none;
  border-radius: var(--rad2);
  flex: auto;
  padding: 10px 25px;
  outline: none;
  font-size: 17px;
  margin: 6px;
  position: relative;
}

.CookiesInputInside::placeholder{
  opacity: 0.35;
}
.CookiesRowSpacer{
  width: 100%;
  height: 20px;
}

.CookiesNumberInput{
  display: flex;
  margin: auto;
  border-radius: var(--rad3);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ErrorBorder{
  border: solid var(--colorr2) 2px;
  padding: 8px 23px;
}
@media (max-width: 500px) {
  .DialogBody{
    font-size: 3vw;
  }
}
</style>
