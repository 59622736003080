<template>
<div :id="'CookiesDialog_' + DialogIndex" class="CookiesDialogdBody">
  <div class="M-Auto DialogContainer">
    <div class="DialogBody">
      <!-- User -------------------------------------- -->
      <div class="CookiesRow">
      <div class="CookiesRowLeft">
        <span class="O-50">{{Translate('Email: ')}}<strong>{{DialogData.Email}}</strong> </span>
      </div>
      <form class="CookiesRowSplitter"/>
        <input v-model="NewPassWord" :type="MEMRead('D_ChangePass_NewPasswordUnHide') ? 'Password': 'Text'" :CookiesInvalidInput="!PasswordValidator(NewPassWord)" :class="(ErrorsVisibilty & !PasswordValidator(NewPassWord)) && 'ErrorBorder'" class="CookiesInput" name="New Password" :placeholder="Translate('New Password')" autocomplete="off">
        <input v-model="ConfirmNewPassWord" :type="MEMRead('D_ChangePass_NewPasswordUnHide') ? 'Password': 'Text'" :CookiesInvalidInput="NewPassWord !== ConfirmNewPassWord" :class="(ErrorsVisibilty & NewPassWord !== ConfirmNewPassWord) && 'ErrorBorder'" class="CookiesInput" name="Confirm New Password" :placeholder="Translate('Confirm New Password')" autocomplete="off">
      <form class="W-100" style="padding:5px">
        <CookiesCheckBox style="text-align:left;" :Name="Translate('Show Password')" :Res="'D_ChangePass_NewPasswordUnHide'" :Type="'Switch'"></CookiesCheckBox>
      </form>
      <!-- <div v-if="ErrorsVisibilty" class="W-100" style="padding:5px">
        <div v-for='(error, index) in PassValidaton.ErrorsList' :key='(error, index)' class=" W-100" style="color:var(--colorr1); padding:4px;">{{error}}</div>
      </div> -->
      </div>
    </div>
  </div>
</div>
<div class="CookiesDialogFooter">
  <div class=" F-Bold" style="margin: auto 1em;">
    <button @click="$router.go(-1)" style="background: var(--colorr1);" class="F-Bold CookiesButton DialogFooterButton">Cancel</button>
    <button @click="ChangePassword()" class="F-Bold CookiesButton DialogFooterButton BGThird">Save</button>
  </div>
</div>

</template>

<script>
export default {
  setup () {},
  components: {},
  name: 'Test',
  created () {},
  data () {
    return {
      NewPassWord: '',
      ConfirmNewPassWord: '',
      ErrorsVisibilty: false
    }
  },
  props: {
    DialogIndex: Number,
    DialogData: { default: '' }
  },
  methods: {
    ChangePassword () {
      if (this.Requirments(this).Incomplete) { // checking any dom inside this dialog that contains attribute CookiesInvalidInput = true
        this.ErrorsVisibilty = true
        return
      }
      // remove id to User_ID
      this.Post('POST', 'User/ChangePassword?userName=' + this.NewPassword.UserName + '&Password=' + this.NewPassword.Password, {}).then(response => {
        this.CloseDialog(this.DialogIndex)
      }, error => {
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    }
  },
  mounted () {
    try {
      var DialogPos = document.getElementById('CookiesDialog_' + this.DialogIndex).offsetTop - 60
      window.scrollTo(0, DialogPos)
    } catch (error) {
      console.log('error: ', error.message)
    }
  },
  computed: {
    NewPassword () {
      return {
        UserName: this.DialogData.UserName,
        Password: this.NewPassWord
      }
    },
    PassValidaton () {
      var DialogErrors = {
        NewPass: false,
        ConfirmPass: false,
        ErrorsList: []
      }
      if (this.NewPassWord === '') {
        DialogErrors.ErrorsList.push('Please enter a new passord')
        if (this.ErrorsVisibilty) {
          DialogErrors.NewPass = true
        }
      }
      if (this.NewPassWord.length < 5) {
        DialogErrors.ErrorsList.push('Password must be at least 5 characters')
        if (this.ErrorsVisibilty) {
          DialogErrors.NewPass = true
        }
      }
      if (this.ConfirmNewPassWord !== this.NewPassWord && this.ConfirmNewPassWord !== '') {
        DialogErrors.ErrorsList.push('Passwords didn\'t match')
        if (this.ErrorsVisibilty) {
          DialogErrors.ConfirmPass = true
        }
      }
      return DialogErrors
    }
  }
}
</script>

<style scoped>
.DialogBody{
  width: 400px;
  max-width: 65vw;
}
.DialogContainer{
  padding: 50px;
}
@media (max-width: 500px) {
  .FlexBreaker{
    flex-direction: column;
  }
  .FlexBreaker > *{
    width: 80% !important;
  }
  .DialogContainer{
    padding: 1em;
  }
  .CookiesDialogdBody{
    font-size: 3vw;
  }
}
@media (max-width: 300px) {
  .CookiesDialogdBody{
    font-size: 4vw;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.CookiesNumberInput{
  border-radius: var(--rad2);
}
</style>
