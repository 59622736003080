<template>
  <div class="H-100 FilterArea Shadow0" :class="ShowFilter ? '': 'FilterHidden'" style="font-size: 0.89em;">
    <div class="" style="margin: 0.2em; width: 100%;">
      <div class="F-Bold" style="padding-bottom: 0.2em;">{{LanguageSelector('Filter', 'فلتر')}}</div>
      <CookiesIcon @click="$emit('CloseMe')" class="P-Abs HoverGlow FilterButton" style="width: 2em; height: 2em; right: 1em; top: 1em;" :ID="'Close'"></CookiesIcon>
      <div v-if="Lists.length > 0" class="CookiesRowSplitter"></div>
      <template v-for="item in Lists" :key="item">
        <CookiesDropInputObject style="margin: 0.4em 0em;" v-if="item.Visible" :PreserveMemory="true" :Res="item.Res" :Selectors="item.Selectors" :Name="item.Name" :Options="item.Options"></CookiesDropInputObject>
      </template>
      <div v-if="Checks.length > 0" class="CookiesRowSplitter"></div>
      <div v-if="Checks.length > 3" class="W-100 D-Flex" style="font-size: 0.9em;">
        <div class="M-Auto InFlex">
        <CookiesIcon @click="AllFilterSelector(true)" class="HoverGlow" style="width:2.1em; height:2.1em; margin-bottom: 1em; padding: 0.4em;" :ID="'Select'"></CookiesIcon>
        <CookiesIcon @click="AllFilterSelector(false)" class="HoverGlow" style="width:2.1em; height:2.1em; margin-bottom: 1em; padding: 0.4em;" :ID="'Deselect'"></CookiesIcon>
        </div>
      </div>
      <template v-for="CheckBox in Checks" :key="CheckBox">
        <div v-if="CheckBox.Visible === undefined || CheckBox.Visible" class="W-100 D-Flex" style="font-size: 0.9em;">
          <CookiesIcon style="width:2.1em; height:2.1em; margin-bottom: 0.2em;" :ID="CheckBox.Icon"></CookiesIcon><CookiesCheckBox style="margin: auto 0.2em;" :Default="CheckBox.Default === undefined ? true : CheckBox.Default" :Res="CheckBox.Res" :Name="CheckBox.Name"></CookiesCheckBox>
        </div>
      </template>
      <div v-if="Switches.length > 3" class="CookiesRowSplitter" ></div>
      <template v-for="Switch in Switches" :key="Switch">
        <div v-if="Switch.Visible === undefined || Switch.Visible" class="W-100 D-Flex" style="font-size: 0.9em;">
          <CookiesIcon style="width:2.1em; height:2.1em; margin-bottom: 0.2em;" :ID="Switch.Icon"></CookiesIcon><CookiesCheckBox style="margin: auto 0.2em;" :Type="'Switch'" :Default="Switch.Default === undefined ? false : Switch.Default" :Res="Switch.Res" :Name="Switch.Name"></CookiesCheckBox>
        </div>
      </template>
      <div v-if="Dates" class="W-100 D-Flex" style="justify-content: center; flex-direction: column;">
        <div><span>{{LanguageSelector('From', 'من')}}</span><input @change="DatesChanged()" v-model="DateFrom" class="CookiesInput color2" type="date"></div>
        <div><span>{{LanguageSelector('To', 'الى')}}</span><input @change="DatesChanged()" v-model="DateTo" class="CookiesInput color2" type="date"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ShowFilter: { default: true, type: Boolean },
    Lists: { default: [] },
    Checks: { default: [] },
    Switches: { default: [] },
    Dates: { default: false, type: Boolean }
  },
  data () {
    return {
      DateFrom: this.$store.state.CurrentDate.From,
      DateTo: this.$store.state.CurrentDate.To
    }
  },
  methods: {
    DatesChanged () {
      this.DateChanged(this.DateFrom, this.DateTo)
      this.$emit('DateChanged')
    },
    AllFilterSelector (value) {
      this.Checks.forEach(CheckBox => {
        this.MEMWrite(CheckBox.Res, value)
      })
    }
  }
}
</script>

<style scoped>
.FilterArea{
  background: v-bind('$store.getters.Theme.BG2');
  border-radius: var(--rad2);
  display: flex;
  flex-wrap: wrap;
  margin-left: 1em;
  margin-right: 1em;
}
.FilterArea > div {
  width: 12em;
  padding: 1em;
  opacity: 1;
}
.FilterButton{
  display: none;
}
@media (max-width: 800px){
  .FilterArea{
    position: absolute;
    min-height: 100%;
    width: 100%;
    right: 0;
    z-index: 55;
    font-size: 4vw !important;
    overflow-y: scroll;
    margin: 0;
    transition: width,overflow,opacity 1s ease-in-out;
  }
  .FilterButton{
    display: block;
  }
  .FilterHidden{
    width: 0% !important;
    overflow: hidden !important;
    font-size: 0em !important;
    opacity: 0;
    pointer-events: none;
  }
  .BigPlaceholder{
    font-size: 3vw;
  }
  .PhoneHider{
    display: none !important;
  }
  .CookiesGeneralTable > tr{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .CookiesGeneralTableRow{
    padding: 0;
    border-bottom: solid 1px v-bind('$store.getters.Theme.BG4') !important;
  }
  .CookiesGeneralTable td{
    border: none !important;
    display: block;
  }
  .OrdersHeader{
    font-size: 3vw;
    width: 100%;
    margin-top: 0.5em;
  }
  .TabContainer{
    margin: 1em 0em;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 5vw !important;
  }
  .CookiesSearchInputContainer{
    font-size: 3vw;
  }
}
</style>
