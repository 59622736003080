<template>
<div class="CoookiesPrintBG P-Rel" :style="{direction: $store.getters.Direction}">
  <div v-if="!PrintingOptions.NoHeader" class="D-Flex" style="position: absolute; left: 0; top: 0; direction: ltr;"><span class="P_25em">{{ JsonDateParser(new Date()) }}</span>-<span class="P_25em">{{ JsonTimeParser(new Date())}}</span></div>
  <div class="D-Flex Inset0 F2_5em noprint BG1Fixed" style="position: fixed; z-index: 9999999;" :style="{background: $store.getters.Theme.BG1Fixed}">
    <span :style="{color: $store.getters.Theme.color3}" class="M-Auto">Printing...</span>
  </div>
  <div class="CoookiesPrintPlaceAdjuster">
    <div class="W-100 D-Flex PrintHeader">
      <button @click="Print()" class="PrintButtons D-Flex"><span class="M-Auto" style="margin:0px 5px">Print</span><CookiesIcon style="width: 1.5em; height: 1.5em;" :ID="'Printer'"></CookiesIcon></button>
      <button @click="$store.state.Printing = ''" class="PrintButtons D-Flex" style="background: var(--colorr1)">Close</button>
    </div>
    <div id="PrintingPlace" class="CookiesPrintPage"></div>
  </div>
  <div v-if="!PrintingOptions.NoHeader" class="D-Flex" style="position: absolute; right: 0; top: 0; direction: ltr;"><div class="P_25em M-Auto" style="width: 2.5em; height: 2.5em; background-size: contain; background-repeat:no-repeat; background-position: center;" :style="'background-image: url(' + $store.state.CookiesConfig.Logo + ');'"></div><span class="P_25em M-Auto">{{ $store.state.CookiesConfig.Name }}</span></div>
</div>
<component v-bind:is="A4Landscape"></component>
</template>

<script>
export default {
  data () {
    return {}
  },
  components: {},
  props: {},
  created () {},
  mounted () {
    var Sting = '@media print { @page{ size: A4 Landscape !important; } }'
    if (this.PrintingOptions.PageSize !== undefined) {
      Sting = '@media print { @page{ size: ' + this.PrintingOptions.PageSize + ' !important; } }'
    }
    document.getElementById('PrintingStyle').innerHTML = Sting
    document.getElementById('PrintingPlace').append(this.$store.state.Printing)
    try {
      window.scrollTo(0, 0)
    } catch (error) {
      console.log('error: ', error.message)
    }
    window.print()
    this.$store.state.PrintingOptions = {}
    this.$store.state.Printing = ''
    document.getElementById('PrintingPlace').innerHTML = ''
  },
  methods: {
    Print () {
      window.print()
    }
  },
  computed: {
    PrintingOptions () {
      var Options = {
        MinWidth: '20cm',
        MaxWidth: '27cm',
        FontSize: '9pt',
        Padding: '0.5cm',
        Margin: '5px auto',
        PageSize: 'A4 Landscape',
        NoHeader: false
      }
      var CurrentOptions = this.$store.state.PrintingOptions
      if (CurrentOptions.FontSize !== undefined) {
        Options.FontSize = CurrentOptions.FontSize
      }
      if (CurrentOptions.MinWidth !== undefined) {
        Options.MinWidth = CurrentOptions.MinWidth
      }
      if (CurrentOptions.MaxWidth !== undefined) {
        Options.MaxWidth = CurrentOptions.MaxWidth
      }
      if (CurrentOptions.Padding !== undefined) {
        Options.Padding = CurrentOptions.Padding
      }
      if (CurrentOptions.Margin !== undefined) {
        Options.Margin = CurrentOptions.Margin
      }
      if (CurrentOptions.PageSize !== undefined) {
        Options.PageSize = CurrentOptions.PageSize
      }
      if (CurrentOptions.NoHeader !== undefined) {
        Options.NoHeader = CurrentOptions.NoHeader
      }
      return Options
    },
    PrintingScrollAdjuster () {
      if (this.$store.state.Printing !== '') {
        return 'visible !important'
      } else {
        return 'visible !important'
      }
    }
  }
}
</script>
<style>
@media print {
  #app{
    overflow: visible !important;
  }
  /* @page {size: A4 landscape !important;} */
}
</style>
<style scoped>
.CookiesPrintPage>>>.PageImage{
  margin-left: 5pt !important;
}
.CoookiesPrintBG{
  /* background: rgb(255, 255, 255); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;
  display: flex;
  min-height: 100%;
  padding: 8px;
  opacity: 1;
}
.CoookiesPrintPlaceAdjuster{
  /* background: rgb(255, 255, 255); */
  margin: 25px auto;
  display: flex;
  flex-direction: column;
  /* min-height: 100%; */
}
.CookiesPrintPage{
  filter: drop-shadow(var(--shadow1));
  background: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
  /* border: red solid 1px; */
  /* width: 8.5cm; */
  /* height: 7.2cm; */
  min-width: v-bind('PrintingOptions.MinWidth');
  max-width: v-bind('PrintingOptions.MaxWidth');
  /* min-height: 27.2cm; */
  margin: v-bind('PrintingOptions.Margin');
  display: flex;
  padding: v-bind('PrintingOptions.Padding');
  justify-content: center;
}
.CookiesPrintPage >>> .NoPrint{
  display: none;
}
.CookiesPrintPage >>> .noprint{
  display: none;
}

.CookiesPrintPage >>> .JustPrint{
  display: block;
}
.CookiesPrintPage >>> *{
  font-size: v-bind('PrintingOptions.FontSize');
}
.CookiesPrintPage >>> .FlexBoxes{
  flex-direction: row;
  font-size: '';
}
.CookiesPrintPage >>> .DataSections{
  width: 47%;
  margin-top: '';
}
.CookiesPrintPage >>> .HeadeElement{
  margin: auto 25px auto auto !important;
  padding: 0px;
}

.PrintButtons{
  padding: 10px 20px;
  margin: 5px;
}
@media print {
  .CoookiesPrintBG{
    padding: 0px !important;
  }
  .CookiesPrintPage{
    margin: 0px !important;
    filter: none !important;
    padding: 0cm !important;
  }
  .PrintHeader{
    width: 0px;
    height: 0px;
    overflow: hidden;
    display: none;
  }
}

.CookiesPrintPage >>> td.ReportTable {
  background-color: none;
  background: none;
  border: solid 0.2pt var(--shade3) !important;
  border-left: none !important;
}
.CookiesPrintPage >>> td.TableFooter {
  background-color: none;
  background: none;
  border-left: none !important;
  border-right: none !important;
  border-top: solid 0.2pt var(--shade3) !important;
  border-bottom: solid 0.2pt var(--shade3) !important;
}

.CookiesPrintPage >>> tr.ReportTable {
  background-color: none !important;
  background: none !important;
  border-right: solid 0.2pt var(--shade3) !important;
  border-left: solid 0.2pt var(--shade3) !important;
}
.CookiesPrintPage >>> tr.ReportTable::before {
  background-color: none !important;
  background: none !important;
  border: none;
}
.CookiesPrintPage >>> th.ReportTable {
  background-color: none !important;
  background: none !important;
  border: solid 0.2pt var(--shade3) !important;
  border-left: none !important;
}
.Table >>> * {
  border-collapse: collapse;
}
</style>
